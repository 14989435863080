import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { useState } from 'react';

import ScrollPicker from '@client/components/generic/ScrollPicker';
import defaultTheme from '@client/css-modules/MinOnlyPicker.css';
import {
  CONTROL_CUSTOMIZATIONS,
  FilterKey,
  FilterTriBool,
  LABEL_DEFAULTS,
} from '@client/store/filter-constants';
import { FilterPickerValue } from '@client/store/types/filters';
import { getFilterControlCustomization } from '@client/utils/filters.utils';

export type MinOnlyPickerProps = {
  theme: Theme;
  filterKey: FilterKey;
  values: FilterPickerValue[];
  setValueForStateFormatter: (val: FilterPickerValue) => FilterPickerValue;
  labelFormatter: (val: FilterPickerValue) => string;
  onChange: (value: [FilterPickerValue, null] | FilterTriBool) => void;
  handleReportValueSelection: (value: FilterPickerValue | null) => void;
  currentValues: [FilterPickerValue | null, null];
  height?: number;
  itemHeight?: number;
  getValueForControlFormatter: (currentVal: FilterPickerValue | null) => string;
  ariaLabelledBy: string;
  isTribool?: boolean;
};

const MinOnlyPicker = ({
  theme,
  filterKey,
  values,
  setValueForStateFormatter,
  labelFormatter,
  onChange,
  handleReportValueSelection,
  currentValues,
  height,
  itemHeight,
  getValueForControlFormatter,
  ariaLabelledBy,
  isTribool,
}: MinOnlyPickerProps) => {
  const defaultText =
    getFilterControlCustomization(filterKey)?.[
      CONTROL_CUSTOMIZATIONS.CUSTOM_NO_MIN_TEXT
    ] ?? LABEL_DEFAULTS.NO_MIN;

  const [min, setMin] = useState<FilterPickerValue>(
    getValueForControlFormatter(currentValues[0]) || defaultText
  );

  const handleValueChange = (type: string, value: FilterPickerValue): void => {
    const formattedVal = value && setValueForStateFormatter(value);
    isTribool
      ? onChange(formattedVal as FilterTriBool)
      : onChange([formattedVal, null]);
    /* Updating control value via local state for performance */
    setMin(value);
    handleReportValueSelection(value);
  };

  const generateLabels = (v: FilterPickerValue): string => {
    if (v === defaultText) {
      return v;
    } else {
      return labelFormatter(v);
    }
  };

  return (
    <div className={theme.MinOnlyPicker}>
      <ScrollPicker
        dataHcName="min-only-picker"
        ariaLabelledBy={ariaLabelledBy}
        height={height}
        itemHeight={itemHeight}
        labelFormatter={generateLabels}
        valueGroups={{
          min: min,
        }}
        optionGroups={{
          min: [defaultText, ...values],
        }}
        onChange={handleValueChange}
      />
    </div>
  );
};

export default themr('MinOnlyPicker', defaultTheme)(MinOnlyPicker);
