import classNames from 'classnames';
import React, { Component } from 'react';

import Dropdown from '@client/components/generic/Dropdown';
import DropdownRange from '@client/components/generic/DropdownRange';
import FlatButton from '@client/components/generic/FlatButton';
import MultiSelect from '@client/components/generic/MultiSelect';
import NumberAdjuster from '@client/components/generic/NumberAdjuster';
import RangeSlider from '@client/components/generic/RangeSlider';
import SimpleTooltip from '@client/components/generic/SimpleTooltip';
import GrantProgramFilterToggle from '@client/components/GrantProgram/GrantProgramFilterToggle';
import RouterLink from '@client/components/RouterLink';
import SavedSearchFilterDropdown from '@client/components/SavedSearchFilterDropdown';
import ScrollSectionIntoView from '@client/components/ScrollSectionIntoView';
import theme from '@client/css-modules/SearchPageFilters.css';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { LockedComponent } from '@client/hocs/locked-component';
import ChevronIconStandardGray from '@client/inline-svgs/chevron-standard-gray';
import { View } from '@client/routes/constants';
import {
  FE_ONLY_MLS_STATES,
  FILTER_KEYS,
  FILTER_SECTION_HEADING_ID,
  FilterKey,
  FiltersState,
  SCHOOL_FILTER_KEYS,
} from '@client/store/filter-constants';
import { SearchFiltersConfig } from '@client/store/types/cobranding';
import { SpecialUserType } from '@client/store/types/locked-components';
import { SavedSearchItem } from '@client/store/types/saved-searches';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  FILTER_CONTROLS,
  FLAT_FILTER_CONTROLS,
  getAllMlsStateOptions,
  getAllMlsStateValues,
  getAllPropertyTypeOptions,
  getAllPropertyTypeValues,
  getDropdownRangeSourceValues,
  getDropdownRangeSourceValuesForListPrice,
  getFilterControlCustomization,
  getMlsStateValues,
  getPropertyTypeValues,
} from '@client/utils/filters.utils';

export const BUTTON_TYPE = {
  CANCEL: 'cancel',
  RETURN: 'return',
};

type Filter = {
  title?: string;
  key: string;
  minValue: number;
  maxValue: number;
  isRangeSlider: boolean;
  rightSideLabel: string;
  getValueForControlFormatter: () => void;
  setValueForStateFormatter: () => void;
  labelFormatter: (value: number | { min: number; max: number }) => string;
  ariaLabelFormatter?: (value: number | { min: number; max: number }) => number;
  step: number;
};

type Props = {
  /* All filter values gotten from state */
  filterValues: FiltersState;
  filterSortOrder?: SearchFiltersConfig | null;
  handleClearAllFilters: (
    isDisplayMultiFamilySearchFiltersEnabled: boolean
  ) => void;
  handleFilterChange: (
    key: FilterKey,
    value: any,
    isDisplayMultiFamilySearchFiltersEnabled?: boolean
  ) => void;
  savedSearches: SavedSearchItem[];
  handleSavedSearchSelect: (id: string | number | null) => void;
  handleFetchSavedSearches: () => void;
  selectedSavedSearch: number | null;
  hasSavedSearches: boolean;
  userBuyingPower: number | null;
  isSchoolDataEnabled: boolean;
  isDisplayMultiFamilySearchFiltersEnabled: boolean;
  isShowComingSoonInListingStatusFilterEnabled: boolean;
  reportValueSelection: (
    filterKey: FilterKey,
    descriptor: string | null,
    value: string | number | null
  ) => void;
  reportShowAdvancedFilters: () => void;
  /* Feature flag to hide the Advanced Filters */
  isHidingAdvancedFilters: boolean;
  /* Pushes in a toggle to turn on/off the Grant Program indicators on the SRP */
  isShowingGrantProgramToggle: boolean;
  areTempEnhancedFilterOptionsEnabled: boolean;
};

type State = {
  /* Assuming `!isHidingAdvancedFilters`, show advanced filters on mount if any advanced filters are currently active */
  showAdvancedFilters: boolean;
  hasClickedShowAdvancedFilters: boolean;
};

/**
 * Filters for adjusting spatial-search GQL call and altering properties displayed
 * in the sidebar and on the map
 */
export default class SearchPageFilters extends Component<Props, State> {
  searchPageFiltersContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef();
  advancedFilterRef;

  constructor(props: Props) {
    super(props);
    this.state = {
      /* Assuming `!isHidingAdvancedFilters`, show advanced filters on mount if any advanced filters are currently active */
      showAdvancedFilters: FILTER_CONTROLS.advanced
        .map((filter) => filter.key)
        .reduce((areAnyActive: boolean, filterKey) => {
          const isThisActive =
            props.filterValues[filterKey][0] !== null ||
            props.filterValues[filterKey][1] !== null;
          return areAnyActive || isThisActive;
        }, false),
      hasClickedShowAdvancedFilters: false,
    };
  }

  componentDidMount() {
    const { current: searchPageFiltersContainer } =
      this.searchPageFiltersContainerRef;
    this.props.handleFetchSavedSearches();
    if (searchPageFiltersContainer) {
      const firstFilterBtn =
        searchPageFiltersContainer.querySelectorAll('button')[0];
      if (firstFilterBtn) {
        firstFilterBtn.focus();
      }
    }
  }

  setAdvancedFilterRef = (el) => {
    this.advancedFilterRef = el;
  };

  toggleAdvancedFilters = () => {
    if (!this.state.showAdvancedFilters) {
      this.props.reportShowAdvancedFilters();
    }

    this.setState((prevState) => ({
      showAdvancedFilters: !prevState.showAdvancedFilters,
      hasClickedShowAdvancedFilters: !prevState.showAdvancedFilters,
    }));
  };

  /* Excluding school filters if feature flag turned off */
  shouldShowSchoolsFilter = (filter: Filter) => {
    return (
      !(
        filter.key === SCHOOL_FILTER_KEYS.ELEMENTARY ||
        filter.key === SCHOOL_FILTER_KEYS.MIDDLE ||
        filter.key === SCHOOL_FILTER_KEYS.HIGH
      ) || this.props.isSchoolDataEnabled
    );
  };

  buildSchoolFilters = (filters: any, uidOfWrapper?: string) => {
    const {
      filterValues,
      handleFilterChange,
      userBuyingPower,
      reportValueSelection,
    } = this.props;

    let SectionHeading: JSX.Element | null = null;
    let schoolHeadingId = `${FILTER_SECTION_HEADING_ID.SCHOOL}-${uidOfWrapper}`;
    /* Add section heading for schools filters */
    SectionHeading = (
      <LockedComponent
        sectionId={FILTER_KEYS.SEARCH_BY_MLS}
        lockedFor={[SpecialUserType.Restricted]}
        className={theme.LockedComponentContainer}
        theme={theme}
      >
        {({ isLocked }) => (
          <legend key="legend">
            <div
              key={FILTER_SECTION_HEADING_ID.SCHOOL}
              className={classNames(theme.FilterSectionHeading, {
                [theme.disabled]: isLocked,
              })}
              id={schoolHeadingId}
            >
              Schools
            </div>
          </legend>
        )}
      </LockedComponent>
    );

    const FilterSchoolContent = filters.map((filter) => {
      if (
        /**
         * Add the check for filter to prevent the page from breaking
         * when we add a new search filter config on Parcon
         */
        !filter ||
        !this.shouldShowSchoolsFilter(filter) ||
        (filter.key !== FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX &&
          filter.key !== FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX &&
          filter.key !== FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX)
      ) {
        return null;
      }

      const Filter = (
        <AccessibleElementUniqueId>
          {({ uid }) => {
            const ROW_LABEL_TEXT = `row-label-text-${uid}`;
            return (
              <LockedComponent
                sectionId={FILTER_KEYS.SEARCH_BY_MLS}
                lockedFor={[SpecialUserType.Restricted]}
                showLockedSection={false}
              >
                {({ isLocked }) => (
                  <div
                    key={filter.key}
                    data-hc-name="filter-row"
                    id={filter.key}
                    role="group"
                    aria-labelledby={uid}
                    className={classNames(theme.FilterRow, {
                      [theme.SubTitle]: filter.subTitle,
                      [theme.SubTitleBottomPadding]:
                        FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX ===
                        filter.key,
                      [theme.FilterRowWithBuyingPower]:
                        !!userBuyingPower &&
                        filter.key === FILTER_KEYS.LIST_PRICE_MIN_MAX &&
                        filter.isDropdownRange,
                      [theme.disabled]: isLocked,
                    })}
                  >
                    {filter.title && (
                      <span
                        data-hc-name="row-label"
                        id={!filter.subTitle ? uid : undefined}
                        className={theme.FilterTitle}
                      >
                        <div id={!filter.subTitle ? ROW_LABEL_TEXT : undefined}>
                          {filter.title}
                        </div>
                        {filter.shortDescription && (
                          <SimpleTooltip
                            width={220}
                            triggerAriaDescribedBy={ROW_LABEL_TEXT}
                            content={`${filter.shortDescription}`}
                          />
                        )}
                      </span>
                    )}
                    {filter.subTitle && (
                      <span id={uid} className={theme.FilterSubTitle}>
                        {filter.subTitle}
                      </span>
                    )}

                    {!!filter.key &&
                      filter.key !== FILTER_KEYS.GRANT_ELIGIBLE &&
                      filter.isRangeSlider && (
                        <div
                          data-hc-name="row-control"
                          className={theme.FilterControl}
                        >
                          <RangeSlider
                            disabled={isLocked}
                            ariaLabelledBy={uid}
                            minValue={filter.minValue}
                            maxValue={filter.maxValue}
                            theme={theme}
                            value={{
                              min:
                                filter.getValueForControlFormatter(
                                  filterValues[filter.key][0]
                                ) || filter.minValue,
                              max:
                                filter.getValueForControlFormatter(
                                  filterValues[filter.key][1]
                                ) || filter.maxValue,
                            }}
                            labelFormatter={filter.labelFormatter}
                            ariaLabelFormatter={filter.ariaLabelFormatter}
                            step={filter.step}
                            onChangeComplete={(values) =>
                              handleFilterChange(
                                filter.key,
                                filter.setValueForStateFormatter(values)
                              )
                            }
                            handleReportValueSelection={(value) =>
                              reportValueSelection(filter.key, null, value)
                            }
                            className={theme[filter.color] || theme.hotToCool}
                          />
                        </div>
                      )}
                  </div>
                )}
              </LockedComponent>
            );
          }}
        </AccessibleElementUniqueId>
      );

      return <React.Fragment key={filter.key}>{[Filter]}</React.Fragment>;
    });

    return (
      <React.Fragment>
        {
          <fieldset role="group" aria-labelledby={schoolHeadingId}>
            {[SectionHeading, FilterSchoolContent]}
          </fieldset>
        }
      </React.Fragment>
    );
  };

  buildSearchByMlsFilter = () => {
    return (
      <LockedComponent
        sectionId={FILTER_KEYS.SEARCH_BY_MLS}
        lockedFor={[SpecialUserType.Restricted]}
        className={theme.LockedComponentContainer}
        theme={theme}
      >
        {({ isLocked }) => (
          <React.Fragment key={FILTER_KEYS.SEARCH_BY_MLS}>
            <RouterLink
              key={FILTER_KEYS.SEARCH_BY_MLS}
              view={View.MLS_NUMBER}
              className={classNames(theme.MLSSearchLink, {
                [theme.disabled]: isLocked,
              })}
            >
              Search by MLS#
            </RouterLink>
          </React.Fragment>
        )}
      </LockedComponent>
    );
  };

  buildFilters = (
    basicFilters: /* TODO should be typed FilterControl[] */ any,
    isAdvancedFilters?: boolean,
    uidOfWrapper?: string
  ) => {
    const {
      filterValues,
      filterSortOrder,
      handleFilterChange,
      userBuyingPower,
      reportValueSelection,
      isShowingGrantProgramToggle,
      isDisplayMultiFamilySearchFiltersEnabled,
      isShowComingSoonInListingStatusFilterEnabled,
    } = this.props;

    const allPropertyTypeOptions = getAllPropertyTypeOptions(
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const propertyTypeValues = getPropertyTypeValues(
      filterValues,
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const allPropertyTypeValues = getAllPropertyTypeValues(
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const filterValuesIncludesMulti =
      filterValues.propertyType.includes('MULTI');

    let filters =
      isDisplayMultiFamilySearchFiltersEnabled && filterValuesIncludesMulti
        ? basicFilters
        : basicFilters.filter(
            (filter) => filter.key !== FILTER_KEYS.UNITS_TOTAL_MIN_MAX
          );
    const allMlsStatesOptions = getAllMlsStateOptions(
      isShowComingSoonInListingStatusFilterEnabled
    );
    const mlsStateValues = getMlsStateValues(
      filterValues,
      isShowComingSoonInListingStatusFilterEnabled
    );
    const allMlsStateValues = getAllMlsStateValues(
      isShowComingSoonInListingStatusFilterEnabled
    );

    const shouldAddGrantEligibleToFilters =
      isShowingGrantProgramToggle &&
      !isAdvancedFilters &&
      !filterSortOrder &&
      !filters.some((filter) => filter.key === FILTER_KEYS.GRANT_ELIGIBLE);

    if (shouldAddGrantEligibleToFilters) {
      filters.splice(2, 0, { key: FILTER_KEYS.GRANT_ELIGIBLE });
    }

    if (this.props.areTempEnhancedFilterOptionsEnabled === false) {
      filters = filters.filter(
        (filter) =>
          ![FILTER_KEYS.POOL, FILTER_KEYS.BASEMENT].includes(filter.key)
      );
    }

    const renderedFilters = filters.map((filter) => {
      if (!filter || !this.shouldShowSchoolsFilter(filter)) {
        return null;
      } else if (filter.key === FILTER_KEYS.GRANT_ELIGIBLE) {
        if (isShowingGrantProgramToggle && !isAdvancedFilters) {
          return <GrantProgramFilterToggle key="grant-program" />;
        }
        return null;
      } else if (filter.key === FILTER_KEYS.SEARCH_BY_MLS) {
        return this.buildSearchByMlsFilter();
      }

      const customizations = getFilterControlCustomization(filter.key);
      const shouldExpandUpwards = !!customizations?.should_expand_upwards;

      const Filter = (
        <AccessibleElementUniqueId>
          {({ uid }) => {
            const ROW_LABEL_TEXT = `row-label-text${uid}`;
            return (
              <LockedComponent
                className={theme.LockedComponentContainer}
                sectionId={filter.key}
                lockedFor={[SpecialUserType.Restricted]}
                theme={theme}
              >
                {({ isLocked }) => (
                  <div
                    key={filter.key}
                    data-hc-name="filter-row"
                    id={filter.key}
                    role="group"
                    aria-labelledby={uid}
                    className={classNames(theme.FilterRow, {
                      [theme.SubTitle]: filter.subTitle,
                      [theme.SubTitleBottomPadding]:
                        FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX ===
                        filter.key,
                      [theme.FilterRowWithBuyingPower]:
                        !!userBuyingPower &&
                        filter.key === FILTER_KEYS.LIST_PRICE_MIN_MAX &&
                        filter.isDropdownRange,
                      [theme.disabled]: isLocked,
                    })}
                  >
                    {filter.title && (
                      <span
                        data-hc-name="row-label"
                        id={!filter.subTitle ? uid : undefined}
                        className={theme.FilterTitle}
                      >
                        <div id={!filter.subTitle ? ROW_LABEL_TEXT : undefined}>
                          {filter.title}
                        </div>
                        {filter.shortDescription && (
                          <SimpleTooltip
                            width={220}
                            triggerAriaDescribedBy={ROW_LABEL_TEXT}
                            content={`${filter.shortDescription}`}
                          />
                        )}
                      </span>
                    )}
                    {filter.subTitle && (
                      <span id={uid} className={theme.FilterSubTitle}>
                        {filter.subTitle}
                      </span>
                    )}
                    {!!filter.key && (
                      <div
                        data-hc-name="row-control"
                        className={theme.FilterControl}
                      >
                        {(() => {
                          if (filter.isNumberAdjuster) {
                            return (
                              <NumberAdjuster
                                ariaLabelledBy={uid}
                                offScreenLabel
                                label={filter.title}
                                theme={theme}
                                value={filterValues[filter.key][0] || 0}
                                minValue={filter.minValue}
                                maxValue={filter.maxValue}
                                labelFormatter={filter.labelFormatter}
                                onChange={(value) =>
                                  handleFilterChange(
                                    filter.key,
                                    filter.setValueForStateFormatter(value)
                                  )
                                }
                                handleReportValueSelection={(
                                  operator: 'plus' | 'minus',
                                  value: string | number | null
                                ) => {
                                  reportValueSelection(
                                    filter.key,
                                    operator,
                                    value
                                  );
                                }}
                              />
                            );
                          } else if (filter.isMultiSelect) {
                            return (
                              <MultiSelect
                                ariaDescribedBy={uid}
                                ariaLabelledBy={uid}
                                theme={theme}
                                label={filter.title}
                                filterKey={filter.key}
                                values={
                                  filter.key === FILTER_KEYS.PROPERTY_TYPE
                                    ? propertyTypeValues
                                    : filter.key === FILTER_KEYS.MLS_STATE
                                      ? mlsStateValues
                                      : filter.getValueForControlFormatter(
                                            filterValues[filter.key]
                                          ).length > 0
                                        ? filter.getValueForControlFormatter(
                                            filterValues[filter.key]
                                          )
                                        : [FE_ONLY_MLS_STATES.ALL]
                                }
                                options={
                                  filter.key === FILTER_KEYS.PROPERTY_TYPE
                                    ? allPropertyTypeOptions
                                    : filter.key === FILTER_KEYS.MLS_STATE
                                      ? allMlsStatesOptions
                                      : filter.options
                                }
                                inverseBehavior={filter.isMultiSelectInverse}
                                onChange={(values, valueAdded) => {
                                  handleFilterChange(
                                    filter.key,
                                    filter.key === FILTER_KEYS.PROPERTY_TYPE
                                      ? filter.setValueForStateFormatter(
                                          values,
                                          valueAdded,
                                          allPropertyTypeValues
                                        )
                                      : filter.key === FILTER_KEYS.MLS_STATE
                                        ? filter.setValueForStateFormatter(
                                            values,
                                            valueAdded,
                                            allMlsStateValues
                                          )
                                        : filter.setValueForStateFormatter(
                                            values,
                                            valueAdded
                                          ),
                                    isDisplayMultiFamilySearchFiltersEnabled
                                  );
                                }}
                                handleReportValueSelection={(
                                  value: string | number | null | any
                                ) => {
                                  const valueLowerCase =
                                    typeof value === 'string'
                                      ? value.toLocaleLowerCase()
                                      : null;
                                  reportValueSelection(
                                    filter.key,
                                    null,
                                    valueLowerCase || value
                                  );
                                }}
                              />
                            );
                          } else if (filter.isRangeSlider) {
                            return (
                              <RangeSlider
                                ariaLabelledBy={uid}
                                minValue={filter.minValue}
                                maxValue={filter.maxValue}
                                theme={theme}
                                value={{
                                  min:
                                    filter.getValueForControlFormatter(
                                      filterValues[filter.key][0]
                                    ) || filter.minValue,
                                  max:
                                    filter.getValueForControlFormatter(
                                      filterValues[filter.key][1]
                                    ) || filter.maxValue,
                                }}
                                labelFormatter={filter.labelFormatter}
                                ariaLabelFormatter={filter.ariaLabelFormatter}
                                step={filter.step}
                                onChangeComplete={(values) =>
                                  handleFilterChange(
                                    filter.key,
                                    filter.setValueForStateFormatter(values)
                                  )
                                }
                                className={
                                  theme[filter.color] || theme.hotToCool
                                }
                                handleReportValueSelection={() => {
                                  reportValueSelection(filter.key, null, null);
                                }}
                              />
                            );
                          } else if (
                            userBuyingPower &&
                            filter.key === FILTER_KEYS.LIST_PRICE_MIN_MAX &&
                            filter.isDropdownRange
                          ) {
                            return (
                              <DropdownRange
                                ariaLabelledBy={uid}
                                className={classNames(theme.DropdownRange, {
                                  [theme.UserBuyingPowerInListPriceFilter]:
                                    !!userBuyingPower,
                                })}
                                theme={theme}
                                label={filter.title}
                                values={filterValues[filter.key]}
                                sources={getDropdownRangeSourceValuesForListPrice(
                                  filter.options,
                                  filterValues[filter.key],
                                  filter.labelFormatter,
                                  userBuyingPower
                                )}
                                handleChange={(values) =>
                                  handleFilterChange(
                                    filter.key,
                                    filter.setValueForStateFormatter([
                                      values[0],
                                      values[1],
                                    ])
                                  )
                                }
                                userBuyingPower={userBuyingPower}
                                handleReportValueSelection={(
                                  position: 'min' | 'max',
                                  value: string | number | null
                                ) => {
                                  reportValueSelection(
                                    filter.key,
                                    position,
                                    value
                                  );
                                }}
                              />
                            );
                          } else if (filter.isDropdownRange) {
                            return (
                              <DropdownRange
                                ariaLabelledBy={uid}
                                label={filter.title}
                                values={filterValues[filter.key]}
                                sources={getDropdownRangeSourceValues(
                                  filter.options,
                                  filterValues[filter.key],
                                  filter.labelFormatter,
                                  filter.key === FILTER_KEYS.UNITS_TOTAL_MIN_MAX
                                )}
                                handleChange={(values) =>
                                  handleFilterChange(
                                    filter.key,
                                    filter.setValueForStateFormatter([
                                      values[0],
                                      values[1],
                                    ])
                                  )
                                }
                                handleReportValueSelection={(
                                  position: 'min' | 'max',
                                  value: string | number | null
                                ) => {
                                  reportValueSelection(
                                    filter.key,
                                    position,
                                    value
                                  );
                                }}
                                shouldExpandUpwards={shouldExpandUpwards}
                              />
                            );
                          } else if (filter.isDropdown) {
                            const customNullText =
                              customizations?.custom_no_min_text;

                            return (
                              <Dropdown
                                ariaLabelledBy={uid}
                                dataHcName="single-dropdown-filter"
                                theme={theme}
                                onChange={(value) =>
                                  handleFilterChange(
                                    filter.key,
                                    filter.setValueForStateFormatter(value)
                                  )
                                }
                                source={filter.options.map((val) => {
                                  return {
                                    value: val,
                                    label:
                                      val === null && customNullText
                                        ? customNullText
                                        : filter.labelFormatter(val),
                                  };
                                })}
                                label={filter.title}
                                value={filter.getValueForControlFormatter(
                                  filterValues[filter.key]
                                )}
                                handleReportValueSelection={(
                                  value: string | number | null
                                ) => {
                                  reportValueSelection(filter.key, null, value);
                                }}
                                shouldExpandUpwards={shouldExpandUpwards}
                              />
                            );
                          } else {
                            return null;
                          }
                        })()}
                      </div>
                    )}
                  </div>
                )}
              </LockedComponent>
            );
          }}
        </AccessibleElementUniqueId>
      );

      return (
        <React.Fragment key={filter.key}>
          {
            /**
             * To pass the accessibility requirement,
             * we need to wrap the school filter with <fieldset></fieldset>.
             * So we use buildSchoolFilters() to build all school filter
             * and add it here
             */
            filter.key !== FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX &&
            filter.key !== FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX &&
            filter.key !== FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX
              ? [Filter]
              : filter.key ===
                  FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX &&
                this.buildSchoolFilters(filters, uidOfWrapper)
          }
        </React.Fragment>
      );
    });

    return renderedFilters;
  };

  getSortedFilterControl = () => {
    const { filterSortOrder } = this.props;
    const sortedFilterControl = filterSortOrder?.map((filter) => {
      if (
        filter === FILTER_KEYS.SEARCH_BY_MLS ||
        filter === FILTER_KEYS.GRANT_ELIGIBLE
      ) {
        return { key: filter };
      } else {
        return FLAT_FILTER_CONTROLS.filter((fc) => fc.key === filter)[0];
      }
    });
    return sortedFilterControl;
  };

  render() {
    const { showAdvancedFilters } = this.state;
    const {
      filterSortOrder,
      isDisplayMultiFamilySearchFiltersEnabled,
      savedSearches,
      handleClearAllFilters,
      handleSavedSearchSelect,
      selectedSavedSearch,
      hasSavedSearches,
      /* Feature flag to hide Advanced filters from appearing */
      isHidingAdvancedFilters,
    } = this.props;

    return (
      <AccessibleElementUniqueId>
        {({ uid }) => (
          <div ref={this.searchPageFiltersContainerRef}>
            <div className={theme.ClearAllFiltersContainer}>
              <button
                data-event-name="click_clear_all_filters"
                aria-label="Clear All Filters"
                className={theme.ClearAllFilters}
                data-hc-name={'clear-all-filters'}
                onClick={() =>
                  handleClearAllFilters(
                    isDisplayMultiFamilySearchFiltersEnabled
                  )
                }
                onKeyDown={onEnterOrSpaceKey(() =>
                  handleClearAllFilters(
                    isDisplayMultiFamilySearchFiltersEnabled
                  )
                )}
              >
                Clear All Filters
              </button>
            </div>
            {hasSavedSearches && (
              <SavedSearchFilterDropdown
                theme={theme}
                selected={selectedSavedSearch}
                handleChange={handleSavedSearchSelect}
                options={savedSearches}
              />
            )}
            <div className={theme.SearchPageFilters}>
              {/* If there is a filterSortOrder config from Parcon, render the filters in that order */}
              {filterSortOrder ? (
                <div>
                  {this.buildFilters(this.getSortedFilterControl(), false, uid)}
                </div>
              ) : (
                <>
                  <div>
                    {this.buildFilters(FILTER_CONTROLS.standard, false, uid)}
                    {/* Hard-coded link to MLS Search page */}
                    {this.buildSearchByMlsFilter()}
                  </div>
                  {
                    /* Feature flag that removes the Advanced Filters from the DOM */
                    !isHidingAdvancedFilters && (
                      <>
                        <FlatButton
                          label={
                            showAdvancedFilters
                              ? 'Hide'
                              : 'Show Advanced Filters'
                          }
                          aria-label={`${
                            showAdvancedFilters ? 'Hide' : 'Show'
                          } Advanced Filters`}
                          aria-expanded={showAdvancedFilters}
                          icon={
                            <ChevronIconStandardGray
                              className={theme.ChevronIcon}
                            />
                          }
                          className={classNames(theme.FiltersToggle, {
                            [theme.HideAdvanced]: showAdvancedFilters,
                            [theme.ShowAdvanced]: !showAdvancedFilters,
                          })}
                          onClick={this.toggleAdvancedFilters}
                        />
                        <div ref={this.setAdvancedFilterRef}>
                          {this.state.showAdvancedFilters &&
                            (this.state.hasClickedShowAdvancedFilters ? (
                              <ScrollSectionIntoView>
                                <div>
                                  {this.buildFilters(
                                    FILTER_CONTROLS.advanced,
                                    true
                                  )}
                                </div>
                              </ScrollSectionIntoView>
                            ) : (
                              <div>
                                {this.buildFilters(
                                  FILTER_CONTROLS.advanced,
                                  true
                                )}
                              </div>
                            ))}
                        </div>
                      </>
                    )
                  }
                </>
              )}
            </div>
          </div>
        )}
      </AccessibleElementUniqueId>
    );
  }
}
