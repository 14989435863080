import classNames from 'classnames';
import React, { useState } from 'react';

import CobrandedStyles from '@client/components/CobrandedStyles';
import EmailField from '@client/components/generic/EmailField';
import FormError from '@client/components/generic/FormError';
import PasswordField from '@client/components/generic/PasswordField';
import PillButton from '@client/components/generic/PillButton';
import RouterLink from '@client/components/RouterLink';
import SignUpOrLoginLink from '@client/components/SignUpOrLoginLink';
import theme from '@client/css-modules/Login.css';
import { View } from '@client/routes/constants';
import { onEnterKey } from '@client/utils/accessibility.utils';

type SignupUser = {
  email: string;
  password: string;
};

type Props = {
  /** Tells the LoginForm to go into loading state */
  isLoading?: boolean;
  /** Title */
  title?: string;
  /** Signup event handler */
  handleLogin: (_: SignupUser) => void;
  /** callback when "don't have an account?" link is clicked */
  handleSignupClick?: () => void;
  /** optional callback when the forgot password link is clicked */
  handleForgotPasswordClick?: () => void;
  handleFormFieldInteraction: (typ: string) => void;
  /** Error message to show in form */
  errorMessage?: string | null;
};

const Login = ({
  isLoading = false,
  title = 'Log In',
  handleLogin = () => undefined,
  handleSignupClick,
  handleForgotPasswordClick,
  handleFormFieldInteraction,
  errorMessage,
}: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    handleFormFieldInteraction(e.target.type);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleLoginClick = (e: React.FormEvent): void => {
    e.preventDefault();
    if (emailValid && !!email && passwordValid && !!password) {
      handleLogin({ email, password });
    }
  };

  const handleEmailValid = () => setEmailValid(true);
  const handleEmailInvalid = () => setEmailValid(false);
  const handlePasswordValid = () => setPasswordValid(true);
  const handlePasswordInvalid = () => setPasswordValid(false);

  const isValid = passwordValid && !!password && emailValid && email;

  return (
    <CobrandedStyles>
      {({ linkColor }) => (
        <div className={theme.LoginForm}>
          <>
            {title && (
              <h2 data-hc-name="login-header-text" className={theme.LoginTitle}>
                {title}
              </h2>
            )}
            {handleSignupClick && (
              <div
                data-hc-name={'sign-up-row'}
                className={classNames(theme.CenteredRow, theme.SignupLinkRow)}
              >
                <SignUpOrLoginLink
                  theme={theme}
                  linkType="signup"
                  handleClick={handleSignupClick}
                />
              </div>
            )}
            <form onSubmit={handleLoginClick}>
              <EmailField
                theme={theme}
                label="Email"
                ariaDescribedby="error-email"
                required
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
                onValid={handleEmailValid}
                onInvalid={handleEmailInvalid}
                dataHcName={'email-field'}
                value={email}
                name={'email'}
                error={
                  !emailValid && !email ? 'Please enter your email' : undefined
                }
                debounce={750}
              />
              <PasswordField
                theme={theme}
                label="Password"
                ariaDescribedby="error-password"
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
                onKeyDown={onEnterKey(handleLoginClick)}
                onValid={handlePasswordValid}
                onInvalid={handlePasswordInvalid}
                dataHcName={'password-field'}
                value={password}
                name={'password'}
                error={
                  !passwordValid && !password
                    ? 'Please enter your password'
                    : undefined
                }
                debounce={250}
              />
              <div className={theme.ForgotPasswordLink}>
                <RouterLink
                  view={View.FORGOT_PASSWORD}
                  data-hc-name="forgot-password-link"
                  className={theme.Link}
                  style={{ color: linkColor }}
                  onKeyDown={
                    handleForgotPasswordClick
                      ? onEnterKey(handleForgotPasswordClick)
                      : undefined
                  }
                  onClick={handleForgotPasswordClick}
                >
                  Forgot your password?
                </RouterLink>
              </div>
              <div className={theme.CenteredRow}>
                <PillButton
                  ariaLabel="log in"
                  type="submit"
                  disabled={isLoading || !isValid}
                  onClick={handleLoginClick}
                  theme={theme}
                >
                  Log In
                </PillButton>
              </div>
              {errorMessage && (
                <FormError
                  data-hc-name={'form-error-message'}
                  theme={theme}
                  value={errorMessage}
                />
              )}
            </form>
          </>
        </div>
      )}
    </CobrandedStyles>
  );
};

export default Login;
