import { Alert } from '@client/utils/reach-alert';
import { Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';

import AdCardForCobrand from '@client/components/AdCardForCobrand';
import ConnectWithATopLocalAgent from '@client/components/cobrand/topagentsranked/ConnectWithATopLocalAgent';
import CobrandedComponent, {
  CobrandedComponentArgs,
} from '@client/components/CobrandedComponent';
import DisclaimerFairHousing from '@client/components/DisclaimerFairHousing';
import CloseButton from '@client/components/generic/CloseButton';
import DropdownSort from '@client/components/generic/DropdownSort';
import LoadingSection from '@client/components/generic/LoadingSection';
import LazilyRenderedList from '@client/components/LazilyRenderedList';
import LoadMoreResultsButton from '@client/components/LoadMoreResultsButton';
import PropertyCard from '@client/components/PropertyCard';
import SelectorDivider from '@client/components/SelectorDivider';
import SRPFinanceCTA from '@client/components/SRPFinanceCTA';
import Footer from '@client/containers/footer.container';
import propertyCardTheme from '@client/css-modules/PropertyCard.css';
import theme from '@client/css-modules/SearchPageList.css';
import { SEARCH_LIST_SORT_OPTIONS, STATUSES } from '@client/store/constants';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getPropertyCountDisplay } from '@client/utils/property.utils';
import {
  getIsAdCardForCobrand,
  getIsFinanceAd,
  getIsLenderCTACard,
  getIsLoanOfficerAd,
  getIsPropertyCardItem,
  getIsTopAgentsRankedAd,
} from '@client/utils/search-ad.utils';

import ConditionalFeature from '@client/components/ConditionalFeature';
import ImageCTAOrLenderCTA from '@client/components/ImageCTAOrLenderCTA';
import LenderCTACardCobranded from '@client/components/LenderCTACard/LenderCTACardCobranded';
import RecentUserActivityCTA from '@client/components/RecentUserActivity/RecentUserActivityCTA';
import ScaffoldingMarketingCardSRP from '@client/components/ScaffoldingUI/ScaffoldingMarketingCard/ScaffoldingMarketingCardSRP';
import SearchIconCobranded from '@client/components/SearchIcon/SearchIconCobranded';
import SearchLoanOfficerAdCobranded from '@client/components/SearchLoanOfficerAd/SearchLoanOfficerAdCobranded';
import { CARD_TYPES } from '@client/store/types/cobranded-components/lender-cta-card';
import {
  NormalizedProperty,
  PropertyListProperty,
  PropertyListWithAdCard,
} from '@client/store/types/property';
import { PropertyPhotosSizes } from '@client/store/types/property-photos';
import {
  SearchListSortField,
  SearchListSortOrder,
} from '@client/store/types/search';

const { cardHeight, cardWidth } = propertyCardTheme;
const PROPERTY_CARD_DIMENSIONS = {
  width: cardWidth,
  height: cardHeight,
};

const MARKETING_CARD_DIMENSIONS = {
  width: cardWidth,
  height: 'auto',
  padding: '0px 0px 20px 0px',
};

type Props = {
  /* to determine if we should search for watchlist */
  shouldFetchWatchListData: boolean;
  /* function to grab watchlist data */
  getWatchListData: () => void;
  /* Open a mobile PDP, where we want to pre-populate the PDP with some of the property
   * data that we already have in the property card */
  handleOpenPDP: (item: NormalizedProperty) => void;
  /* reporting pdp show detail click */
  reportPropertyCardClick: () => void;
  /* close out of the Property sort view */
  closeSearchList: () => void;
  /* The array of properties */
  propertyResults: PropertyListProperty[];
  propertyResultsWithAdCard: PropertyListWithAdCard[];
  /* The type of sorting (ex: AVM, List Price) */
  sortField: SearchListSortField | null;
  /* Whether to sort by High-to-Low or Low-to-High */
  sortOrder: SearchListSortOrder | null;
  /* Function to resort the properties */
  handleChangeSort: (field, order) => void;
  isPropertyListInitStatus: boolean;
  isScaffoldingCMGMarketingCardDataForSRP?: boolean;
  isTooLowZoom: boolean;
  photoSize: PropertyPhotosSizes;
  shouldShowLoadingIndicatorWhenLoading: boolean;
  /* Returns true once the call to Property Graph with the properties has completed successfully */
  isPropertyListSuccessStatus: boolean;
  /* Will be true if the properties request returns `null`.  This can sometimes happen if the query
   * is for a very large area with many filters and a sort applied */
  isPropertyListErrorStatus: boolean;
  /* Kicks off the saga to grab the properties for the sort */
  fetchProperties: () => void;
  watchListItemsCount: number;
  handleLoadMoreProperties: () => void;
  /* Whether the next page of properties is loading at the end of the list */
  isLoadingMore: boolean;
  activeFilterCount: number;
  areMorePropertiesAvailableToLoad: boolean;
  totalPropertyCount: null | number;
  handleSearchListUnwatchClick: (slug: string) => void;
  handleSearchListUnwatchConfirmClick: (slug: string) => void;
  handleOpenMobileFilters: () => void;
  handleOpenSortMenu: () => void;
  handleUpdateListScrollPosition: (scrollPosition: number) => void;
  handleReportNoPropertyResults: () => void;
  handleReportHasPropertyResults: () => void;
  isSortControlDisabled: boolean;
  /* The 'no results' background causes the mobile filters to be hard to view, so hiding it
   * when mobile filters are shown */
  shouldHideNoResultsBackground?: boolean;
  previousScrollPosition: number;
  isMLSCoverageIncompleteForMapArea: boolean;
  hasSearchMLSCoverageLoaded: boolean;
  hasSavedCurrentSearch: boolean;
  lastUpdatedTimestamp: string;
  shouldShowDisclaimerFairHousingNY: boolean;
  maxResultCount: number;
  handleSaveSearch: (location: 'list') => void;
  isCanaryUIFeatureEnabled?: boolean;
  isMobileView?: boolean;
  isLoggedIn: boolean;
  isFourHundredPercentZoom?: boolean;
  saveSearchButtonConfig: any | null;
};

type State = {
  invalidateSizeTrigger: string;
  scrollPosition: number;
};

type TopBarControlButtonProps = {
  children?: any;
  onClick?: (e) => void;
  ariaLabel?: string;
  disabled?: boolean;
  theme: Theme;
  dataHcName?: string;
};

const TopBarControlButton = React.forwardRef(
  (
    {
      children,
      onClick,
      theme,
      ariaLabel,
      disabled,
      dataHcName,
      ...rest
    }: TopBarControlButtonProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <button
      data-hc-name={dataHcName}
      className={classNames(theme.TopBarControlButton, {
        [theme.TopBarControlButtonDisabled]: disabled,
      })}
      aria-label={ariaLabel}
      type="button"
      onKeyDown={onClick ? onEnterOrSpaceKey(onClick) : undefined}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
);

/* A fullscreen list view of property cards for the search area.  This component is only accessible on mobile
 * screen sizes, either via clicking the "List" bottom nav item or by including view=list as query parameter */
export default class SearchPageList extends Component<Props, State> {
  state: State = {
    invalidateSizeTrigger: '',
    scrollPosition: 0,
  };

  scrollableContainerEle: null | Element = null;

  componentDidMount() {
    const {
      shouldFetchWatchListData,
      propertyResults,
      isPropertyListInitStatus,
      isPropertyListSuccessStatus,
      handleReportNoPropertyResults,
      handleReportHasPropertyResults,
    } = this.props;

    if (isPropertyListInitStatus) {
      this.props.fetchProperties();
    }
    if (shouldFetchWatchListData) {
      /* Fetch watchlist to show which properties are currently in watchlist */
      this.props.getWatchListData();
    }
    if (isPropertyListSuccessStatus) {
      if (propertyResults.length === 0) {
        handleReportNoPropertyResults();
      } else {
        handleReportHasPropertyResults();
      }
    }
    window.addEventListener('resize', this.debouncedHandleResize);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      shouldFetchWatchListData,
      getWatchListData,
      propertyResults,
      isPropertyListSuccessStatus,
      handleReportNoPropertyResults,
      handleReportHasPropertyResults,
      previousScrollPosition,
    } = this.props;

    if (shouldFetchWatchListData && !prevProps.shouldFetchWatchListData) {
      /* Fetch watchlist to show which properties are currently in watchlist */
      getWatchListData();
    }

    if (!prevProps.isPropertyListSuccessStatus && isPropertyListSuccessStatus) {
      if (propertyResults.length === 0) {
        handleReportNoPropertyResults();
      } else {
        handleReportHasPropertyResults();
      }
    }
    /* When scroll position prop is changed from a non-zero value to zero, we want to
     * scroll the list back to the top */
    if (
      prevProps.previousScrollPosition > 0 &&
      previousScrollPosition === 0 &&
      this.scrollableContainerEle
    ) {
      this.scrollableContainerEle.scrollTop = previousScrollPosition;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  onPropertyClick = (slug: string): void => {
    const { handleOpenPDP, reportPropertyCardClick } = this.props;
    const property = this.props.propertyResultsWithAdCard.find(
      (property): property is PropertyListProperty => {
        return getIsPropertyCardItem(property) && property.slug === slug;
      }
    );

    if (property) {
      handleOpenPDP(property);
      reportPropertyCardClick();
    } else {
      throw new Error(
        'Property not found for property list property click with slug ' + slug
      );
    }
  };

  handleResize = (
    e: any /* TS doesn't recognize `clientHeight` on UIEvent.currentTarget */
  ) => {
    if (
      e &&
      e.currentTarget &&
      (e.currentTarget.clientHeight || e.currentTarget.clientWidth)
    ) {
      const triggerString = `${e.currentTarget.clientHeight}x${e.currentTarget.clientWidth}`;
      if (triggerString !== this.state.invalidateSizeTrigger) {
        this.setState({
          invalidateSizeTrigger: triggerString,
        });
      }
    }
  };

  debouncedHandleResize = debounce(this.handleResize, 300);

  handleChangeSort = (field: string, order: string) => {
    const { handleChangeSort, handleUpdateListScrollPosition } = this.props;
    handleUpdateListScrollPosition(0);
    handleChangeSort(field, order);
  };

  conditionallySetListScrollPosition = (ele) => {
    const { previousScrollPosition } = this.props;
    if (ele) {
      /* We know that the ScrollableContainer component's rendered element can't be of type Text */
      const node = findDOMNode(ele) as Element | null;
      this.scrollableContainerEle = node;

      if (node && previousScrollPosition) {
        node.scrollTop = previousScrollPosition;
      }
    }
  };

  handleSaveSearchClick = () => {
    const { hasSavedCurrentSearch, handleSaveSearch } = this.props;
    if (!hasSavedCurrentSearch) {
      handleSaveSearch('list');
    }
  };

  render() {
    const {
      closeSearchList,
      isSortControlDisabled,
      propertyResultsWithAdCard,
      activeFilterCount,
      areMorePropertiesAvailableToLoad,
      isLoadingMore,
      shouldShowLoadingIndicatorWhenLoading,
      isPropertyListSuccessStatus,
      isPropertyListErrorStatus,
      isScaffoldingCMGMarketingCardDataForSRP,
      isTooLowZoom,
      photoSize,
      totalPropertyCount,
      sortField,
      sortOrder,
      shouldHideNoResultsBackground,
      isMLSCoverageIncompleteForMapArea,
      hasSearchMLSCoverageLoaded,
      handleSearchListUnwatchClick,
      handleSearchListUnwatchConfirmClick,
      handleLoadMoreProperties,
      handleOpenMobileFilters,
      handleUpdateListScrollPosition,
      handleOpenSortMenu,
      hasSavedCurrentSearch,
      lastUpdatedTimestamp,
      shouldShowDisclaimerFairHousingNY,
      maxResultCount,
      isCanaryUIFeatureEnabled,
      isMobileView,
      isLoggedIn,
      isFourHundredPercentZoom,
      saveSearchButtonConfig,
    } = this.props;
    const { invalidateSizeTrigger } = this.state;
    const DisclaimerFairHousingNYComponent = (
      <DisclaimerFairHousing
        theme={theme}
        url={'https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf'}
        ariaLabel={'Fair Housing Policy for New York State'}
        state={'New York'}
      />
    );

    const isLoading =
      (!isPropertyListSuccessStatus &&
        !isPropertyListErrorStatus &&
        shouldShowLoadingIndicatorWhenLoading) ||
      (propertyResultsWithAdCard.length === 0 && !hasSearchMLSCoverageLoaded);

    const sortFieldLabel = SEARCH_LIST_SORT_OPTIONS.find(
      (x) => x.field === sortField && x.order === sortOrder
    )?.label;

    const { saveSearchButtonLabel } = saveSearchButtonConfig;
    const saveSearchText = saveSearchButtonLabel
      ? `Save ${saveSearchButtonLabel}`
      : 'Save search';

    return (
      <CobrandedComponent>
        {({
          styles: {
            defaultTextColor,
            searchListControlsColor,
            activeFilterCountColor,
          },
          utils: { getNoResultsBackgroundImageUrl },
        }: CobrandedComponentArgs) => (
          <div
            className={theme.SearchPageList}
            style={isFourHundredPercentZoom ? { position: 'static' } : {}}
          >
            <div className={theme.TopBar} data-hc-name="topbar">
              <div className={theme.TopBarInner}>
                <div
                  className={theme.TopBarControls}
                  style={{ color: searchListControlsColor }}
                >
                  <TopBarControlButton
                    dataHcName="filters-button"
                    ariaLabel="Filters"
                    theme={theme}
                    onClick={handleOpenMobileFilters}
                  >
                    <div className={theme.Control}>
                      <div
                        className={theme.ActiveFilterCount}
                        style={{ backgroundColor: activeFilterCountColor }}
                      >
                        {activeFilterCount}
                      </div>
                      <div>Filters</div>
                    </div>
                  </TopBarControlButton>
                  <SelectorDivider theme={theme} />
                  <TopBarControlButton
                    dataHcName="save-search-button"
                    ariaLabel={saveSearchText}
                    theme={theme}
                    onClick={this.handleSaveSearchClick}
                  >
                    <div
                      className={classNames(theme.Control, {
                        [theme.DisableControl]: hasSavedCurrentSearch,
                      })}
                    >
                      {saveSearchText}
                    </div>
                  </TopBarControlButton>
                  <SelectorDivider theme={theme} />
                  <DropdownSort
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onChangeSort={this.handleChangeSort}
                    onOpenDropdown={handleOpenSortMenu}
                    isDisabled={isSortControlDisabled}
                    theme={theme}
                    Button={
                      <TopBarControlButton
                        dataHcName="sort-button"
                        ariaLabel="Sort"
                        disabled={isSortControlDisabled}
                        theme={theme}
                      >
                        <div className={theme.Control}>Sort</div>
                      </TopBarControlButton>
                    }
                  />
                </div>
                <CloseButton
                  dataHcName="close-button"
                  ariaLabel="Close search list"
                  onClick={closeSearchList}
                  theme={theme}
                />
              </div>
            </div>
            <div className={theme.PropertyResults}>
              <LoadingSection
                theme={theme}
                /* Show loading indicator if either property results are loading OR if we have no property results
                 * and we're still waiting on the MLS coverage results */
                isLoading={isLoading}
              >
                {propertyResultsWithAdCard.length > 0 ? (
                  <div
                    className={theme.SearchListScrollableContainer}
                    ref={this.conditionallySetListScrollPosition}
                  >
                    <div className={theme.MinHeightWrapper}>
                      <div className={theme.MobilePropertyTopWrapper}>
                        <Alert
                          type="polite"
                          className={theme.MobilePropertyCountWrapper}
                        >
                          {getPropertyCountDisplay(
                            totalPropertyCount,
                            maxResultCount
                          )}
                        </Alert>
                        {shouldShowDisclaimerFairHousingNY && (
                          <div
                            className={
                              theme.PropertyDisclaimerFairHousingWrapper
                            }
                          >
                            {DisclaimerFairHousingNYComponent}
                          </div>
                        )}
                        {isLoggedIn && (
                          <ConditionalFeature
                            renderIfFeaturesEnabled={['recent_user_activity']}
                          >
                            <RecentUserActivityCTA dataEventName="click_recent_activity_srp_view_search_activity" />
                          </ConditionalFeature>
                        )}
                      </div>
                      <LazilyRenderedList
                        dataHcName="property-list"
                        dataHcLastUpdated={lastUpdatedTimestamp}
                        className={theme.PropertyList}
                        preloadBuffer={800}
                        scrollableAncestorClassName={
                          theme.SearchListScrollableContainer
                        }
                        shouldKeepItemsRendered
                        theme={theme}
                        itemDimensionsStyle={PROPERTY_CARD_DIMENSIONS}
                        customDimensionsStyle="data-custom-dimensions-style"
                        llKeyField="data-ll-key"
                        updateItemsToRenderTrigger={invalidateSizeTrigger}
                        isMoreToLoad={false}
                        handleReportItemsInViewport={(_, position) => {
                          handleUpdateListScrollPosition(position);
                        }}
                      >
                        {propertyResultsWithAdCard.map((item, index) => {
                          if (getIsPropertyCardItem(item)) {
                            return (
                              <PropertyCard
                                theme={theme}
                                data-ll-key={item.slug}
                                key={item.slug}
                                photoSize={photoSize}
                                isShowingWatchListActionButton
                                isShowingDaysOnMarket
                                propertyDetails={item}
                                status={STATUSES.SUCCESS}
                                shouldHandleCheckingForWatchListStatus={false}
                                isAddedToWatchList={!!item.isAddedToWatchList}
                                goToProperty={this.onPropertyClick}
                                handleReportUnwatchClick={
                                  handleSearchListUnwatchClick
                                }
                                handleReportUnwatchConfirmClick={
                                  handleSearchListUnwatchConfirmClick
                                }
                              />
                            );
                          } else if (
                            isCanaryUIFeatureEnabled &&
                            isScaffoldingCMGMarketingCardDataForSRP
                          ) {
                            return (
                              <ScaffoldingMarketingCardSRP
                                key="ScaffoldingMarketingCardSRP"
                                data-ll-key="ScaffoldingMarketingCardSRP"
                              />
                            );
                          } else if (getIsLenderCTACard(item)) {
                            return (
                              <ImageCTAOrLenderCTA
                                key={`ImageCTAOrLenderCTA-${index}`}
                                data-ll-key={`ImageCTAOrLenderCTA-${index}`}
                                area="srp"
                                theme={theme}
                                ordinal={index}
                                data-custom-dimensions-style={
                                  isMobileView && MARKETING_CARD_DIMENSIONS
                                }
                                LenderCTA={
                                  <LenderCTACardCobranded
                                    area="srp"
                                    ordinal={index}
                                    cardType={CARD_TYPES.BOTH}
                                    key={`DefaultAdCard-${index}`}
                                    data-ll-key={`DefaultAdCard-${index}`}
                                  />
                                }
                              />
                            );
                          } else if (getIsTopAgentsRankedAd(item)) {
                            return (
                              <ConnectWithATopLocalAgent
                                key="ConnectWithATopLocalAgent"
                                data-ll-key="ConnectWithATopLocalAgent"
                                theme={theme}
                              />
                            );
                          } else if (getIsLoanOfficerAd(item)) {
                            return (
                              <SearchLoanOfficerAdCobranded
                                key="SearchLoanOfficerAd"
                                data-ll-key="SearchLoanOfficerAd"
                              />
                            );
                          } else if (getIsFinanceAd(item)) {
                            return (
                              <SRPFinanceCTA
                                key="SRPFinanceCTA"
                                data-ll-key="SRPFinanceCTA"
                              />
                            );
                          } else if (getIsAdCardForCobrand(item)) {
                            return (
                              <AdCardForCobrand
                                key="AdCardForCobrand"
                                data-ll-key="AdCardForCobrand"
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </LazilyRenderedList>
                      {isLoadingMore ? (
                        <LoadingSection
                          className={theme.NextPageLoader}
                          isLoading
                        />
                      ) : (
                        areMorePropertiesAvailableToLoad && (
                          <LoadMoreResultsButton
                            handleLoadMoreClick={handleLoadMoreProperties}
                          />
                        )
                      )}
                    </div>
                    <Footer theme={theme} shouldUseSectionElement />
                  </div>
                ) : (
                  <div
                    className={theme.NoResults}
                    style={{
                      backgroundImage: shouldHideNoResultsBackground
                        ? 'none'
                        : `url(${getNoResultsBackgroundImageUrl()})`,
                    }}
                  >
                    <div className={theme.NoResultsInner}>
                      {!isMLSCoverageIncompleteForMapArea && (
                        <div className={theme.NoResultsTooltipArrow} />
                      )}
                      <div className={theme.NoResultsTooltipContent}>
                        <SearchIconCobranded className={theme.SearchIcon} />
                        <div
                          className={theme.NoResultsTooltipText}
                          style={{ color: defaultTextColor }}
                        >
                          {isPropertyListErrorStatus
                            ? 'Please zoom in on the map or search for a new city, ZIP code or address to view properties'
                            : isTooLowZoom
                              ? 'Please search for a city, ZIP code or address to view properties.'
                              : isMLSCoverageIncompleteForMapArea
                                ? "We don't have full coverage of active listings in this area."
                                : 'No results. Adjust your filters or search for a new location.'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </LoadingSection>
              {sortFieldLabel && (
                <div className={theme.VisuallyHidden} aria-live="polite">
                  {isLoading
                    ? `Loading search results sorted by ${sortFieldLabel}`
                    : `Finished loading search results sorted by ${sortFieldLabel}`}
                </div>
              )}
            </div>
          </div>
        )}
      </CobrandedComponent>
    );
  }
}
