import {
  getLenderCtaPartnerUserId,
  getUserPartnerUserId,
} from '@client/store/selectors/auth.selectors';
import { getLenderCtasConfig } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import { getActiveHomeownerOrPDPSlug } from '@client/store/selectors/router.selectors';
import { LenderCTAsAreas } from '@client/store/types/analytics';
import { replaceTemplateKeyWithLoId } from '@client/utils/loan-officer.utils';
import queryString from 'query-string';
import { useSelector } from 'react-redux';

export type LenderCtaPdpConfig = {
  ctaText: string | null;
  ctaUrl: string | null;
  buttonColor: string | null;
  buttonFontColor: string | null;
  nativeAppNavKey: string | null;
};

export const useLenderCtaPdpConfig = (): LenderCtaPdpConfig => {
  const lenderCtasConfig = useSelector(getLenderCtasConfig);
  const loId = useSelector(getLoanOfficerId) as ReturnType<
    typeof getLoanOfficerId
  >;

  const loUrl =
    !!lenderCtasConfig?.pdp?.loUrlTemplate && loId
      ? replaceTemplateKeyWithLoId(lenderCtasConfig.pdp.loUrlTemplate, loId)
      : undefined;

  return {
    ctaText: lenderCtasConfig?.pdp?.ctaText || null,
    ctaUrl: loUrl || lenderCtasConfig?.pdp?.ctaUrl || null,
    buttonColor: lenderCtasConfig?.pdp?.buttonColor || null,
    buttonFontColor: lenderCtasConfig?.pdp?.buttonFontColor || null,
    nativeAppNavKey: lenderCtasConfig?.pdp?.nativeAppNavKey || null,
  };
};

export const useLenderCtaUrlModifier = (
  ctaUrl?: string | null
): string | null => {
  const shouldAddSlugToUrl = useSelector(
    getIsFeatureEnabled('add_property_slug_to_lender_cta_destination_url')
  );

  const isCalloutsCardIncludePartnerUserIdFlagEnabled = useSelector(
    getIsFeatureEnabled('callouts_html_card_include_partner_user_id')
  );

  const isAddPartnerUserIdToCtaForAuthenticatedUsersFlagEnabled = useSelector(
    getIsFeatureEnabled(
      'add_partner_user_id_to_cta_destination_url_for_authenticated_users'
    )
  );

  const lenderCtaPartnerUserIdKey = useSelector(getLenderCtaPartnerUserId);
  const userPartnerUserId = useSelector(getUserPartnerUserId);
  const slug = useSelector(getActiveHomeownerOrPDPSlug);

  if (!ctaUrl) {
    return null;
  }
  let generatedCtaUrl = ctaUrl;

  // add slug if it exists and feature flag is enabled
  if (shouldAddSlugToUrl && slug) {
    generatedCtaUrl = queryString.stringifyUrl({
      url: generatedCtaUrl,
      query: { slug },
    });
  }

  // add partner user id if it exists and feature flag is enabled
  if (
    isCalloutsCardIncludePartnerUserIdFlagEnabled &&
    lenderCtaPartnerUserIdKey
  ) {
    generatedCtaUrl = queryString.stringifyUrl({
      url: generatedCtaUrl,
      query: { partner_user_id: lenderCtaPartnerUserIdKey },
    });
  } else if (
    isAddPartnerUserIdToCtaForAuthenticatedUsersFlagEnabled &&
    userPartnerUserId
  ) {
    generatedCtaUrl = queryString.stringifyUrl({
      url: generatedCtaUrl,
      query: { partner_user_id: userPartnerUserId },
    });
  }

  return generatedCtaUrl;
};

export const useLenderCtaNotPdpUrl = (
  area: Exclude<LenderCTAsAreas, 'pdp'>
): string | null => {
  const lenderCtasConfig = useSelector(getLenderCtasConfig);
  const loId = useSelector(getLoanOfficerId);

  const urlTemplate = lenderCtasConfig?.[area]?.loUrlTemplate || '';
  if (urlTemplate && loId) {
    return replaceTemplateKeyWithLoId(urlTemplate, loId);
  } else {
    return lenderCtasConfig?.[area]?.ctaUrl || null;
  }
};
