import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle<{
  accessibilityFocusOutlineColor: string;
  accessibilityFocusOutlineStyle: string;
  accessibilityFocusOutlineWidth: string;
  buttonDefaultTextColor: string;
}>`
  iframe#react-refresh-overlay {
    display: none;
  }
  html {
    & div:focus,
    & a:focus,
    & button:focus,
    & input:focus,
    & textarea:focus,
    & span:focus,
    & ul:focus,
    & li:focus,
    & [role='button']:focus,
    & [type='checkbox']:focus,
    & [role='checkbox']:focus,
    & [role='option']:focus,
    & [role='listitem']:focus,
    & [role='menuitem']:focus {
      outline-color: ${({ accessibilityFocusOutlineColor }) =>
        accessibilityFocusOutlineColor};
      outline-style: ${({ accessibilityFocusOutlineStyle }) =>
        accessibilityFocusOutlineStyle};
      outline-width: ${({ accessibilityFocusOutlineWidth }) =>
        accessibilityFocusOutlineWidth};
    }
    & input[type='search']::-webkit-search-decoration,
    & input[type='search']::-webkit-search-cancel-button,
    & input[type='search']::-webkit-search-results-button,
    & input[type='search']::-webkit-search-results-decoration {
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-appearance: none;
    }
  }
  button {
    color: ${({ buttonDefaultTextColor }) => buttonDefaultTextColor};
  }
`;
