import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React from 'react';
import { useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';
import defaultTheme from '@client/css-modules/YourTeam/YourTeamMainCTAButton.css';
import { useMainCTAOnClick } from '@client/hooks/ad-card-main-cta.hooks';
import {
  useLenderCtaNotPdpUrl,
  useLenderCtaPdpConfig,
  useLenderCtaUrlModifier,
} from '@client/hooks/lender-cta-config.hooks';
import { useLoInfoProps } from '@client/hooks/lo-info-provider.hooks';
import { useOutboundCtaUrl } from '@client/hooks/outbound-cta-url.hooks';
import { HOMEOWNER_ROUTES, View } from '@client/routes/constants';
import { eventType } from '@client/store/actions/analytics.actions';
import { getLenderCtasConfig } from '@client/store/selectors/cobranding.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

type Props = {
  eventName: eventType;
  theme: Theme;
  customBackgroundColor?: string;
  customTextColor?: string;
  customBorderRadius?: string;
};

const YourTeamMainCTAButton: React.FC<Props> = ({
  customBackgroundColor,
  customBorderRadius,
  customTextColor,
  eventName,
  theme,
}) => {
  const { loCtaUrl, loConfig } = useLoInfoProps();
  const lenderCTAs = useSelector(getLenderCtasConfig);
  const lenderCtaPdpConfig = useLenderCtaPdpConfig();
  const lenderCtaUrlPdp = lenderCtaPdpConfig.ctaUrl;
  const lenderCtaUrlHic = useLenderCtaNotPdpUrl('hic');
  const lenderCtaUrlHo = useLenderCtaNotPdpUrl('ho');
  const lenderCtaUrlSrp = useLenderCtaNotPdpUrl('srp');
  const currentView = useSelector(getCurrentView);
  const reportYourTeamEvent = useReportYourTeamEvent();

  let buttonText: string | undefined;
  let buttonUrl: string | undefined | null;

  if (currentView === View.HOME_IMPROVEMENT) {
    buttonText = lenderCTAs?.hic?.ctaText;
    buttonUrl = lenderCtaUrlHic;
  } else if (currentView && HOMEOWNER_ROUTES.includes(currentView)) {
    buttonText = lenderCTAs?.ho?.ctaText;
    buttonUrl = lenderCtaUrlHo;
  } else if (currentView === View.SEARCH) {
    buttonText = lenderCTAs?.srp?.ctaText;
    buttonUrl = lenderCtaUrlSrp;
  }

  /* default PDP config fallback */
  if (!buttonText || !buttonUrl) {
    buttonText = lenderCTAs?.pdp?.ctaText ?? loConfig?.hbPdp?.cta;
    buttonUrl = lenderCtaUrlPdp ?? loCtaUrl;
  }

  const modifiedLenderCtaUrl = useLenderCtaUrlModifier(buttonUrl);
  const outboundCtaUrl = useOutboundCtaUrl(modifiedLenderCtaUrl);
  const handleMainButtonClick = useMainCTAOnClick(() => {});
  const handlePrimaryClick = () => {
    reportYourTeamEvent(eventName, {
      ctaText: buttonText,
    });

    if (outboundCtaUrl) {
      window.open(outboundCtaUrl, '_blank');
    } else {
      handleMainButtonClick();
    }
  };

  if (!buttonText || (!buttonUrl && !loCtaUrl)) {
    return null;
  }
  return (
    <div className={theme.LenderCTA}>
      <PillButton
        ariaLabel={buttonText}
        className={theme.LenderCTAButton}
        onClick={handlePrimaryClick}
        onKeyDown={onEnterOrSpaceKey(handlePrimaryClick)}
        customBackgroundColor={customBackgroundColor}
        customTextColor={customTextColor}
        customBorderRadius={customBorderRadius}
      >
        {buttonText}
      </PillButton>
    </div>
  );
};

export default themr(
  'ThemedYourTeamMainCTAButton',
  defaultTheme
)(YourTeamMainCTAButton);
