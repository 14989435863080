import {
  ACTIVE_MODAL_URL_KEY,
  BROKERAGE_ATTRIBUTION_MODAL_KEY,
  CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY,
  ModalKey,
} from '@client/store/constants';
import { SSO_QUERY_KEYS } from '@client/store/sso-constants';
import { COBRAND_IDS } from '@client/store/types/cobranding';

/**
 * Definition of view ids.  Used both server-side and client-side to identify a view.
 */
export enum View {
  ALERTS = 'alerts',
  BROKERAGE_INFO = 'brokerage-info',
  CITIES = 'cities',
  CONCIERGE_TEAM = 'concierge-team',
  CONFIRM_USER = 'confirm-user',
  FEEDBACK = 'feedback',
  FORGOT_PASSWORD = 'forgot-password',
  HOMEPAGE = 'homepage',
  LEGAL = 'legal',
  LENDER_HOMEPAGE = 'lender-homepage',
  /* Note: 'CHD' (ComeHome Direct) prefix is used to denote these as end-user, consumer facing routes
   * whereas 'LO_DIRECT' prefix is used to denote LO Direct Dashboard routes.
   * Allows client to signup via a shared link and then associates them with the LO Direct LO */
  CHD_CLIENT_SIGN_UP = 'chd-client-sign-up',
  /* Allows client to signup following an invite email (by converting the invite to a user) and then
   * associates them with the LO Direct LO */
  CHD_CLIENT_INVITE_SIGN_UP = 'chd-client-invite-sign-up',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MLS_NUMBER = 'mls-number',
  NATIVE_APP_ERROR = 'native-app-error',
  NATIVE_APP_TEST = 'native-app-test',
  NOTIFICATIONS = 'notifications',
  /* Used for when we want to explicitly send the user here for when there's missing
   * data i.e. not just a bad URL */
  PAGE_404 = '404',
  PARTNER_API_CREDENTIAL = 'partner-api-credential',
  PLACE = 'place',
  PRIVACY_POLICY = 'privacy-policy',
  PRO_FINDER = 'pro-finder',
  PROFILE = 'profile',
  PROPERTY_DETAILS = 'property-details',
  RESET_PASSWORD = 'reset-password',
  SAVED_SEARCHES = 'saved-searches',
  SEARCH = 'search-results',
  SETTINGS = 'settings',
  SIGN_UP = 'sign-up',
  TERMS_OF_USE = 'terms-of-use',
  TERMS_AND_DEFINITIONS = 'terms-and-definitions',
  TEST_PROPERTIES = 'test-properties',
  TEST_SVGS = 'test-svgs',
  UNREAD_ALERTS_RESET = 'unread-alerts-reset',
  UNSUBSCRIBE = 'unsubscribe',
  USER_UNSUBSCRIBE = 'user-unsubscribe',
  WATCHLIST = 'watchlist',
  WELCOME_SEARCH = 'welcome-search',
  WELCOME_TRANSITION = 'welcome_transition',
  WELCOME_TRANSITION_INSET = 'welcome_transition_inset',
  WIDGET_DEMO_HERO = 'widget-demo-hero',
  WIDGET_DEMO_BANNER = 'widget-demo-banner',
  ZIPCODE = 'zipcode',

  // Admin views
  ADMIN = 'admin',
  ADMIN_ANALYTICS = 'admin-analytics',
  ADMIN_ROI = 'admin-roi',
  ADMIN_API = 'admin-api',
  ADMIN_SUPPORT = 'admin-support',

  // Homeowner views
  HOMEOWNER = 'homeowner',
  HOMEOWNER_CLAIMED_HOMES = 'homeowner-claimed-homes',
  HOME_DETAILS = 'home-details',
  HOME_IMPROVEMENT = 'home-improvement',
  HOME_INSURANCE = 'home-insurance',
  HOMEOWNER_PROPERTY_DETAILS = 'homeowner-property-details',
  HOME_VALUATION_FULL_HISTORY = 'home-valuation-full-history',
  LOAN_DETAILS = 'loan-details',
  UNAUTHENTICATED_ACCESS = 'unauthenticated-access',
  VERIFICATION = 'verification',

  // LO Direct dashboard views
  LO_DIRECT = 'lo-direct' /* NOTE: This view doesn't currently map to a page component - we redirect from it to /ch-direct/leads within server-side-redirect.ts */,
  LO_DIRECT_ACCOUNT = 'lo-direct-account',
  LO_DIRECT_ACCOUNT_PLANS = 'lo-direct-account-plans',
  LO_DIRECT_ACTIVITY = 'lo-direct-activity',
  LO_DIRECT_CLIENTS = 'lo-direct-clients',
  LO_DIRECT_FORGOT_PASSWORD = 'lo-direct-forgot-password',
  LO_DIRECT_LEADS = 'lo-direct-leads',
  LO_DIRECT_LOGIN = 'lo-direct-login',
  LO_DIRECT_RESET_PASSWORD = 'lo-direct-reset-password',
  LO_DIRECT_RESUBSCRIBE = 'lo-direct-resubscribe',
  LO_DIRECT_INVITE_SIGNUP = 'lo-direct-invite-signup',
  LO_DIRECT_SIGNUP = 'lo-direct-signup',
  LO_DIRECT_SIGNUP_PLANS = 'lo-direct-signup-plans',
  LO_DIRECT_SUBSCRIBE = 'lo-direct-subscribe',
  LO_DIRECT_SUPPORT = 'lo-direct-support',
}

/* LO Direct routes that require being both signed-in and registered with Stripe */
export const LO_DIRECT_AUTHENTICATED_ROUTES: View[] = [
  View.LO_DIRECT /* NOTE: This view doesn't currently map to a page component - we redirect from it to /ch-direct/leads within server-side-redirect.ts */,
  View.LO_DIRECT_ACCOUNT,
  View.LO_DIRECT_ACCOUNT_PLANS,
  View.LO_DIRECT_ACTIVITY,
  View.LO_DIRECT_CLIENTS,
  View.LO_DIRECT_LEADS,
  View.LO_DIRECT_SUPPORT,
];

/* LO Direct routes that require being signed-in but not registered with Stripe */
export const LO_DIRECT_SEMIAUTHENTICATED_ROUTES: View[] = [
  View.LO_DIRECT_SUBSCRIBE,
  View.LO_DIRECT_RESUBSCRIBE,
  View.LO_DIRECT_SIGNUP_PLANS,
];

/* LO Direct routes that require neither being signed-in or registered with Stripe */
export const LO_DIRECT_UNAUTHENTICATED_ROUTES: View[] = [
  View.LO_DIRECT_FORGOT_PASSWORD,
  View.LO_DIRECT_LOGIN,
  View.LO_DIRECT_RESET_PASSWORD,
  View.LO_DIRECT_SIGNUP,
  View.LO_DIRECT_INVITE_SIGNUP,
];

export const LO_DIRECT_ROUTES: View[] = [
  ...LO_DIRECT_UNAUTHENTICATED_ROUTES,
  ...LO_DIRECT_AUTHENTICATED_ROUTES,
  ...LO_DIRECT_SEMIAUTHENTICATED_ROUTES,
];

export const ADMIN_ROUTES: View[] = [
  View.ADMIN,
  View.ADMIN_ANALYTICS,
  View.ADMIN_ROI,
  View.ADMIN_API,
  View.ADMIN_SUPPORT,
];

/**
 * Routes for which campaign is set to 'homebuyer'
 * in getComeHomeLogoRedirect selector in cobranding utils
 * Any new route for which campaign needs to be 'homebuyer'
 * should be added to this array.
 */
export const HOME_BUYER_ROUTES: View[] = [
  View.ALERTS,
  View.BROKERAGE_INFO,
  View.CITIES,
  View.CONFIRM_USER,
  View.FEEDBACK,
  View.LENDER_HOMEPAGE,
  View.PROPERTY_DETAILS,
  View.SAVED_SEARCHES,
  View.SEARCH,
  View.WATCHLIST,
  View.ZIPCODE,
  View.PLACE,
  View.NOTIFICATIONS,
  View.WELCOME_SEARCH,
  View.WELCOME_TRANSITION,
];

export const HOMEOWNER_BREAKOUT_ROUTES: View[] = [
  View.HOME_IMPROVEMENT,
  View.HOME_INSURANCE,
  View.HOME_VALUATION_FULL_HISTORY,
  View.LOAN_DETAILS,
  View.HOME_DETAILS,
  View.VERIFICATION,
];

export const HOMEOWNER_ROUTES: View[] = [
  View.HOMEOWNER,
  View.HOMEOWNER_CLAIMED_HOMES,
  View.HOMEOWNER_PROPERTY_DETAILS,
  View.PRO_FINDER,
  ...HOMEOWNER_BREAKOUT_ROUTES,
];

export type Route = { view: View; params?: Params; query?: Query };
export type Params = { [key: string]: string };
export type Query = { [key: string]: string };

/* Views that load the same SearchPage.jsx page component */
export const ALL_SEARCH_VIEWS: View[] = [
  View.SEARCH,
  View.CITIES,
  View.ZIPCODE,
  View.PLACE,
  View.MLS_NUMBER,
];

/**
 * Definition of keys to be used in view paths.  Useful for when we need to manually
 * build URLs by finding/replacing the keys in paths.
 */
export const VIEW_PATH_PARAM_KEYS: Partial<Record<View, string>> = {
  [View.PROPERTY_DETAILS]: ':slug',
  [View.HOMEOWNER_PROPERTY_DETAILS]: ':slug',
  [View.PRO_FINDER]: ':slug',
};
/*
 * Definition of view paths.  The path that displays in the URL.
 */
export const ROOT_PATH = '/';
export const VIEW_PATHS = {
  [View.ALERTS]: '/alerts',
  [View.BROKERAGE_INFO]: '/brokerage-info',
  [View.CHD_CLIENT_SIGN_UP]: '/chd-client-sign-up',
  [View.CHD_CLIENT_INVITE_SIGN_UP]: '/chd-client-invite-sign-up',
  [View.CITIES]: '/cities/:placeSlug',
  [View.CONCIERGE_TEAM]: '/concierge-team',
  [View.CONFIRM_USER]: '/confirm-user',
  [View.FEEDBACK]: '/feedback',
  [View.FORGOT_PASSWORD]: '/forgot-password',
  [View.HOMEPAGE]: '/',
  [View.LEGAL]: '/legal',
  [View.LOGIN]: '/login',
  [View.LOGOUT]: '/logout',
  [View.LENDER_HOMEPAGE]: '/lender-homepage',
  [View.MLS_NUMBER]: '/mls-number',
  [View.NATIVE_APP_TEST]: '/native-app-integration-test',
  [View.PLACE]: '/place/:place',
  [View.PRIVACY_POLICY]: '/privacy-policy',
  [View.PROFILE]: '/profile',
  [View.PROPERTY_DETAILS]: `/property-details/${
    VIEW_PATH_PARAM_KEYS[View.PROPERTY_DETAILS]
  }`,
  [View.PRO_FINDER]: `/referral/pro-finder/${
    VIEW_PATH_PARAM_KEYS[View.PRO_FINDER]
  }`,
  [View.RESET_PASSWORD]: '/reset-password',
  [View.SEARCH]: '/search',
  [View.SAVED_SEARCHES]: '/saved-searches',
  [View.SETTINGS]: '/settings',
  [View.SIGN_UP]: '/sign-up',
  [View.TERMS_OF_USE]: '/terms-of-use',
  [View.TERMS_AND_DEFINITIONS]: '/terms-and-definitions',
  [View.TEST_PROPERTIES]: '/internal/test-properties',
  [View.TEST_SVGS]: '/internal/test-svgs',
  [View.UNREAD_ALERTS_RESET]: '/internal/reset-alerts',
  [View.WATCHLIST]: '/watchlist',
  [View.ZIPCODE]: '/zipcode/:zipcode',
  [View.NOTIFICATIONS]: '/settings/notifications',
  [View.WELCOME_SEARCH]: '/welcome-search',
  [View.WELCOME_TRANSITION]: '/welcome-transition',
  [View.WELCOME_TRANSITION_INSET]:
    '/welcome-to-comehome' as '/welcome-to-comehome',
  [View.PAGE_404]: '/404',
  [View.PARTNER_API_CREDENTIAL]: '/partner-api-credential',
  [View.NATIVE_APP_ERROR]: '/error',
  [View.UNSUBSCRIBE]: '/unsubscribe',
  [View.USER_UNSUBSCRIBE]: '/users/unsubscribe',
  [View.WIDGET_DEMO_BANNER]: '/widget-demo-banner',
  [View.WIDGET_DEMO_HERO]: '/widget-demo-hero',
  [View.UNAUTHENTICATED_ACCESS]: '/login-to-continue',

  // Admin view paths
  [View.ADMIN]: '/admin',
  [View.ADMIN_ANALYTICS]: '/admin/analytics',
  [View.ADMIN_ROI]: '/admin/roi',
  [View.ADMIN_API]: '/admin/api',
  [View.ADMIN_SUPPORT]: '/admin/support',

  // Homeowner view paths
  [View.HOMEOWNER]: '/homeowner',
  [View.HOMEOWNER_CLAIMED_HOMES]: '/homeowner/claimed-homes',
  [View.HOMEOWNER_PROPERTY_DETAILS]: `/homeowner/${
    VIEW_PATH_PARAM_KEYS[View.HOMEOWNER_PROPERTY_DETAILS]
  }`,

  // Homeowner breakout view paths
  [View.HOME_DETAILS]: '/homeowner/home-details/:slug',
  [View.HOME_IMPROVEMENT]: '/homeowner/home-improvement/:slug',
  [View.HOME_INSURANCE]: '/homeowner/home-insurance/:slug',
  [View.HOME_VALUATION_FULL_HISTORY]:
    '/homeowner/home-valuation-full-history/:slug',
  [View.LOAN_DETAILS]: '/homeowner/loan-details/:slug',
  [View.VERIFICATION]: '/homeowner/verification/:slug',

  // LO Direct view paths
  [View.LO_DIRECT]:
    '/ch-direct' /* NOTE: This view doesn't currently map to a page component - we redirect from it to /ch-direct/leads within server-side-redirect.ts */,
  [View.LO_DIRECT_ACCOUNT]: '/ch-direct/account',
  [View.LO_DIRECT_ACCOUNT_PLANS]: '/ch-direct/account/plans',
  [View.LO_DIRECT_ACTIVITY]: '/ch-direct/activity',
  [View.LO_DIRECT_CLIENTS]: '/ch-direct/clients',
  [View.LO_DIRECT_FORGOT_PASSWORD]: '/ch-direct/forgot-password',
  [View.LO_DIRECT_LEADS]: '/ch-direct/leads',
  [View.LO_DIRECT_LOGIN]: '/ch-direct/login',
  [View.LO_DIRECT_RESET_PASSWORD]: '/ch-direct/reset-password',
  [View.LO_DIRECT_RESUBSCRIBE]: '/ch-direct/resubscribe',
  [View.LO_DIRECT_INVITE_SIGNUP]: '/ch-direct/invite-signup',
  [View.LO_DIRECT_SIGNUP]: '/ch-direct/signup',
  [View.LO_DIRECT_SIGNUP_PLANS]: '/ch-direct/signup/plans',
  [View.LO_DIRECT_SUBSCRIBE]: '/ch-direct/subscribe',
  [View.LO_DIRECT_SUPPORT]: '/ch-direct/support',
} as const satisfies Record<View, string>;

export type ViewPath = (typeof VIEW_PATHS)[keyof typeof VIEW_PATHS];

export const HOMEOWNER_BREAKOUT_VIEW_PATHS = [
  VIEW_PATHS[View.HOME_DETAILS],
  VIEW_PATHS[View.HOME_IMPROVEMENT],
  VIEW_PATHS[View.HOMEOWNER_CLAIMED_HOMES],
  VIEW_PATHS[View.HOME_INSURANCE],
  VIEW_PATHS[View.HOME_VALUATION_FULL_HISTORY],
  VIEW_PATHS[View.LOAN_DETAILS],
  VIEW_PATHS[View.VERIFICATION],
];

const LO_DIRECT_VIEW_PATHS = LO_DIRECT_ROUTES.map((view) => VIEW_PATHS[view]);

const urlToViewConstant = () => {
  const viewConstantMap: Record<string, View> = {};
  Object.entries(VIEW_PATHS).forEach(([key, value]) => {
    viewConstantMap[value] = key as View;
  });
  return viewConstantMap;
};

export const PATH_URL_MAP_TO_VIEW = urlToViewConstant();

/**
 * Page titles to show for views within the site.
 * IMPORTANT: if the page title needs to be dynamically generated,
 * PAGE_TITLE_FOR_VIEW must either have no key for that page or be a
 * non-string value. You then need to add the logic for generating the title
 * to getPageTitleForView in store/selectors/router.selectors.js
 */
export const PAGE_TITLE_FOR_VIEW: Partial<Record<View, string | null>> = {
  [View.ADMIN]: 'Admin',
  [View.ALERTS]: 'Alerts',
  [View.BROKERAGE_INFO]: 'Brokerage Info',
  [View.CHD_CLIENT_SIGN_UP]: 'Sign Up',
  [View.CHD_CLIENT_INVITE_SIGN_UP]: 'Sign Up',
  [View.CITIES]: null,
  [View.CONCIERGE_TEAM]: 'Concierge Team',
  [View.CONFIRM_USER]: 'Confirm Your Account',
  [View.FEEDBACK]: 'Feedback',
  [View.FORGOT_PASSWORD]: 'Forgot Password',
  [View.HOME_DETAILS]: 'Home Details',
  [View.LEGAL]: 'Legal',
  [View.LO_DIRECT_ACCOUNT]: 'ComeHome Direct: Account',
  [View.LO_DIRECT_ACCOUNT_PLANS]: 'ComeHome Direct: Plans',
  [View.LO_DIRECT_ACTIVITY]: 'ComeHome Direct: Activity',
  [View.LO_DIRECT_CLIENTS]: 'ComeHome Direct: Clients',
  [View.LO_DIRECT_FORGOT_PASSWORD]: 'ComeHome Direct: Forgot Password',
  [View.LO_DIRECT_LOGIN]: 'ComeHome Direct: Login',
  [View.LO_DIRECT_LEADS]: 'ComeHome Direct: Leads',
  [View.LO_DIRECT_RESUBSCRIBE]: 'ComeHomeDirect: Resubscribe',
  [View.LO_DIRECT_INVITE_SIGNUP]: 'ComeHome Direct: Sign up',
  [View.LO_DIRECT_SIGNUP]: 'ComeHome Direct: Sign up',
  [View.LO_DIRECT_SUBSCRIBE]: 'ComeHome Direct: Subscribe',
  [View.LO_DIRECT_SUPPORT]: 'ComeHome Direct: Support',
  [View.LOAN_DETAILS]: 'Loan Details',
  [View.LOGIN]: 'Login',
  [View.LOGOUT]: 'Logout',
  [View.LENDER_HOMEPAGE]: 'Lender Home Page',
  [View.NATIVE_APP_TEST]: 'Native App Integration Test Page',
  [View.PRIVACY_POLICY]: 'ComeHome Privacy Policy',
  [View.PROFILE]: 'Your Profile',
  [View.PROPERTY_DETAILS]: null,
  [View.HOMEOWNER]: null,
  [View.HOMEOWNER_CLAIMED_HOMES]: null,
  [View.HOMEOWNER_PROPERTY_DETAILS]: null,
  [View.HOMEPAGE]: 'Home',
  [View.MLS_NUMBER]: 'Search by MLS#',
  [View.PRO_FINDER]: 'Find a Pro',
  [View.RESET_PASSWORD]: 'Reset Your Password',
  [View.SEARCH]: null,
  [View.SAVED_SEARCHES]: 'Saved Searches',
  [View.SETTINGS]: 'Settings',
  [View.SIGN_UP]: 'Sign Up',
  [View.TERMS_OF_USE]: 'Legal',
  [View.TERMS_AND_DEFINITIONS]: 'Legal',
  [View.TEST_PROPERTIES]: 'Test Properties',
  [View.TEST_SVGS]: 'Test SVGs',
  [View.UNREAD_ALERTS_RESET]: 'Reset Alerts',
  [View.WATCHLIST]: 'Saved Homes',
  [View.ZIPCODE]: null,
  [View.PLACE]: null,
  [View.NOTIFICATIONS]: 'Notifications',
  [View.WELCOME_SEARCH]: 'Welcome Search',
  [View.WELCOME_TRANSITION]: 'Welcome',
  [View.WELCOME_TRANSITION_INSET]: 'Welcome',
  [View.PAGE_404]: 'Page Not Found',
  [View.PARTNER_API_CREDENTIAL]: 'Credential',
  [View.NATIVE_APP_ERROR]: 'Error',
  [View.UNAUTHENTICATED_ACCESS]: 'Login to Continue',
  [View.UNSUBSCRIBE]: 'Unsubscribe',
  [View.VERIFICATION]: 'Home Verification',
  [View.WIDGET_DEMO_BANNER]: 'Banner Search Widget Demo',
  [View.WIDGET_DEMO_HERO]: 'Hero Search Widget Demo',
};

export const GENERIC_META_DESCRIPTION = "Explore ComeHome's property listings";

/* Routes for which to apply a `<meta name="robots" content="noindex">` in the SSR HTML server response */
export const NOINDEX_VIEW_PATHS = [
  VIEW_PATHS[View.HOMEOWNER_CLAIMED_HOMES],
  VIEW_PATHS[View.HOMEOWNER_PROPERTY_DETAILS],
  VIEW_PATHS[View.HOME_IMPROVEMENT],
  VIEW_PATHS[View.HOME_INSURANCE],
  VIEW_PATHS[View.HOME_VALUATION_FULL_HISTORY],
  VIEW_PATHS[View.PRO_FINDER],
  VIEW_PATHS[View.WELCOME_SEARCH],
  /* LO Direct is behind login, so indexing wouldn't be possible */
  ...LO_DIRECT_VIEW_PATHS,
];

export const INDEX_COBRAND_IDS: string[] = [COBRAND_IDS.WWW];

/* Query params to be allowed on every route.  Useful for params used to trigger global modals, etc. */
export const COMMON_QUERY_PARAMS = [
  ACTIVE_MODAL_URL_KEY,
  CANT_FIND_ADDRESS_MODAL_ACTIVE_KEY,
  BROKERAGE_ATTRIBUTION_MODAL_KEY,
  ...Object.values(SSO_QUERY_KEYS),
];

export type AccountNavItem = {
  route: View;
  title: string;
};
export type AccountModalItem = {
  modalKey: ModalKey;
  title: string;
};
export type AccountMenuItem = AccountModalItem | AccountNavItem;

export const ACCOUNT_MENU_ITEMS_WITH_AUTH: AccountMenuItem[] = [
  {
    route: View.PROFILE,
    title: PAGE_TITLE_FOR_VIEW[View.PROFILE]!,
  },
  {
    route: View.SETTINGS,
    title: PAGE_TITLE_FOR_VIEW[View.SETTINGS]!,
  },
  {
    modalKey: 'recent-user-activity' as ModalKey,
    title: 'Activity',
  },
  {
    route: View.LOGOUT,
    title: PAGE_TITLE_FOR_VIEW[View.LOGOUT]!,
  },
];

export const PROFINDER_QUERY_STRING = 'project' as 'project';
export const UNSUBSCRIBE_QUERY_STRING = 'inviteid' as 'inviteid';
export const USER_UNSUBSCRIBE_QUERY_STRING = 'user_id' as 'user_id';
export const USER_UNSUBSCRIBE_QUERY_TOKEN = 'token' as 'token';
export const RELOAD_RETRY_QUERY_KEY = 'retry' as 'retry';
export const LO_DIRECT_SIGNUP_PLANS_BACK_VIEW_KEY = 'backview' as 'backview';
export const LO_DIRECT_LOGIN_REDIRECT_KEY =
  'login-redirect' as 'login-redirect';
export const LO_DIRECT_LOGOUT_QUERY_KEY = 'logout' as 'logout';
export const LO_DIRECT_DISABLED_ERROR_QUERY_KEY =
  'disabled-error' as 'disabled-error';
