import { connect } from 'react-redux';

import SearchPageFilters from '@client/components/SearchPageFilters';
import {
  reportEvent,
  reportSearchFilterValueSelection,
} from '@client/store/actions/analytics.actions';
import {
  clearCurrentSavedSearch,
  fetchSavedSearchesIfNecessary,
  selectSavedSearch,
} from '@client/store/actions/saved-search.actions';
import {
  searchCancelPendingQueries,
  searchClearAllFilters,
  searchUpdateFilter,
} from '@client/store/actions/search.actions';
import { PARENT_EVENTS } from '@client/store/analytics-constants';
import { FilterKey } from '@client/store/filter-constants';
import { getUserBuyingPower } from '@client/store/selectors/auth.selectors';
import { getSearchFiltersOrder } from '@client/store/selectors/cobranding.selectors';
import {
  getIsDisplayMultiFamilySearchFiltersEnabled,
  getIsFeatureEnabled,
  getIsShowComingSoonInListingStatusFilterEnabled,
  selectAreTempEnhancedFilterOptionsEnabled,
} from '@client/store/selectors/enabled-features.selectors';
import {
  getSavedSearches,
  getSelectedSavedSearch,
} from '@client/store/selectors/saved-search.selectors';
import {
  getSearchFilterValues,
  getSearchPlaceGeoJSONDescription,
} from '@client/store/selectors/search.selectors';

const mapStateToProps = (state) => {
  const savedSearches = getSavedSearches(state);

  return {
    filterValues: getSearchFilterValues(state),
    filterSortOrder: getSearchFiltersOrder(state),
    savedSearches: savedSearches,
    hasSavedSearches: savedSearches.length > 0,
    selectedSavedSearch: getSelectedSavedSearch(state),
    geoJSONDescription: getSearchPlaceGeoJSONDescription(state),
    userBuyingPower: getUserBuyingPower(state),
    isSchoolDataEnabled: getIsFeatureEnabled('school_data')(state),
    isDisplayMultiFamilySearchFiltersEnabled:
      getIsDisplayMultiFamilySearchFiltersEnabled(state),
    isShowComingSoonInListingStatusFilterEnabled:
      getIsShowComingSoonInListingStatusFilterEnabled(state),
    isHidingAdvancedFilters: getIsFeatureEnabled(
      'hide_advanced_search_filters'
    )(state),
    isShowingGrantProgramToggle: getIsFeatureEnabled('grant_program')(state),
    areTempEnhancedFilterOptionsEnabled:
      selectAreTempEnhancedFilterOptionsEnabled(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleClearAllFilters: (
    isDisplayMultiFamilySearchFiltersEnabled: boolean
  ) => {
    dispatch(
      searchClearAllFilters({ isDisplayMultiFamilySearchFiltersEnabled })
    );
  },
  handleFilterChange: (
    key,
    value,
    isDisplayMultiFamilySearchFiltersEnabled = false
  ) => {
    dispatch(searchCancelPendingQueries());
    dispatch(searchUpdateFilter(key, value));
    if (
      isDisplayMultiFamilySearchFiltersEnabled &&
      key === 'propertyType' &&
      !value.includes('MULTI')
    ) {
      dispatch(searchUpdateFilter('unitsTotalMinMax', [null, null]));
    }
    dispatch(clearCurrentSavedSearch());
  },
  handleSavedSearchSelect: (id) => {
    dispatch(selectSavedSearch(id, 'click_filter_name'));
  },
  handleFetchSavedSearches: () => dispatch(fetchSavedSearchesIfNecessary()),
  reportValueSelection: (
    filterKey: FilterKey,
    descriptor: string | null,
    value: string | number | null
  ) => {
    dispatch(reportSearchFilterValueSelection(filterKey, descriptor, value));
  },
  reportShowAdvancedFilters: () =>
    dispatch(
      reportEvent(
        'click_search_filters_advanced_filters',
        PARENT_EVENTS.CLICK_SEARCH_FILTERS
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageFilters);
