import {
  FILTER_KEYS,
  FilterKey,
  INITIAL_FILTER_VALUES,
} from '@client/store/filter-constants';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { FocusOn } from 'react-focus-on';

import BuyingPowerPill from '@client/components/BuyingPowerPill';
import CobrandedStyles from '@client/components/CobrandedStyles';
import FlatButton from '@client/components/generic/FlatButton';
import LoadingSection from '@client/components/generic/LoadingSection';
import GrantProgramMobileFilterControl from '@client/components/GrantProgram/GrantProgramMobileFilterControl';
import GrantProgramMobileFilterListing from '@client/components/GrantProgram/GrantProgramMobileFilterListing';
import GreenCheckIconCobranded from '@client/components/GreenCheckIcon/GreenCheckIconCobranded';
import MobileFilterControlContainer from '@client/containers/mobile-filter-control.container';
import MobileFilterSelectContainer from '@client/containers/mobile-filter-select.container';
import theme from '@client/css-modules/MobileFilters.css';
import { createModalPortal } from '@client/hocs/create-modal-portal';
import Arrow from '@client/inline-svgs/arrow';
import CloseIcon from '@client/inline-svgs/close';
import HeartIcon from '@client/inline-svgs/heart';
import { getIsShowComingSoonInListingStatusFilterEnabled } from '@client/store/selectors/enabled-features.selectors';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { generateGenericUid } from '@client/utils/component.utils';
import {
  getAllPropertyTypeValues,
  getFilterSummary,
  getFilterTitle,
} from '@client/utils/filters.utils';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { getPropertyCountDisplay } from '@client/utils/property.utils';
import { Alert } from '@client/utils/reach-alert';
import { useSelector } from 'react-redux';

const {
  headerAndControlsAndTitleCombinedHeight,
  columnTitleHeight,
  headerAndControlsCombinedHeight,
} = theme;

const ADDITIONAL_PADDING = '12px';
const ACTIVE_FILTER_ROW_DATA_ATTR = 'data-active-filter-row-key';
const ADD_FILTER_BUTTON_DATA_ATTR = 'data-add-filter-button';
const OPEN_ANIM_LENGTH = 0.4;
const GRANT_PROGRAM = 'grant-program';

type MobileFiltersProps = {
  isSavingCurrentFilter: boolean;
  hasSavedCurrentFilter: boolean;
  propertyCount: number | null;
  activeFilterValuesByKey: { [key in FilterKey]: any };
  /* whether or not the component is being rendered on a mobile browser and needs to be adjusted for viewport */
  needsMobileHeightAdjustment: boolean;
  initialFilterValue: {
    key: FilterKey | null;
    values: any[];
  };
  isEditingAFilter: boolean;
  isDisplayMultiFamilySearchFiltersEnabled: boolean;
  userBuyingPower: number | null;
  maxResultCount: number;
  handleClose: () => void;
  handleFilterValueChange: (key: FilterKey, values: any[]) => void;
  handleFetchPropertyListAndCount: () => void;
  reportQuickFilterMobileShowResultsClick: () => void;
  reportQuickFilterMobileBackBtnClick: () => void;
  reportQuickFilterMobileAddFilterClick: () => void;
  handleSaveSearch: (location: 'filters') => void;
  isShowingGrantToggle: boolean;
  isGrantToggleEnabled: boolean;
  toggleSRPGrantFilter: () => void;
  saveSearchButtonConfig: any | null;
};

type FilterOptionProps = {
  title: string;
  value: any;
  handleClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  handleRemoveFilter: (e: React.MouseEvent | React.KeyboardEvent) => void;
  filterKey: FilterKey;
  pillColor: string;
  userBuyingPower: number | null;
  selectedFilterKey: FilterKey | null;
  ariaLabelledBy?: string;
  role?: string;
};

const FilterOption: React.FC<FilterOptionProps> = ({
  title,
  value,
  handleClick,
  handleRemoveFilter,
  filterKey,
  pillColor,
  userBuyingPower,
  selectedFilterKey,
  ariaLabelledBy,
  role = 'group',
}) => {
  const filterLabelId = `${title.toLocaleLowerCase()}-filter`;
  const activeFilterPillId = `active-${title.toLocaleLowerCase()}-filter`;
  const isShowComingSoonInListingStatusFilterEnabled = useSelector(
    getIsShowComingSoonInListingStatusFilterEnabled
  );
  return (
    <div
      className={theme.FilterRowWrapper}
      role={role}
      aria-labelledby={ariaLabelledBy}
    >
      <button
        className={theme.FilterRow}
        /* Needed to set focus on this element after returning from filter control for a11y */
        {...{ [ACTIVE_FILTER_ROW_DATA_ATTR]: filterKey }}
        /* indicating accessibility user that mls_state could be expanded */
        {...(filterKey === FILTER_KEYS.MLS_STATE
          ? { 'aria-expanded': selectedFilterKey === filterKey }
          : {})}
        type="button"
        aria-describedby={activeFilterPillId}
        onClick={handleClick}
        onKeyDown={onEnterOrSpaceKey(handleClick)}
        key={filterKey}
      >
        <span
          id={filterLabelId}
          onKeyDown={onEnterOrSpaceKey(handleClick)}
          className={theme.FilterTitle}
        >
          {title}
        </span>
        {
          /* Show a special active filter pill when the max list price filter value matches the provided
           * buying power for a user */
          filterKey === FILTER_KEYS.LIST_PRICE_MIN_MAX &&
          value &&
          value[0] === null &&
          userBuyingPower &&
          userBuyingPower === value[1] ? (
            <BuyingPowerPill value={userBuyingPower} />
          ) : (
            <span
              className={theme.ActiveFilterPill}
              style={{ background: pillColor }}
              id={activeFilterPillId}
            >
              {getFilterSummary(
                filterKey,
                value,
                isShowComingSoonInListingStatusFilterEnabled
              )}
            </span>
          )
        }
      </button>
      <div className={theme.FilterOptionCloseWrapper}>
        <button
          type="button"
          aria-label={`Remove ${title} filter`}
          className={theme.FilterOptionCloseButton}
          onClick={handleRemoveFilter}
          onKeyDown={onEnterOrSpaceKey(handleRemoveFilter)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

type State = {
  isShowingFilterSelect: boolean;
  /* Grant Program is a special toggle that is not part of the FILTER_KEYS enum */
  selectedFilterKey: FilterKey | typeof GRANT_PROGRAM | null;
  ariaMessage: string | null;
  ariaControlMessage: string | null;
};

class MobileFilters extends Component<MobileFiltersProps> {
  state: State = {
    isShowingFilterSelect: false,
    selectedFilterKey: null,
    ariaMessage: null,
    ariaControlMessage: null,
  };

  componentDidMount() {
    const { handleFetchPropertyListAndCount } = this.props;
    handleFetchPropertyListAndCount();
    this.setState({
      ariaMessage: 'Navigated to Current Filters',
    });
  }

  headerFilterTitleId = `mobile-filters-header-${generateGenericUid()}`;

  showFilterControl = (filterKey) => {
    this.setState({
      selectedFilterKey: filterKey,
      isShowingFilterSelect: false,
      ariaMessage: `Navigated to ${
        filterKey === GRANT_PROGRAM
          ? 'Grant Eligible'
          : getFilterTitle(filterKey)
      } filtering options`,
    });
  };

  showFilterSelect = () => {
    this.setState({
      isShowingFilterSelect: true,
      ariaMessage: 'Navigated to Filter Selection',
      ariaControlMessage: null,
    });
  };

  handleApplyFilter = (hasChanged?: boolean) => {
    const { isShowingGrantToggle } = this.props;
    const { selectedFilterKey } = this.state;
    if (hasChanged && selectedFilterKey) {
      // Key is not grant-program and value is in activeFiltersValueByKey. The filter was added.
      if (
        selectedFilterKey !== GRANT_PROGRAM &&
        this.props.activeFilterValuesByKey[selectedFilterKey]
      ) {
        this.setState({
          ariaControlMessage: `${getFilterTitle(
            selectedFilterKey
          )} filter added.`,
        });
        // Key is the grant-program and we are not yet showing the grant toggle. Grant filter was added.
      } else if (selectedFilterKey === GRANT_PROGRAM && !isShowingGrantToggle) {
        this.setState({
          ariaControlMessage: 'Grant Eligible filter added.',
        });
        // Filter was changed and it is not in the activeFilterValuesByKey and we are currently showing the grant toggle. Filter was removed.
      } else {
        this.setState({
          ariaControlMessage: `${
            selectedFilterKey === GRANT_PROGRAM
              ? 'Grant Eligible'
              : getFilterTitle(selectedFilterKey)
          } filter has been removed.`,
        });
      }
    }
  };

  handleCancelFilter = () => {
    if (this.state.selectedFilterKey) {
      this.setState({
        ariaControlMessage: `${
          this.state.selectedFilterKey === GRANT_PROGRAM
            ? 'Grant Eligible'
            : getFilterTitle(this.state.selectedFilterKey)
        } filter canceled.`,
      });
    }
  };

  handleRemoveFilter = (key) => {
    const {
      handleFilterValueChange,
      isDisplayMultiFamilySearchFiltersEnabled,
    } = this.props;
    const allPropertyTypeValues = getAllPropertyTypeValues(
      isDisplayMultiFamilySearchFiltersEnabled
    );
    const initialFilterValues = {
      ...INITIAL_FILTER_VALUES,
      [FILTER_KEYS.PROPERTY_TYPE]: allPropertyTypeValues,
    };
    handleFilterValueChange(key, initialFilterValues[key]);
    this.setState({
      ariaControlMessage: `${
        key === GRANT_PROGRAM ? 'Grant Eligible' : getFilterTitle(key)
      } filter has been removed.`,
    });
  };

  handleRemoveGrantFilter = () => {
    this.setState({
      ariaControlMessage: 'Grant Eligible filter has been removed.',
    });
  };

  returnToInitialView = () => {
    const previouslySelectedFilterKey = `${this.state.selectedFilterKey}`;
    this.setState(
      {
        selectedFilterKey: null,
        isShowingFilterSelect: false,
        ariaMessage: 'Navigated to Current Filters',
      },
      () => {
        /* Sought filter might not be currently active.  If it's not, let's focus the Add Filter button */
        const buttonToFocus =
          document.querySelector(
            `[${ACTIVE_FILTER_ROW_DATA_ATTR}='${previouslySelectedFilterKey}']`
          ) || document.querySelector(`[${ADD_FILTER_BUTTON_DATA_ATTR}]`);
        if (buttonToFocus) {
          (buttonToFocus as HTMLElement).focus();
        }
      }
    );
  };

  handleCancelOrClose = () => {
    const {
      activeFilterValuesByKey,
      initialFilterValue,
      isEditingAFilter,
      handleFilterValueChange,
      handleClose,
    } = this.props;

    if (isEditingAFilter) {
      const { key, values } = initialFilterValue;

      if (key && !isEqual(activeFilterValuesByKey[key], values)) {
        handleFilterValueChange(key, values);
      }
    }
    handleClose();
  };

  handleOnShowResultsClick = () => {
    this.props.reportQuickFilterMobileShowResultsClick();
    this.props.handleClose();
  };

  handleSaveSearchClick = () => {
    const { handleSaveSearch } = this.props;
    handleSaveSearch('filters');
  };

  render() {
    const {
      activeFilterValuesByKey,
      propertyCount,
      needsMobileHeightAdjustment,
      hasSavedCurrentFilter,
      isSavingCurrentFilter,
      userBuyingPower,
      reportQuickFilterMobileBackBtnClick,
      reportQuickFilterMobileAddFilterClick,
      maxResultCount,
      isShowingGrantToggle,
      toggleSRPGrantFilter,
      saveSearchButtonConfig,
    } = this.props;
    const {
      isShowingFilterSelect,
      selectedFilterKey,
      ariaMessage,
      ariaControlMessage,
    } = this.state;

    const { saveSearchButtonLabel, saveSearchButtonIcon } =
      saveSearchButtonConfig;
    const saveSearchText = saveSearchButtonLabel
      ? `Save ${saveSearchButtonLabel}`
      : 'Save search';
    const isShowingFilterControl = !!selectedFilterKey;
    const windowHeight = window.innerHeight;

    return (
      <CobrandedStyles>
        {({
          activeFilterPillColor,
          mobileFiltersShowResultsButtonBackground,
          primaryButtonFillColor,
        }) => (
          // Using `FocusOn` here instead of `FocusLock` to address issues with background
          // elements gaining focus while using VoiceOver on iOS
          <FocusOn returnFocus={true} scrollLock={false}>
            <Alert type="assertive">{ariaMessage}</Alert>
            <Alert type="assertive">{ariaControlMessage}</Alert>
            <div className={theme.MobileFiltersModalContainer}>
              <div
                className={theme.BgScreen}
                onClick={this.handleCancelOrClose}
              />
              <div
                className={theme.MobileFiltersModal}
                style={{
                  ...(needsMobileHeightAdjustment
                    ? { paddingBottom: `calc(100vh - ${windowHeight}px)` }
                    : { paddingBottom: 0 }),
                }}
              >
                {userBuyingPower && !isShowingFilterControl && (
                  <div className={theme.BuyingPower}>
                    <div className={theme.BuyingPowerValue}>
                      Your buying power&nbsp;&nbsp;
                      {dollarsFormatter(userBuyingPower)}
                    </div>
                    <div className={theme.BuyingPowerSubtext}>
                      (Maximum pre-approved $ amount)
                    </div>
                  </div>
                )}
                <div className={theme.Body}>
                  {!isShowingFilterSelect && !isShowingFilterControl && (
                    <motion.div
                      className={theme.CurrentFilters}
                      key="CurrentFilters"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{
                        opacity: 1,
                        height: 'auto',
                        transition: {
                          delay: 0.08,
                          opacity: {
                            duration: 0.3,
                          },
                          height: {
                            duration: 0.2,
                          },
                        },
                      }}
                      style={{
                        ...(needsMobileHeightAdjustment
                          ? {
                              maxHeight: `calc(${windowHeight}px - ${headerAndControlsAndTitleCombinedHeight} - ${ADDITIONAL_PADDING})`,
                            }
                          : {
                              maxHeight: `calc(100vh - ${headerAndControlsAndTitleCombinedHeight} - ${ADDITIONAL_PADDING})`,
                            }),
                      }}
                    >
                      <div className={theme.Heading}>
                        <h2
                          className={theme.Title}
                          id={this.headerFilterTitleId}
                        >
                          Current Filters
                        </h2>
                        <div className={theme.Results}>
                          {getPropertyCountDisplay(
                            propertyCount,
                            maxResultCount
                          )}
                        </div>
                      </div>
                      <div
                        className={theme.FilterList}
                        style={{
                          ...(needsMobileHeightAdjustment
                            ? {
                                maxHeight: `calc(${windowHeight}px - ${headerAndControlsAndTitleCombinedHeight} - ${columnTitleHeight} - ${ADDITIONAL_PADDING})`,
                              }
                            : {
                                maxHeight: `calc(100vh - ${headerAndControlsAndTitleCombinedHeight} - ${columnTitleHeight} - ${ADDITIONAL_PADDING})`,
                              }),
                        }}
                      >
                        {isShowingGrantToggle && (
                          <GrantProgramMobileFilterListing
                            theme={theme}
                            onClick={() =>
                              this.showFilterControl(GRANT_PROGRAM)
                            }
                            onClose={toggleSRPGrantFilter}
                            onRemove={this.handleRemoveGrantFilter}
                            ariaLabelledBy={this.headerFilterTitleId}
                          />
                        )}
                        {(
                          Object.keys(activeFilterValuesByKey) as FilterKey[]
                        ).map((key, i) => (
                          <FilterOption
                            key={key}
                            ariaLabelledBy={this.headerFilterTitleId}
                            filterKey={key}
                            title={getFilterTitle(key)}
                            value={activeFilterValuesByKey[key]}
                            pillColor={activeFilterPillColor}
                            handleClick={() => this.showFilterControl(key)}
                            userBuyingPower={userBuyingPower}
                            handleRemoveFilter={(e) => {
                              e.stopPropagation();
                              this.handleRemoveFilter(key);
                            }}
                            selectedFilterKey={selectedFilterKey}
                          />
                        ))}
                      </div>
                    </motion.div>
                  )}
                  <motion.div
                    animate={{
                      height:
                        isShowingFilterSelect || isShowingFilterControl
                          ? 'auto'
                          : 57,
                      transition: { duration: OPEN_ANIM_LENGTH },
                    }}
                    className={classNames(theme.FilterSelector, {
                      [theme.FilterSelectorExpanded]:
                        isShowingFilterSelect || isShowingFilterControl,
                    })}
                  >
                    {isShowingFilterSelect && (
                      <MobileFilterSelectContainer
                        theme={theme}
                        handleClose={this.returnToInitialView}
                        handleSelect={this.showFilterControl}
                        needsMobileHeightAdjustment={
                          needsMobileHeightAdjustment
                        }
                      />
                    )}
                    {isShowingFilterControl &&
                      selectedFilterKey &&
                      (selectedFilterKey === GRANT_PROGRAM ? (
                        <GrantProgramMobileFilterControl
                          theme={theme}
                          needsMobileHeightAdjustment={
                            needsMobileHeightAdjustment
                          }
                          headerAndControlsCombinedHeight={
                            headerAndControlsCombinedHeight
                          }
                          handleClose={this.returnToInitialView}
                          handleApply={this.handleApplyFilter}
                          handleCancel={this.handleCancelFilter}
                        />
                      ) : (
                        <MobileFilterControlContainer
                          theme={theme}
                          filterKey={selectedFilterKey}
                          handleClose={this.returnToInitialView}
                          handleApply={this.handleApplyFilter}
                          handleCancel={this.handleCancelFilter}
                          needsMobileHeightAdjustment={
                            needsMobileHeightAdjustment
                          }
                        />
                      ))}
                    {!isShowingFilterSelect && !isShowingFilterControl && (
                      <button
                        className={theme.AddFilterButton}
                        onClick={() => {
                          reportQuickFilterMobileAddFilterClick();
                          this.showFilterSelect();
                        }}
                        type="button"
                        aria-expanded={isShowingFilterSelect}
                        {...{ [ADD_FILTER_BUTTON_DATA_ATTR]: true }}
                        onKeyDown={onEnterOrSpaceKey(() => {
                          reportQuickFilterMobileAddFilterClick();
                          this.showFilterSelect();
                        })}
                      >
                        <CloseIcon className={theme.PlusIcon} />
                        Add Filter
                      </button>
                    )}
                  </motion.div>

                  <div className={theme.ActionButtons}>
                    {isShowingFilterSelect || isShowingFilterControl ? (
                      <button
                        type="button"
                        className={theme.BackBtn}
                        onClick={() => {
                          reportQuickFilterMobileBackBtnClick();
                          this.returnToInitialView();
                        }}
                        onKeyDown={onEnterOrSpaceKey(this.returnToInitialView)}
                      >
                        <span className={theme.circle}>
                          <Arrow className={theme.ArrowIcon} />
                        </span>
                        <span className={theme.ActionButtonText}>Back</span>
                      </button>
                    ) : (
                      <React.Fragment>
                        <div
                          className={classNames(
                            theme.SaveFiltersButtonContainer,
                            {
                              [theme.LoadingState]: isSavingCurrentFilter,
                            }
                          )}
                        >
                          <LoadingSection
                            isLoading={isSavingCurrentFilter}
                            className={theme.LoadingSection}
                          >
                            {hasSavedCurrentFilter ? (
                              <div className={theme.SavedSearchButton}>
                                <GreenCheckIconCobranded
                                  className={theme.GreenCheckIcon}
                                />
                                <div className={theme.SavedText}>Saved</div>
                              </div>
                            ) : (
                              <FlatButton
                                className={theme.SaveSearchButton}
                                buttonStyle={{
                                  background: primaryButtonFillColor,
                                }}
                                theme={theme}
                                disabled={hasSavedCurrentFilter}
                                onClick={this.handleSaveSearchClick}
                                aria-label={
                                  saveSearchButtonLabel
                                    ? `Save ${saveSearchButtonLabel}`
                                    : 'Save search'
                                }
                                data-hc-name={
                                  'save-search-button-mobile-filters'
                                }
                                icon={
                                  saveSearchButtonIcon ? (
                                    <img
                                      src={saveSearchButtonIcon}
                                      alt=""
                                      className={theme.HeartIcon}
                                    />
                                  ) : (
                                    <HeartIcon className={theme.HeartIcon} />
                                  )
                                }
                                label={
                                  <span className={theme.SaveSearchText}>
                                    {saveSearchText}
                                  </span>
                                }
                              />
                            )}
                          </LoadingSection>
                        </div>
                        <button
                          className={theme.ResultsBtn}
                          type="button"
                          onKeyDown={onEnterOrSpaceKey(
                            this.handleOnShowResultsClick
                          )}
                          onClick={this.handleOnShowResultsClick}
                        >
                          <span
                            className={theme.circle}
                            style={{
                              background:
                                mobileFiltersShowResultsButtonBackground,
                            }}
                          >
                            <Arrow className={theme.ArrowIcon} />
                          </span>
                          <span className={theme.ActionButtonText}>
                            Show Results
                          </span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FocusOn>
        )}
      </CobrandedStyles>
    );
  }
}

export default createModalPortal(MobileFilters, 'mobile-filters-modal');
