import GrantProgramMobileToggle from '@client/components/GrantProgram/GrantProgramMobileToggle';
import AccessibleElementUniqueId from '@client/hocs/accessible-element-unique-id';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { reportEvent } from '@client/store/actions/analytics.actions';
import { getSearchListMaxResultCount } from '@client/store/selectors/enabled-features.selectors';
import { getSearchPropertyTotalCount } from '@client/store/selectors/search.selectors';
import {
  selectIsSRPGrantFilterOn,
  toggleSRPGrantFilter,
} from '@client/store/slices/grant-program.slice';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import { getPropertyCountDisplay } from '@client/utils/property.utils';
import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Grabbing MobileFilterControl CSS as we are matching those styles */
import defaultTheme from '@client/css-modules/MobileFilterControl.css';
import { grantProgramFilterToggleDescription } from './GrantProgramFilterToggle';

type Props = {
  theme: Theme;
  needsMobileHeightAdjustment: boolean;
  headerAndControlsCombinedHeight: string;
  handleClose: () => void;
  handleCancel?: () => void;
  handleApply?: (hasChanged: boolean) => void;
};

const GrantProgramMobileFilterControl = ({
  theme,
  needsMobileHeightAdjustment,
  headerAndControlsCombinedHeight,
  handleClose,
  handleApply,
  handleCancel,
}: Props) => {
  const dispatch = useDispatch();
  const isToggleOnAtLoad = useSelector(selectIsSRPGrantFilterOn);

  const [isToggleOn, setIsToggleOn] = useState<boolean>(isToggleOnAtLoad);
  const windowHeight = window ? window.innerHeight : 0;
  const propertyCount = useSelector(getSearchPropertyTotalCount);
  const maxResultCount = useSelector(getSearchListMaxResultCount);
  const { primaryButtonFillColor } = useCobrandStyles();

  const handleApplyClick = () => {
    if (isToggleOn !== isToggleOnAtLoad) {
      dispatch(toggleSRPGrantFilter());
    }

    handleApply?.(isToggleOn !== isToggleOnAtLoad);
    handleClose();
  };

  const handleCancelClick = () => {
    handleCancel?.();
    handleClose();
  };

  return (
    <AccessibleElementUniqueId>
      {({ uid }) => (
        <div
          className={theme.MobileFilterControl}
          style={{
            ...(needsMobileHeightAdjustment
              ? {
                  maxHeight: `calc(${windowHeight}px - ${headerAndControlsCombinedHeight})`,
                }
              : {}),
          }}
        >
          <div className={theme.Header}>
            <h2 id={uid} className={theme.SelectedFilter}>
              Grant Eligible
            </h2>
            <div className={theme.Results}>
              {getPropertyCountDisplay(propertyCount, maxResultCount)}
            </div>
          </div>
          <div className={theme.FilterControl}>
            <section className={theme.Filter}>
              <div className={theme.Description}>
                {grantProgramFilterToggleDescription}
              </div>
              <div className={theme.Controls}>
                <div style={{ width: '100%' }}>
                  <GrantProgramMobileToggle
                    ariaLabelledBy={uid}
                    isToggleOn={isToggleOn}
                    onChange={(value) => {
                      setIsToggleOn(value);
                      dispatch(
                        reportEvent(
                          'click_grant_eligible_search',
                          'searches_performed'
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </section>
            <div className={theme.ControlActionButtons}>
              <button
                type="button"
                style={{
                  color: primaryButtonFillColor,
                  borderColor: primaryButtonFillColor,
                }}
                onClick={handleCancelClick}
                onKeyDown={onEnterOrSpaceKey(handleCancelClick)}
                className={classNames(theme.ActionButton, theme.cancel)}
                data-hc-name="cancel-button"
              >
                Cancel
              </button>
              <button
                type="button"
                style={{ background: primaryButtonFillColor }}
                onClick={handleApplyClick}
                onKeyDown={onEnterOrSpaceKey(handleApplyClick)}
                className={classNames(theme.ActionButton, theme.apply)}
                data-hc-name="apply-button"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </AccessibleElementUniqueId>
  );
};

export default themr(
  'GrantProgramMobileFilterControl',
  defaultTheme
)(GrantProgramMobileFilterControl);
