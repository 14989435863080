import {
  differenceWith,
  get,
  intersection,
  isEmpty,
  isEqual,
  pull,
  values,
} from 'lodash';

import MaxOnlyPicker from '@client/components/generic/MaxOnlyPicker';
import MinMaxPicker from '@client/components/generic/MinMaxPicker';
import MinOnlyButtons from '@client/components/generic/MinOnlyButtons';
import MinOnlyPicker from '@client/components/generic/MinOnlyPicker';
import MobileCheckboxPicker from '@client/components/generic/MobileCheckboxPicker';
import MobileFiltersSlider from '@client/components/generic/MobileFiltersSlider';
import {
  FILTER_DEFINITIONS,
  FilterDefinition,
} from '@client/services/filter-definitions';
import { SEARCH_MAP_URL_PARAM_DELIMINATOR } from '@client/store/constants';
import {
  CONTROL_CUSTOMIZATIONS,
  CONTROL_TYPES,
  FE_ONLY_MLS_STATES,
  FILTER_CONSUMER_API_KEYS,
  FILTER_GQL_TYPES,
  FILTER_KEYS,
  FILTER_KEY_ABBREVIATIONS,
  FILTER_VALUE_ABBREVIATIONS,
  FORMATTING_TYPES,
  FilterKey,
  FilterPropertyTypes,
  FiltersState,
  INITIAL_FILTER_VALUES,
  LABEL_DEFAULTS,
  MIN_MAX_FILTER_DESIGNATOR,
  PROPERTY_TYPE_LABELS,
  QL_TYPES,
} from '@client/store/filter-constants';
import {
  SavedSearchAPIFieldsForFiltersState,
  SavedSearchAPIResponse,
  SavedSearchItem,
} from '@client/store/types/saved-searches';
import { dollarsFormatter } from '@client/utils/formatter.utils';
import { abbrDollarsFormatter, pluralize } from '@client/utils/string.utils';
import { LISTING_STATUS_LABELS } from './property.utils';

/**
 * Given a filter key, returns the filter definition
 * @param  {string} key the filter key from constants
 * @return {object} definition JSON object
 */
export const getFilterDef = (key: FilterKey): FilterDefinition =>
  FILTER_DEFINITIONS[key];

/**
 * Given a comma separated list string, return an array of values
 * @param  {string} key the filter key from constants
 * @param  {string} str comma separated list string
 * @return {array}
 */
const getArrayFromListString = (
  key: FilterKey,
  str: string
): string[] | number[] =>
  str
    .split(',')
    .map((v) => getFormattedUIValueForType(v, FILTER_GQL_TYPES[key]));

/**
 * Combine above methods to get an array of options from filter definition
 * @param  {string} key the filter key from constants
 * @return {array}  options
 */
export const getFilterOptions = (key: FilterKey): (string | number)[] =>
  getFilterDef(key).value_list;

type ArrayTypeSummaryFn = (array: any[], featureFlag?: boolean) => string;
type TwoStringTypeSummaryFn = (string1: string, string2: string) => string;

const getDoesFilterDefSummaryAcceptArray = (
  filterDef: FilterDefinition,
  summaryFn: FilterDefinition['summary_function']
): summaryFn is ArrayTypeSummaryFn => {
  return filterDef.summary_input_type.indexOf('ARRAY') > -1;
};

const getDoesFilterDefSummaryAcceptMinMax = (
  filterDef: FilterDefinition,
  summaryFn: FilterDefinition['summary_function']
): summaryFn is TwoStringTypeSummaryFn => {
  return filterDef.summary_input_type === 'TWO_STRING';
};

/**
 * A function that generates the filters summary text (for the blue pills)
 * @param {string}  key the filter key from constants
 * @param {array}   valueArr the filters values
 * @param {boolean} isShowComingSoonInListingStatusFilterEnabled
 * @return {string} the string describing the filter summary
 */
export const getFilterSummary = (
  key: FilterKey,
  valueArr: any,
  isShowComingSoonInListingStatusFilterEnabled?: boolean
): string => {
  const filterDef = getFilterDef(key);

  if (
    getDoesFilterDefSummaryAcceptArray(filterDef, filterDef.summary_function)
  ) {
    return filterDef.summary_function(
      valueArr,
      isShowComingSoonInListingStatusFilterEnabled
    );
  } else if (
    getDoesFilterDefSummaryAcceptMinMax(filterDef, filterDef.summary_function)
  ) {
    return filterDef.summary_function(valueArr[0], valueArr[1]);
  } else {
    return '';
  }
};

/**
 * Return the title of the filter
 * @param {string}  key the filter key from constants
 * @return {string} the title of the filter
 */
export const getFilterTitle = (key: FilterKey): string =>
  getFilterDef(key).displayable_title;

/**
 * On desktop, we need to remove "Schools - " from the school filters, as they are in a sub category
 * @param {string}  key the filter key from constants
 * @return {string} the title of the filter
 */
export const getSchoolsDesktopFilterTitle = (key: FilterKey): string =>
  getFilterTitle(key).split('Schools - ')[1];

/**
 * Get the filter description from filter definition service
 * @param {string} key the filter key from constants
 * @return {string} the description of the filter
 */
export const getFilterDescription = (key: FilterKey): string =>
  getFilterDef(key).description_short;

export type FilterControlCustomizations = {
  [CONTROL_CUSTOMIZATIONS.MIN_ONLY_BUTTONS_SHOULD_ADD_PLUS]: boolean;
  [CONTROL_CUSTOMIZATIONS.CUSTOM_NO_MAX_TEXT]: string;
  [CONTROL_CUSTOMIZATIONS.CUSTOM_NO_MIN_TEXT]: string;
  [CONTROL_CUSTOMIZATIONS.SHOULD_REVERSE_SLIDER_COLORS]: boolean;
  [CONTROL_CUSTOMIZATIONS.SHOULD_EXPAND_UPWARDS]: boolean;
};

/**
 * Get the filters control customizations from filter definitions
 * @param {string} key the filter key from constants
 * @return {object | null} the customization JSON
 */
export const getFilterControlCustomization = (
  key: FilterKey
): Partial<FilterControlCustomizations> | null =>
  getFilterDef(key)?.control_customization || null;

/* "F, D, C, B, A" -> "FDCBA" */
const MARKET_GRADE_FILTER_CHAR_MAPPING = getFilterOptions(
  FILTER_KEYS.MARKET_GRADE_MIN_MAX
).join('');

// Our controls have different requirements than the filter definition functions, so if
// we need to override their function, we can by adding a new formatter to LABEL_FORMATTER_OVERRIDES:
export const LABEL_FORMATTER_OVERRIDES = {
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: (v) =>
    MARKET_GRADE_FILTER_CHAR_MAPPING[v],
  [FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX]: (v) => {
    const val = v * 100;
    return `${val.toFixed()}%`;
  },
  [FILTER_KEYS.HPI_MIN_MAX]: (v) => {
    // for HPI_MIN_MAX, we display >20% or <-10% for the min and max values:
    if (v > 0.2) {
      return '>20%';
    } else if (v < -0.1) {
      return '<-10%';
    } else {
      return `${(v * 100).toFixed()}%`;
    }
  },
};

const genericLabelFormatter = (v) => v;
type LabelFormatter = (anyValue: any) => string;

/**
 * Generates the labels for the options you can select in a filter
 */
export const getLabelFormatterForFilter = (key: FilterKey): LabelFormatter => {
  if (get(LABEL_FORMATTER_OVERRIDES, key)) {
    return LABEL_FORMATTER_OVERRIDES[key];
  }

  const filterDef = getFilterDef(key);
  const formattingType = filterDef.formatting_type;
  const controlCustomization = filterDef.control_customization;

  // Setting a default formatter function that simply returns the value
  let formatter: LabelFormatter;

  if (formattingType === FORMATTING_TYPES.DOLLAR) {
    formatter = dollarsFormatter;
  } else if (
    formattingType === FORMATTING_TYPES.CUSTOM &&
    filterDef.display_function
  ) {
    formatter = filterDef.display_function;
  } else {
    formatter = genericLabelFormatter;
  }

  // If the filter has the add_plus control customization, we need to tack it on to the formatter's return value:
  if (
    controlCustomization &&
    get(
      controlCustomization,
      CONTROL_CUSTOMIZATIONS.MIN_ONLY_BUTTONS_SHOULD_ADD_PLUS
    )
  ) {
    return (v): string => `${formatter(v)}+`;
  }

  return formatter;
};

/**
 * Given a filter value, format the value for the specific type required by GQL
 * @param  {any} value
 * @param  {string} type
 * @return {any} formatted value
 */
export const getFormattedGQLValueForType = (value: any, type: string): any => {
  if (
    value === null ||
    (get(value, 'constructor') === Array && isEmpty(value))
  ) {
    return null;
  }
  switch (type) {
    case '[FilterPropertyTypes]':
    case '[MlsStateGroup]':
      return value.map((valueItem) => `${valueItem}`);
    case 'Int':
      return parseInt(value, 10);
    case 'Float':
      return parseFloat(value);
    case 'String':
      return `"${value}"`;
    case 'TriBool':
      return value === null ? null : value ? ['TRUE'] : ['FALSE'];
    case 'Date':
    case 'GeoPrecision':
    case 'MarketGrade':
      return value;
    case 'Boolean':
      return JSON.parse(value);
  }
};

/**
 * Given a a JSON string value and a GQL type, format the value for the use by our filter UI controls
 * @param  {any} value
 * @param  {string} type
 * @return {any} formatted value
 */
export const getFormattedUIValueForType = (value: any, type: string): any => {
  if (isEmpty(value)) {
    return null;
  }
  switch (type) {
    case 'Date':
    case 'Int':
      return parseInt(value, 10);
    case 'Float':
      return parseFloat(value);
    case '[FilterPropertyTypes]':
    case '[MlsStateGroup]':
    case 'String':
    case 'GeoPrecision':
    case 'MarketGrade':
      /* Ensure no leading or trailing whitespaces */
      return value.trim().toUpperCase();
    case 'Boolean':
      return JSON.parse(value);
  }
};

/**
 * Given the filter object from state, return an object formatted for a URL query string
 * @param  {object} filters - filters state object
 * @return {object} formatted filters for query string
 */
export const filterStateToUrlParams = (
  filters,
  isDisplayMultiFamilySearchFiltersEnabled
) => {
  let formattedFilters = {};
  const allPropertyTypeValues = getAllPropertyTypeValues(
    isDisplayMultiFamilySearchFiltersEnabled
  );
  const initialFilterValues = {
    ...INITIAL_FILTER_VALUES,
    [FILTER_KEYS.PROPERTY_TYPE]: allPropertyTypeValues,
  };

  values(FILTER_KEYS).forEach((key) => {
    const value = filters[key];
    let formattedValueString;

    /* Don't set filter in the URL if it's unchanged from the initial value */
    if (isEqual(value, initialFilterValues[key])) {
      return;
    } else if (FILTER_GQL_TYPES[key] === 'Boolean') {
      formattedValueString = JSON.stringify(value);
    } else if (FILTER_GQL_TYPES[key] === 'TriBool') {
      formattedValueString = value;
    } else if (Array.isArray(value)) {
      formattedValueString = value
        .map((valueItem) => {
          /* We shouldn't need to check for `undefined` here, and it's probably indicative of
           * a different bug, but including for now until we convert filters to TS
           * https://sentry.io/organizations/housecanarycom/issues/1648791903/?project=1229140 */
          const formattedValueItem =
            valueItem === null || valueItem === undefined
              ? ''
              : valueItem.toString().toLowerCase();
          return (
            FILTER_VALUE_ABBREVIATIONS[formattedValueItem] || formattedValueItem
          );
        })
        .join(',');
    } else {
      throw new Error(
        'Filter state value must either be an array or a boolean'
      );
    }
    if (
      formattedValueString !== SEARCH_MAP_URL_PARAM_DELIMINATOR &&
      formattedValueString !== null
    ) {
      formattedFilters[FILTER_KEY_ABBREVIATIONS[key]] = formattedValueString;
    }
  });

  return formattedFilters;
};

const getTriBoolValue = (value: string): boolean | null =>
  value === 'true' ? true : value === 'false' ? false : null;

/**
 * Given URL query string object, return a filter state object
 * @param  {object} query string object (parsed via `query-string` lib)
 * @return {object} filters - filters state object
 */
export const urlParamsToInitialFilterState = (queryStringObj: {}) => {
  let filterStateFromUrl = {};

  /* Iterating the filter keys to be sure we don't allow any foreign keys to be set on state */
  values(FILTER_KEYS).forEach((key) => {
    let abbreviatedStringValues = queryStringObj[
      FILTER_KEY_ABBREVIATIONS[key]
    ] as string | string[];

    /* If the URL query params have a repeated filter key for a filter (can only happen if the URL is formed manually)
     * just pick the first definition for the key.  This is preferable to hard failing with a server error. The query
     * params will be automatically corrected when navigating, changing filters, or moving the map. */
    if (Array.isArray(abbreviatedStringValues)) {
      abbreviatedStringValues = abbreviatedStringValues[0];
    }

    /* Transform malformed beds and baths query params into proper format */
    if (
      (key === FILTER_KEYS.BEDS_MIN_MAX || key === FILTER_KEYS.BATHS_MIN_MAX) &&
      abbreviatedStringValues &&
      !abbreviatedStringValues.includes(SEARCH_MAP_URL_PARAM_DELIMINATOR)
    ) {
      abbreviatedStringValues += SEARCH_MAP_URL_PARAM_DELIMINATOR;
    }

    if (abbreviatedStringValues) {
      const fullStringValues = abbreviatedStringValues
        .split(SEARCH_MAP_URL_PARAM_DELIMINATOR)
        .map((valueItem) => {
          // Market Grade can have a value of 'C' which is transformed for other filters:
          if (key === FILTER_KEYS.MARKET_GRADE_MIN_MAX) {
            return valueItem;
          } else {
            const nonAbbreviatedValue = Object.keys(
              FILTER_VALUE_ABBREVIATIONS
            ).find((i) => FILTER_VALUE_ABBREVIATIONS[i] === valueItem);
            return nonAbbreviatedValue || valueItem;
          }
        })
        .join(',');
      const stateValue =
        FILTER_GQL_TYPES[key] === 'Boolean'
          ? getFormattedUIValueForType(fullStringValues, FILTER_GQL_TYPES[key])
          : FILTER_GQL_TYPES[key] === 'TriBool'
            ? getTriBoolValue(fullStringValues)
            : getArrayFromListString(key, fullStringValues);

      filterStateFromUrl[key] = stateValue;
    }
  });

  return filterStateFromUrl;
};

/**
 * Given saved-search/last-search filters returned by the consumer API, return filters formatted
 * for setting on app state
 * @param  {object} filters returned as part of a saved-search object
 * @return {object} filters ready to be set on state
 */
export const consumerAPIFiltersToStateFilters = (
  backendFilters: SavedSearchAPIFieldsForFiltersState,
  isDisplayMultiFamilySearchFiltersEnabled: boolean
): FiltersState => {
  let filterState = {};
  const allPropertyTypeValues = getAllPropertyTypeValues(
    isDisplayMultiFamilySearchFiltersEnabled
  );
  const initialFilterValues = {
    ...INITIAL_FILTER_VALUES,
    [FILTER_KEYS.PROPERTY_TYPE]: allPropertyTypeValues,
  };
  /* Iterating the state filter keys to be sure we don't allow any foreign keys to be set on state */
  Object.keys(initialFilterValues).forEach((stateKey) => {
    const backendBaseKey = FILTER_CONSUMER_API_KEYS[stateKey]; // beds
    const isMinMaxFilter = stateKey.indexOf(MIN_MAX_FILTER_DESIGNATOR) > -1; // true
    if (isMinMaxFilter) {
      filterState[stateKey] = [
        backendFilters[`min_${backendBaseKey}`] || null,
        backendFilters[`max_${backendBaseKey}`] || null,
      ];
    } else if (backendFilters[backendBaseKey]) {
      filterState[stateKey] = backendFilters[backendBaseKey];
    }
  });
  /* Special case: "geo precision" from lowercase to uppercase */
  if (filterState[FILTER_KEYS.GEO_PRECISION_MIN_MAX][0]) {
    filterState[FILTER_KEYS.GEO_PRECISION_MIN_MAX][0] =
      filterState[FILTER_KEYS.GEO_PRECISION_MIN_MAX][0].toUpperCase();
  }
  if (filterState[FILTER_KEYS.GEO_PRECISION_MIN_MAX][1]) {
    filterState[FILTER_KEYS.GEO_PRECISION_MIN_MAX][1] =
      filterState[FILTER_KEYS.GEO_PRECISION_MIN_MAX][1].toUpperCase();
  }

  return filterState as FiltersState;
};

const ELEMENTARY_SCHOOL_DEFAULTS = {
  min: getFilterOptions(FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX)[0],
  max: getFilterOptions(
    FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX
  ).slice(-1)[0],
};

const MIDDLE_SCHOOL_DEFAULTS = {
  min: getFilterOptions(FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX)[0],
  max: getFilterOptions(
    FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX
  ).slice(-1)[0],
};

const HIGH_SCHOOL_DEFAULTS = {
  min: getFilterOptions(FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX)[0],
  max: getFilterOptions(FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX).slice(
    -1
  )[0],
};

// HPI values don't match what we actually want, so we're grabbing the second and second to last indexes.
const HPI_DEFAULTS = {
  min: getFilterOptions(FILTER_KEYS.HPI_MIN_MAX)[1],
  max: getFilterOptions(FILTER_KEYS.HPI_MIN_MAX).slice(-2, -1)[0],
};

const RISK_OF_DECLINE_DEFAULTS = {
  min: getFilterOptions(FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX)[0],
  max: getFilterOptions(FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX).slice(-1)[0],
};

const CRIME_COUNTY_PERCENTILE_MIN_MAX_DEFAULTS = {
  min: getFilterOptions(FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX)[0],
  max: getFilterOptions(FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX).slice(
    -1
  )[0],
};

export const MLS_STATE_OPTIONS = getFilterOptions(FILTER_KEYS.MLS_STATE);

/* Given a saved search filters object, return a identifier string summarizing the filters */
export const getFiltersSummaryIdentifier = (filters: object): string => {
  let output = '';
  const minBeds =
    filters[`min_${FILTER_CONSUMER_API_KEYS[FILTER_KEYS.BEDS_MIN_MAX]}`];
  const minBaths =
    filters[`min_${FILTER_CONSUMER_API_KEYS[FILTER_KEYS.BATHS_MIN_MAX]}`];
  const listPrice = [
    abbrDollarsFormatter(
      filters[
        `min_${FILTER_CONSUMER_API_KEYS[FILTER_KEYS.LIST_PRICE_MIN_MAX]}`
      ],
      null
    ),
    abbrDollarsFormatter(
      filters[
        `max_${FILTER_CONSUMER_API_KEYS[FILTER_KEYS.LIST_PRICE_MIN_MAX]}`
      ],
      null
    ),
  ];

  /* Beds & Baths */
  if (minBeds || minBaths) {
    output += 'Min ';

    if (minBeds) {
      output += `${minBeds} ${pluralize('Bed', minBeds)}`;
    }
    if (minBaths) {
      if (minBeds) {
        output += ', ';
      }
      output += `${minBaths} ${pluralize('Baths', minBaths)}`;
    }
  }
  /* List price */
  if (listPrice[0] || listPrice[1]) {
    if (minBeds || minBaths) {
      output += ', ';
    }
    if (listPrice[0] && listPrice[1]) {
      output += `Between ${listPrice[0]} and ${listPrice[1]}`;
    } else if (listPrice[0]) {
      output += `Min ${listPrice[0]}`;
    } else {
      output += `Up to ${listPrice[1]}`;
    }
  }
  return output;
};

/**
 * Given a values array, find the index at which to insert the user's buying power
 * value.  The method assumes that the buying power is NOT already in the list of values
 * @param  {array} valuesArr
 * @param  {number} buyingPower
 * @return {number} index
 */
export const getIndexForInsertingBuyingPower = (
  valuesArr: (number | null)[],
  buyingPower: number
): number => {
  for (let i = 0; i < valuesArr.length; i++) {
    if (valuesArr[i] || 0 > buyingPower) {
      return i;
    } else if (
      valuesArr[i] ||
      (0 < buyingPower && valuesArr[i + 1] && [i + 1]) ||
      0 > buyingPower
    ) {
      return i + 1;
    }
  }
  return valuesArr.length;
};

export const getDropdownRangeSourceValuesForListPrice = (
  options: (number | null)[],
  values: (number | null)[],
  labelFormatter: (val: number | null, str: string) => string,
  userBuyingPower: number | null
): { value: number | null; label: string }[][] => {
  /**
   * We need to insert the buying power value into the list of values if it's present in
   * the app state but not already present in the list of value options
   */
  if (userBuyingPower && options.indexOf(userBuyingPower) === -1) {
    const spliceIndex = getIndexForInsertingBuyingPower(
      options,
      userBuyingPower
    );
    options.splice(spliceIndex, 0, userBuyingPower);
  }

  return [
    options
      .filter((optionValue) => {
        /* Only allow options less than the defined max value */
        if (optionValue && values[1]) {
          return optionValue < values[1];
        } else {
          return !optionValue || !values[1];
        }
      })
      .map((optionValue) => ({
        value: optionValue,
        label: labelFormatter(optionValue, 'Min'),
      })),
    options
      .filter((optionValue) => {
        /* Only allow options greater than the defined min value */
        if (optionValue && values[0]) {
          return optionValue > values[0];
        } else {
          return !optionValue || !values[0];
        }
      })
      .map((optionValue) => ({
        value: optionValue,
        label: labelFormatter(optionValue, 'Max'),
      })),
  ];
};

export const getDropdownRangeSourceValues = (
  options: number[],
  values: number[],
  labelFormatter: (val: number, str: string) => string,
  isUnitsTotalMinMax: boolean
): { value: number; label: string }[][] => [
  options
    .filter(
      (optionValue) =>
        /* Only allow options less than the defined max value */
        (isUnitsTotalMinMax
          ? optionValue <= values[1]
          : optionValue < values[1]) ||
        !optionValue ||
        !values[1]
    )
    .map((optionValue) => ({
      value: optionValue,
      label: labelFormatter(optionValue, 'Min'),
    })),
  options
    .filter(
      (optionValue) =>
        /* Only allow options greater than the defined min value */
        (isUnitsTotalMinMax
          ? optionValue >= values[0]
          : optionValue > values[0]) ||
        !optionValue ||
        !values[0]
    )
    .map((optionValue) => ({
      value: optionValue,
      label: labelFormatter(optionValue, 'Max'),
    })),
];

/**
 * Create a saved search store item given an API response item
 * @param  {object} API response item
 * @return {object} store item
 */
export const savedSearchItemModel = (
  item: SavedSearchAPIResponse
): SavedSearchItem => {
  const { search_id, search } = item;
  const { name, send_email, send_mobile, created, ...filter } = search;

  /* Trim extra information saved as part of the name in some environments, keeping only
   * the place identifier before the "-" character */
  const placeIdentifier = name.replace(
    /(.+)\s-(.+)/,
    (_, placePart) => placePart
  );
  const filterSummaryIdentifier = getFiltersSummaryIdentifier(filter);
  const displayName = filterSummaryIdentifier
    ? `${placeIdentifier} - ${filterSummaryIdentifier}`
    : placeIdentifier;

  return {
    searchId: search_id,
    name,
    displayName,
    alerts: {
      email: send_email,
      mobile: send_mobile,
    },
    created: created,
    filter,
  };
};

export const PROPERTY_TYPE_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.PROPERTY_TYPE),
  key: FILTER_KEYS.PROPERTY_TYPE,
  isMultiSelect: true,
  options: [
    ...getFilterOptions(FILTER_KEYS.PROPERTY_TYPE),
    ...[FE_ONLY_MLS_STATES.ALL],
  ]
    .map((value) => ({
      label: getLabelFormatterForFilter(FILTER_KEYS.PROPERTY_TYPE)(value),
      value,
    }))
    .filter((option) => !!option.label),
  getValueForControlFormatter: (
    values: FilterPropertyTypes[],
    allValues: FilterPropertyTypes[]
  ): FilterPropertyTypes[] => {
    /* If all values are selected, select the 'All' option in the UI */
    if (intersection(values, allValues).length === allValues.length) {
      return [];
    } else {
      return values;
    }
  },
  setValueForStateFormatter: (
    values: FilterPropertyTypes[],
    valueAdded,
    allValues: FilterPropertyTypes[]
  ): FilterPropertyTypes[] => {
    if (
      valueAdded === FE_ONLY_MLS_STATES.ALL ||
      values.length === 0 ||
      values.length === allValues.length
    ) {
      /* Set all values on state when 'All' selected in UI */
      return allValues;
    }
    return values.filter((value) => value !== FE_ONLY_MLS_STATES.ALL);
  },
  getIsActive: (value, allOptionValues) =>
    !isEqual([...value].sort(), [...allOptionValues].sort()),
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.PROPERTY_TYPE, v),
  resetFilterSetValue: () => INITIAL_FILTER_VALUES[FILTER_KEYS.PROPERTY_TYPE],
};

export const MULTI_FAMILY_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.UNITS_TOTAL_MIN_MAX),
  key: FILTER_KEYS.UNITS_TOTAL_MIN_MAX,
  isDropdownRange: true,
  options: [
    null,
    ...getFilterOptions(FILTER_KEYS.UNITS_TOTAL_MIN_MAX).map((v) =>
      parseInt(v.toString(), 10)
    ),
  ],
  setValueForStateFormatter: (value) => value,
  labelFormatter: (value, minOrMax) =>
    value === null
      ? `No ${minOrMax}`
      : getLabelFormatterForFilter(FILTER_KEYS.SQFT_MIN_MAX)(value),
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.UNITS_TOTAL_MIN_MAX, v),
};

export const LIST_PRICE_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.LIST_PRICE_MIN_MAX),
  key: FILTER_KEYS.LIST_PRICE_MIN_MAX,
  isDropdownRange: true,
  options: [
    null,
    ...getFilterOptions(FILTER_KEYS.LIST_PRICE_MIN_MAX).map((v) =>
      parseInt(v.toString(), 10)
    ),
  ],
  setValueForStateFormatter: (value) => value,
  labelFormatter: (value, minOrMax) =>
    value === null
      ? `No ${minOrMax}`
      : getLabelFormatterForFilter(FILTER_KEYS.LIST_PRICE_MIN_MAX)(value),
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.LIST_PRICE_MIN_MAX, v),
};

export const BEDS_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.BEDS_MIN_MAX),
  key: FILTER_KEYS.BEDS_MIN_MAX,
  minValue: 0,
  maxValue: getFilterOptions(FILTER_KEYS.BEDS_MIN_MAX).slice(-1)[0],
  isNumberAdjuster: true,
  setValueForStateFormatter: (value) => [value === 0 ? null : value, null],
  labelFormatter: (value) => `${value}+`,
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.BEDS_MIN_MAX, v),
};

export const MLS_STATE_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.MLS_STATE),
  key: FILTER_KEYS.MLS_STATE,
  isMultiSelect: true,
  options: getFilterOptions(FILTER_KEYS.MLS_STATE)
    .concat(FE_ONLY_MLS_STATES.ALL)
    .map((value) => ({
      label: getLabelFormatterForFilter(FILTER_KEYS.MLS_STATE)(value),
      value,
    }))
    .filter((option) => !!option.label),
  getValueForControlFormatter: (values, allMlsStateValues) =>
    /* If all values are selected, select the 'All' option in the UI */
    intersection(values, allMlsStateValues).length === allMlsStateValues.length
      ? []
      : values,
  setValueForStateFormatter: (values, valueAdded, allMlsStateValues) => {
    /* If ALL is selected or no values are selected, select all values */
    if (
      valueAdded === FE_ONLY_MLS_STATES.ALL ||
      values.length === 0 ||
      values.length === allMlsStateValues.length
    ) {
      return allMlsStateValues;
    } else {
      return values.filter((value) => value !== FE_ONLY_MLS_STATES.ALL);
    }
  },
  shouldSendNullToAPI: (values) => {
    return isEmpty(differenceWith(MLS_STATE_OPTIONS, values, isEqual));
  },
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.MLS_STATE, v),
  getIsActive: (value, allOptionValues) =>
    !isEqual([...value].sort(), [...allOptionValues].sort()),
};

export const HAS_POOL_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.POOL),
  key: FILTER_KEYS.POOL,
  isDropdown: true,
  labelFormatter: (value) => value,
  options: [null, ...getFilterOptions(FILTER_KEYS.POOL)],
  // get value from redux state for UI
  getValueForControlFormatter: getFilterDef(FILTER_KEYS.POOL).summary_function,
  // set value to redux state from UI selection
  setValueForStateFormatter: (values) => {
    const filterOptions = getFilterOptions(FILTER_KEYS.POOL);
    return values === filterOptions[0]
      ? true
      : values === filterOptions[1]
        ? false
        : null;
  },
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.POOL, v),
  getIsActive: (value) => value !== null,
};

export const HAS_BASEMENT_FILTER_DEF = {
  title: getFilterTitle(FILTER_KEYS.BASEMENT),
  key: FILTER_KEYS.BASEMENT,
  isDropdown: true,
  labelFormatter: (value) => value,
  options: [null, ...getFilterOptions(FILTER_KEYS.BASEMENT)],
  // get value from redux state for UI
  getValueForControlFormatter: getFilterDef(FILTER_KEYS.BASEMENT)
    .summary_function,
  // set value to redux state from UI selection
  setValueForStateFormatter: (values) => {
    const filterOptions = getFilterOptions(FILTER_KEYS.BASEMENT);
    return values === filterOptions[0]
      ? true
      : values === filterOptions[1]
        ? false
        : null;
  },
  summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.BASEMENT, v),
  getIsActive: (value) => value !== null,
};

/* Filter UI controls definitions FOR DESKTOP. For mobile see FILTER_DEFINITIONS in `filter-definitions.ts`
 * The order that these appear here is the order that they will be rendered in the UI
 * */
export const FILTER_CONTROLS = {
  standard: [
    MLS_STATE_FILTER_DEF,
    LIST_PRICE_FILTER_DEF,
    HAS_POOL_FILTER_DEF,
    HAS_BASEMENT_FILTER_DEF,
    PROPERTY_TYPE_FILTER_DEF,
    MULTI_FAMILY_FILTER_DEF,
    BEDS_FILTER_DEF,
    {
      title: getFilterTitle(FILTER_KEYS.BATHS_MIN_MAX),
      key: FILTER_KEYS.BATHS_MIN_MAX,
      minValue: 0,
      maxValue: getFilterOptions(FILTER_KEYS.BEDS_MIN_MAX).slice(-1)[0],
      isNumberAdjuster: true,
      setValueForStateFormatter: (value) => [value === 0 ? null : value, null],
      labelFormatter: (value) => `${value}+`,
      summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.BATHS_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.AVM_PRICE_MIN_MAX),
      key: FILTER_KEYS.AVM_PRICE_MIN_MAX,
      isDropdownRange: true,
      options: [null, ...getFilterOptions(FILTER_KEYS.AVM_PRICE_MIN_MAX)],
      labelFormatter: (value, minOrMax) =>
        value === null
          ? `No ${minOrMax}`
          : getLabelFormatterForFilter(FILTER_KEYS.AVM_PRICE_MIN_MAX)(value),
      setValueForStateFormatter: (value) => value,
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.AVM_PRICE_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.SQFT_MIN_MAX),
      key: FILTER_KEYS.SQFT_MIN_MAX,
      isDropdownRange: true,
      options: [null, ...getFilterOptions(FILTER_KEYS.SQFT_MIN_MAX)],
      labelFormatter: (value, minOrMax) =>
        value === null
          ? `No ${minOrMax}`
          : getLabelFormatterForFilter(FILTER_KEYS.SQFT_MIN_MAX)(value),
      setValueForStateFormatter: (value) => value,
      summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.SQFT_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX),
      key: FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX,
      isDropdownRange: true,
      options: [
        null,
        ...getFilterOptions(FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX),
      ],
      setValueForStateFormatter: (value) => value,
      labelFormatter: (value, minOrMax) =>
        value === null
          ? `No ${minOrMax}`
          : getLabelFormatterForFilter(FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX)(
              value
            ),
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.LOT_AREA_MIN_MAX),
      key: FILTER_KEYS.LOT_AREA_MIN_MAX,
      isDropdownRange: true,
      options: [null, ...getFilterOptions(FILTER_KEYS.LOT_AREA_MIN_MAX)],
      labelFormatter: (value, minOrMax) =>
        value === null
          ? `No ${minOrMax}`
          : getLabelFormatterForFilter(FILTER_KEYS.LOT_AREA_MIN_MAX)(value),
      setValueForStateFormatter: (value) => value,
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.LOT_AREA_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX),
      key: FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX,
      isDropdown: true,
      options: [
        null,
        ...getFilterOptions(FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX).map((a) =>
          parseInt(a.toString(), 10)
        ),
      ],
      labelFormatter: (value) => {
        return !value
          ? LABEL_DEFAULTS.NO_MAX
          : getLabelFormatterForFilter(FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX)(
              value
            );
      },
      getValueForControlFormatter: (values) => values[0],
      setValueForStateFormatter: (daysAgo) => {
        return daysAgo ? [daysAgo, null] : [null, null];
      },
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.YEAR_BUILT_MIN_MAX),
      key: FILTER_KEYS.YEAR_BUILT_MIN_MAX,
      isDropdownRange: true,
      options: [
        null,
        ...getFilterOptions(FILTER_KEYS.YEAR_BUILT_MIN_MAX),
      ].reverse(),
      labelFormatter: (value, minOrMax) =>
        value === null
          ? `No ${minOrMax}`
          : getLabelFormatterForFilter(FILTER_KEYS.YEAR_BUILT_MIN_MAX)(value),
      setValueForStateFormatter: (value) => value,
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.YEAR_BUILT_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.HPI_MIN_MAX),
      key: FILTER_KEYS.HPI_MIN_MAX,
      minValue: HPI_DEFAULTS.min,
      maxValue: HPI_DEFAULTS.max,
      isRangeSlider: true,
      step: 0.01,
      color: 'greenGradient',
      getValueForControlFormatter: (v) => v,
      setValueForStateFormatter: (value) => [
        value.min === HPI_DEFAULTS.min ? null : value.min,
        value.max === HPI_DEFAULTS.max ? null : value.max,
      ],
      labelFormatter: (value) =>
        getLabelFormatterForFilter(FILTER_KEYS.HPI_MIN_MAX)(value),
      ariaLabelFormatter: (value) => value * 100,
      summaryFormatter: (v) => getFilterSummary(FILTER_KEYS.HPI_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX),
      key: FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX,
      minValue: CRIME_COUNTY_PERCENTILE_MIN_MAX_DEFAULTS.min,
      maxValue: CRIME_COUNTY_PERCENTILE_MIN_MAX_DEFAULTS.max,
      isRangeSlider: true,
      getValueForControlFormatter: (value) => value,
      setValueForStateFormatter: (values) => [
        values.min === CRIME_COUNTY_PERCENTILE_MIN_MAX_DEFAULTS.min
          ? null
          : values.min,
        values.max === CRIME_COUNTY_PERCENTILE_MIN_MAX_DEFAULTS.max
          ? null
          : values.max,
      ],
      labelFormatter: (value) =>
        getLabelFormatterForFilter(FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX)(
          value
        ),
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.CRIME_COUNTY_PERCENTILE_MIN_MAX, v),
      step: 10,
    },
    {
      subTitle: getSchoolsDesktopFilterTitle(
        FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX
      ),
      key: FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX,
      minValue: ELEMENTARY_SCHOOL_DEFAULTS.min,
      maxValue: ELEMENTARY_SCHOOL_DEFAULTS.max,
      isRangeSlider: true,
      step: 10,
      getValueForControlFormatter: (value) => value,
      setValueForStateFormatter: (values) => [
        values.min === ELEMENTARY_SCHOOL_DEFAULTS.min ? null : values.min,
        values.max === ELEMENTARY_SCHOOL_DEFAULTS.max ? null : values.max,
      ],
      labelFormatter: (value) =>
        getLabelFormatterForFilter(
          FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX
        )(value),
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.BEST_PRIMARY_SCHOOL_PERCENTILE_MIN_MAX, v),
    },
    {
      subTitle: getSchoolsDesktopFilterTitle(
        FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX
      ),
      key: FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX,
      minValue: MIDDLE_SCHOOL_DEFAULTS.min,
      maxValue: MIDDLE_SCHOOL_DEFAULTS.max,
      isRangeSlider: true,
      step: 10,
      getValueForControlFormatter: (value) => value,
      setValueForStateFormatter: (values) => [
        values.min === MIDDLE_SCHOOL_DEFAULTS.min ? null : values.min,
        values.max === MIDDLE_SCHOOL_DEFAULTS.max ? null : values.max,
      ],
      labelFormatter: (value) =>
        getLabelFormatterForFilter(
          FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX
        )(value),
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.BEST_MIDDLE_SCHOOL_PERCENTILE_MIN_MAX, v),
    },
    {
      subTitle: getSchoolsDesktopFilterTitle(
        FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX
      ),
      key: FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX,
      minValue: HIGH_SCHOOL_DEFAULTS.min,
      maxValue: HIGH_SCHOOL_DEFAULTS.max,
      isRangeSlider: true,
      step: 10,
      getValueForControlFormatter: (value) => value,
      setValueForStateFormatter: (values) => [
        values.min === HIGH_SCHOOL_DEFAULTS.min ? null : values.min,
        values.max === HIGH_SCHOOL_DEFAULTS.max ? null : values.max,
      ],
      labelFormatter: (value) =>
        getLabelFormatterForFilter(
          FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX
        )(value),
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.BEST_HIGH_SCHOOL_PERCENTILE_MIN_MAX, v),
    },
  ],
  // These filters are hidden by default, and show up under the Advanced Filters reveal:
  advanced: [
    {
      title: getFilterTitle(FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX),
      key: FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX,
      isDropdown: true,
      options: [
        null,
        ...getFilterOptions(FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX),
      ],
      shortDescription: getFilterDef(FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX)
        .description_short,
      labelFormatter: (value) =>
        value
          ? getLabelFormatterForFilter(FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX)(
              value
            )
          : 'Any',
      setValueForStateFormatter: (value) => [null, value],
      getValueForControlFormatter: (value) => value[1],
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX),
      key: FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX,
      minValue: RISK_OF_DECLINE_DEFAULTS.min,
      maxValue: RISK_OF_DECLINE_DEFAULTS.max,
      shortDescription: getFilterDef(FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX)
        .description_short,
      isRangeSlider: true,
      color: 'coolToHot',
      step: 0.1,
      getValueForControlFormatter: (value) => value,
      setValueForStateFormatter: (values) => [
        values.min === RISK_OF_DECLINE_DEFAULTS.min
          ? null
          : Math.round(values.min * 10) / 10,
        values.max === RISK_OF_DECLINE_DEFAULTS.max
          ? null
          : Math.round(values.max * 10) / 10,
      ],
      labelFormatter: (value) =>
        getLabelFormatterForFilter(FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX)(
          value
        ),
      ariaLabelFormatter: (value) => value * 100,
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.YEAR_RISK_OF_DECLINE_MIN_MAX, v),
    },
    // NOTE: The market grade values are spoofed to be letters, as numbers are the
    // only thing acceptable to the range slider.
    {
      title: getFilterTitle(FILTER_KEYS.MARKET_GRADE_MIN_MAX),
      key: FILTER_KEYS.MARKET_GRADE_MIN_MAX,
      minValue: 0,
      maxValue: MARKET_GRADE_FILTER_CHAR_MAPPING.length - 1,
      shortDescription: getFilterDef(FILTER_KEYS.MARKET_GRADE_MIN_MAX)
        .description_short,
      isRangeSlider: true,
      step: 1,
      getValueForControlFormatter: (value) => {
        const charToNum = MARKET_GRADE_FILTER_CHAR_MAPPING.indexOf(value);
        if (charToNum > -1) {
          return charToNum;
        } else {
          return value;
        }
      },
      setValueForStateFormatter: (values) => {
        return [
          !values.min ? null : MARKET_GRADE_FILTER_CHAR_MAPPING[values.min],
          values.max === MARKET_GRADE_FILTER_CHAR_MAPPING.length - 1
            ? null
            : MARKET_GRADE_FILTER_CHAR_MAPPING[values.max],
        ];
      },
      labelFormatter: (value) =>
        getLabelFormatterForFilter(FILTER_KEYS.MARKET_GRADE_MIN_MAX)(value),
    },
    {
      title: getFilterTitle(FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX),
      key: FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX,
      isDropdownRange: true,
      options: [null, ...getFilterOptions(FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX)],
      shortDescription: getFilterDef(FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX)
        .description_short,
      labelFormatter: (value, minOrMax) =>
        value === null
          ? `No ${minOrMax}`
          : getLabelFormatterForFilter(FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX)(
              value
            ),
      setValueForStateFormatter: (value) => value,
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX, v),
    },
    {
      title: getFilterTitle(FILTER_KEYS.RENTAL_YIELD_MIN_MAX),
      key: FILTER_KEYS.RENTAL_YIELD_MIN_MAX,
      isDropdown: true,
      options: [null, ...getFilterOptions(FILTER_KEYS.RENTAL_YIELD_MIN_MAX)],
      shortDescription: getFilterDef(FILTER_KEYS.RENTAL_YIELD_MIN_MAX)
        .description_short,
      labelFormatter: (value) =>
        value
          ? getLabelFormatterForFilter(FILTER_KEYS.RENTAL_YIELD_MIN_MAX)(value)
          : 'No min',
      setValueForStateFormatter: (value) => [value, null],
      getValueForControlFormatter: (value) => value[0],
      summaryFormatter: (v) =>
        getFilterSummary(FILTER_KEYS.RENTAL_YIELD_MIN_MAX, v),
    },
  ],
};

export const FLAT_FILTER_CONTROLS = [
  ...FILTER_CONTROLS.standard,
  ...FILTER_CONTROLS.advanced,
];

export type FilterControl = NonNullable<(typeof FLAT_FILTER_CONTROLS)[0]>;

export type FilterControlType =
  (typeof CONTROL_TYPES)[keyof typeof CONTROL_TYPES];

/**
 * Given a control type, get the correct React component
 * @param {string} type
 * @return {component} the control
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const mapMobileControlTypeToComponent: Function = (
  type: FilterControlType
) => {
  switch (type) {
    case CONTROL_TYPES.CHECKBOX_PICKER:
      return MobileCheckboxPicker;
    case CONTROL_TYPES.MAX_ONLY_PICKER:
      return MaxOnlyPicker;
    case CONTROL_TYPES.MIN_MAX_PICKER:
      return MinMaxPicker;
    case CONTROL_TYPES.MIN_ONLY_PICKER:
      return MinOnlyPicker;
    case CONTROL_TYPES.MIN_ONLY_BUTTONS:
      return MinOnlyButtons;
    case CONTROL_TYPES.MIN_MAX_SLIDER:
      return MobileFiltersSlider;
    default:
      return;
  }
};

const minMaxPickerFormatter = (
  value: string | number,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  cb: Function,
  fallback
): string | number | null => {
  if (value === LABEL_DEFAULTS.NO_MIN || value === LABEL_DEFAULTS.NO_MAX) {
    return fallback !== undefined ? fallback : value;
  } else {
    return cb(value);
  }
};

const minMaxInt = (value: string | number): string | number | null =>
  minMaxPickerFormatter(value, parseInt, null);
const minMaxFloat = (value: string | number): string | number | null =>
  minMaxPickerFormatter(value, parseFloat, null);

export const CONTROL_FORMATTER_OVERRIDES_PROPERTY_TYPE_NO_MULTI = (v) => {
  const allValues = getFilterOptions(FILTER_KEYS.PROPERTY_TYPE).filter(
    (v) => v !== 'MULTI'
  );
  /* If all values are selected, select the 'All' option in the UI */
  if (intersection(v, allValues).length === allValues.length) {
    return [];
  } else {
    return v;
  }
};
export const CONTROL_FORMATTER_OVERRIDES = {
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: (v) => {
    return v && MARKET_GRADE_FILTER_CHAR_MAPPING.indexOf(v);
  },
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: (v) => v,
  [FILTER_KEYS.PROPERTY_TYPE]: (v) => {
    const allValues = getFilterOptions(FILTER_KEYS.PROPERTY_TYPE);
    /* If all values are selected, select the 'All' option in the UI */
    if (intersection(v, allValues).length === allValues.length) {
      return [];
    } else {
      return v;
    }
  },
  [FILTER_KEYS.MLS_STATE]: (v) => {
    const allValues = getFilterOptions(FILTER_KEYS.MLS_STATE);
    /* If all values are selected, select the 'All' option in the UI */
    if (intersection(v, allValues).length === allValues.length) {
      return [];
    } else {
      return v;
    }
  },
  [FILTER_KEYS.HPI_MIN_MAX]: (v) => {
    // filter definition has the values be 1 and then .20, or -1 and then -.1
    // however, the rest of the values only have .01 difference between them.
    // Our control can't handle the changes in step between the values, so we're
    // changing the min and max values to be .01 above the values preceding, and
    // then removing those values when we send it to the API:
    if (v === 1) {
      return 0.21;
    } else if (v === -1) {
      return -0.11;
    } else {
      return v;
    }
  },
  [FILTER_KEYS.POOL]: getFilterDef(FILTER_KEYS.POOL).summary_function,
  [FILTER_KEYS.BASEMENT]: getFilterDef(FILTER_KEYS.BASEMENT).summary_function,
};

export const FILTER_SET_VALUE_FOR_STATE_FORMATTERS_PROPERTY_TYPE_NO_MULTI = (
  v
) => {
  // Array will be empty if including all options
  if (!v.length) {
    const allPropertyTypes = Object.keys(PROPERTY_TYPE_LABELS).filter(
      (v) => v !== 'MULTI'
    );
    // ALL is not an API value, and is only for display:
    v = pull(allPropertyTypes, FE_ONLY_MLS_STATES.ALL);
  }
  return v;
};

export const FILTER_SET_VALUE_FOR_STATE_FORMATTERS = {
  [FILTER_KEYS.MLS_STATE]: (v) => {
    /* If ALL is selected or no values are selected, select all values */
    if (v.indexOf(FE_ONLY_MLS_STATES.ALL) > -1 || isEmpty(v)) {
      return MLS_STATE_OPTIONS;
    } else {
      return v.filter((value) => value !== FE_ONLY_MLS_STATES.ALL);
    }
  },
  [FILTER_KEYS.POOL]: (v) =>
    v === getFilterOptions(FILTER_KEYS.POOL)[0]
      ? true
      : v === getFilterOptions(FILTER_KEYS.POOL)[1]
        ? false
        : null,
  [FILTER_KEYS.BASEMENT]: (v) =>
    v === getFilterOptions(FILTER_KEYS.BASEMENT)[0]
      ? true
      : v === getFilterOptions(FILTER_KEYS.BASEMENT)[1]
        ? false
        : null,
  [FILTER_KEYS.AVM_PRICE_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.LIST_PRICE_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.LOT_AREA_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.PRICE_PER_SQFT50_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.RENTAL_ESTIMATE_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.SQFT_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.UNITS_TOTAL_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.YEAR_BUILT_MIN_MAX]: minMaxInt,
  [FILTER_KEYS.LIST_AGE_DAYS_MIN_MAX]: (v) => {
    const int = parseInt(v, 10);
    return !isNaN(int) ? int : null;
  },
  [FILTER_KEYS.RENTAL_YIELD_MIN_MAX]: minMaxFloat,
  [FILTER_KEYS.LIST_TO_AVM_PRICE_MIN_MAX]: minMaxFloat,
  [FILTER_KEYS.PROPERTY_TYPE]: (v) => {
    // Array will be empty if including all options
    if (!v.length) {
      // ALL is not an API value, and is only for display:
      v = pull(Object.keys(PROPERTY_TYPE_LABELS), FE_ONLY_MLS_STATES.ALL);
    }
    return v;
  },
  [FILTER_KEYS.MARKET_GRADE_MIN_MAX]: (v) => {
    if (v === 0 || v === MARKET_GRADE_FILTER_CHAR_MAPPING.length - 1) {
      return null;
    } else {
      return MARKET_GRADE_FILTER_CHAR_MAPPING.charAt(v);
    }
  },
  [FILTER_KEYS.HPI_MIN_MAX]: (v) => {
    // to be compatible with our controls, we change the min and max values listed
    // in the filter definition to .21 and -0.11, so when putting them on state we need
    // to convert them back:
    if (v > 0.2) {
      return 1;
    } else if (v < -0.1) {
      return -1;
    } else {
      return Number(v.toFixed(2));
    }
  },
};

export const getAreAllOptionsSelected = (
  allOptions: (string | number)[],
  filterValue: (string | number)[]
) => {
  return (
    Array.isArray(filterValue) &&
    filterValue.length === allOptions.length &&
    filterValue.every((value) => allOptions.includes(value))
  );
};

// Not all values need formatters. This just returns a function returning the
// argument if a formatter does not exist:
const returnEmptyFuncIfNotFound = (key: FilterKey, formattersObj: {}) => {
  return (
    formattersObj[key] ||
    function (v) {
      return v;
    }
  );
};

export const getSetStateFormatterForFilter = (
  key: FilterKey,
  isDisplayMultiFamilySearchFiltersEnabled?: boolean
) => {
  if (
    !isDisplayMultiFamilySearchFiltersEnabled &&
    key === FILTER_KEYS.PROPERTY_TYPE
  ) {
    return FILTER_SET_VALUE_FOR_STATE_FORMATTERS_PROPERTY_TYPE_NO_MULTI;
  }
  return returnEmptyFuncIfNotFound(key, FILTER_SET_VALUE_FOR_STATE_FORMATTERS);
};

/**
 * Check filter definition for ql_type and return a formatter function based on that
 * @param {string} key
 * @return {function} the parsing function
 */
export const getControlFormatterForFilter = (
  key: FilterKey,
  isDisplayMultiFamilySearchFiltersEnabled?: boolean
) => {
  if (
    !isDisplayMultiFamilySearchFiltersEnabled &&
    key === FILTER_KEYS.PROPERTY_TYPE
  ) {
    return CONTROL_FORMATTER_OVERRIDES_PROPERTY_TYPE_NO_MULTI;
  } else if (CONTROL_FORMATTER_OVERRIDES[key]) {
    return CONTROL_FORMATTER_OVERRIDES[key];
  }

  const def = getFilterDef(key);
  const qlType = def.ql_type; // string, float, or integer

  return (val) => {
    const valType = typeof val;
    if (qlType === QL_TYPES.STRING) {
      return valType === 'string' ? val : `${val}`;
    } else if (qlType === QL_TYPES.INTEGER) {
      return Number.isInteger(val) ? val : parseInt(val, 10);
    } else if (qlType === QL_TYPES.FLOAT) {
      return valType === 'number' ? val : parseFloat(val);
    }
  };
};

export const getAllPropertyTypeOptions = (
  isDisplayMultiFamilySearchFiltersEnabled: boolean
) => {
  return isDisplayMultiFamilySearchFiltersEnabled
    ? PROPERTY_TYPE_FILTER_DEF.options
    : PROPERTY_TYPE_FILTER_DEF.options.filter(
        (option) => option.value !== 'MULTI'
      );
};

export const getAllPropertyTypeValues = (
  isDisplayMultiFamilySearchFiltersEnabled: boolean
): FilterPropertyTypes[] => {
  const allPropertyTypeOptions = getAllPropertyTypeOptions(
    isDisplayMultiFamilySearchFiltersEnabled
  ).filter((option) => option.value !== 'ALL');
  return allPropertyTypeOptions.map(
    (option) => option.value as FilterPropertyTypes
  );
};

export const getPropertyTypeValues = (
  filterValues: FiltersState,
  isDisplayMultiFamilySearchFiltersEnabled: boolean
): FilterPropertyTypes[] | string[] => {
  const allPropertyTypeValues = getAllPropertyTypeValues(
    isDisplayMultiFamilySearchFiltersEnabled
  );

  const values = PROPERTY_TYPE_FILTER_DEF.getValueForControlFormatter(
    filterValues[PROPERTY_TYPE_FILTER_DEF.key],
    allPropertyTypeValues
  );

  return values &&
    values.length > 0 &&
    values.length < PROPERTY_TYPE_FILTER_DEF.options.length - 1
    ? values
    : [FE_ONLY_MLS_STATES.ALL];
};

export const getAllMlsStateOptions = (
  isShowComingSoonInListingStatusFilterEnabled: boolean
) =>
  isShowComingSoonInListingStatusFilterEnabled
    ? MLS_STATE_FILTER_DEF.options
    : MLS_STATE_FILTER_DEF.options.filter(
        (option) => option.label !== LISTING_STATUS_LABELS.COMING_SOON
      );

export const getAllMlsStateValues = (
  isTempShowComingSoonInListingStatusFilterEnabled: boolean
) => {
  const allMlsStateValues = getAllMlsStateOptions(
    isTempShowComingSoonInListingStatusFilterEnabled
  ).filter((option) => option.value !== FE_ONLY_MLS_STATES.ALL);

  return allMlsStateValues.map((option) => option.value);
};

export const getMlsStateValues = (
  filterValues: FiltersState,
  isTempShowComingSoonInListingStatusFilterEnabled: boolean
) => {
  const allMlsStateValues = getAllMlsStateValues(
    isTempShowComingSoonInListingStatusFilterEnabled
  );
  const values = MLS_STATE_FILTER_DEF.getValueForControlFormatter(
    filterValues[MLS_STATE_FILTER_DEF.key],
    allMlsStateValues
  );

  return values && values.length > 0 && values.length < allMlsStateValues.length
    ? values
    : [FE_ONLY_MLS_STATES.ALL];
};
