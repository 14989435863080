import { themr } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import ChevronIconCobranded from '@client/components/ChevronIcon/ChevronIconCobranded';
import ClickableLogo from '@client/components/ClickableLogo';
import { getTARCTALink } from '@client/components/cobrand/topagentsranked/top-agents-ranked-utils';
import CobrandedStyles from '@client/components/CobrandedStyles';
import ComehomeHeaderLogoByHousecanaryWithHouse from '@client/components/ComehomeHeaderLogoByHousecanaryWithHouse';
import ComeHomeLogoImg from '@client/components/ComeHomeLogoImg';
import DesktopNavItem from '@client/components/DesktopNavItem';
import EmailOptInSignUpCta from '@client/components/EmailOptInSignUpCta';
import ExternalLink from '@client/components/generic/ExternalLink';
import VerticalSeparator from '@client/components/generic/VerticalSeparator';
import {
  AccountMenuItem,
  getMenuItemsLoggedIn,
  getMenuItemsLoggedOut,
  isExternalUrlItem,
  isModalItem,
  isNavItem,
} from '@client/components/Header/HeaderCobranded';
import HeaderMobileBottomSection from '@client/components/HeaderMobileBottomSection';
import HeaderMobileSearchHomesNav from '@client/components/HeaderMobileSearchHomesNav';
import LendcoLogo from '@client/components/LendcoLogo';
import LenderCTAButtonPDP from '@client/components/LenderCTAButtonPDP/LenderCTAButtonPDP';
import MenuIcon from '@client/components/MenuIcon';
import MenuNotificationCount from '@client/components/MenuNotificationCount';
import ScaffoldingHeaderDesktop from '@client/components/ScaffoldingUI/ScaffoldingHeader/ScaffoldingHeaderDesktop';
import ScaffoldingHeaderMobile from '@client/components/ScaffoldingUI/ScaffoldingHeader/ScaffoldingHeaderMobile';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import HeaderBackArrowContainer from '@client/containers/header-back-arrow.container';
import defaultTheme from '@client/css-modules/Header.css';
import { useLenderCtaUrlModifier } from '@client/hooks/lender-cta-config.hooks';
import SearchIcon from '@client/inline-svgs/search';
import { View } from '@client/routes/constants';
import { EVENTS, PARENT_EVENTS } from '@client/store/analytics-constants';
import { AUTH_MODAL_PAGES } from '@client/store/constants';
import { HeaderProps } from '@client/store/types/cobranded-components/header';
import {
  COBRAND_IDS,
  CustomHeaderCtaConfig,
} from '@client/store/types/cobranding';
import {
  onEnterKey,
  onEnterOrSpaceKey,
} from '@client/utils/accessibility.utils';
import { getOutboundUrl } from '@client/utils/url-formatting.utils';
import { AnimatePresence, motion } from 'framer-motion';
import { FocusOn } from 'react-focus-on';
import { HeaderAuth } from './HeaderAuth';

const SEARCH_MENU = 'search';
const ACCOUNT_MENU = 'account';

type State = {
  isMobileMenuOpen: boolean;
  mobileMenuType: MenuType;
  isShowingSearchFieldOnDesktop: boolean;
};
type MenuType = typeof SEARCH_MENU | typeof ACCOUNT_MENU | null;

/**
 * Due to SSR requirements, the desktop vs. mobile experiences are shown/hidden via CSS.
 * Each is grouped by a top-level container in the render method.
 */
const Header = (props: HeaderProps) => {
  const {
    isLoggedIn,
    currentView,
    userId,
    getUnreadAlertsCount,
    handleShowAuthModal,
    shouldCloseMenuOnAddressSearchResultClick,
    cobrandIdName,
    backToSiteLinkAndText,
    partnerUserId,
    chapiPartnerInviteId,
    topLocalAgentAdDetails,
    isOutboundCtaEnabled,
    accessToken,
    handleClearConstrainedToPlace,
    shouldClearConstrainedPlaceOnClearingSearch,
    shouldRedirectToBackToSiteURLInSameTab,
    theme,
    isCanaryUIFeatureEnabled,
    isScaffoldingDataForHeaderDesktop,
    isScaffoldingDataForHeaderMobile,
    isShowingSearchIconForRoute,
    isShowingPersistentSearchFieldBelowHeader,
    shouldDisplayCurrentLocationSearchOption,
    placeGeoJSONDescription,
    isDesktopHeaderFullWidth,
    handleRouteChange,
    unreadAlertsCount,
    isShowingBackNavButton,
    isHidingTopSection,
    isInsideNativeApp,
    navLinkLabels,
    cobrandDisplayName,
    isHidingPersistentSearchForPageOnTablet,
    partialHeaderLinks,
    isShowingHomeownerNavLink,
    isEmailOptInModalAllowedToOpen,
    isHomePageEnabled,
    isYourTeamEnabled,
    isReferralServicesEnabled,
    userContactInfo,
    isShowingComehomeLogoForPartner,
    isShowingHeaderLenderCTA,
    lenderCtaPdpConfig,
    nativeAppLenderCTAClick,
    isLargerMobileHeaderLogoEnabled,
    grantProgramHeaderNavItem,
    customNavCtas,
    handleLogoutAndRedirect,
    handleLogout,
    handleReportClickLenderNavBackToSite,
    shouldLogOutOnBackToSite,
    handleReportClickLenderNavLink1,
    handleReportClickLenderNavLink2,
    handleReportClickLenderNavLink3,
    handleOpenModal,
  } = props;

  const getCtaUrl = (
    originalCtaUrl?: string | null
  ): string | undefined | null => {
    if (isOutboundCtaEnabled && accessToken && originalCtaUrl) {
      return getOutboundUrl({ originalCtaUrl, accessToken });
    }
    return originalCtaUrl;
  };

  const grantProgramHeaderNavUrl = getCtaUrl(grantProgramHeaderNavItem?.url);
  const modifiedGrantProgramHeaderNavUrl = useLenderCtaUrlModifier(
    grantProgramHeaderNavUrl || null
  );

  const aboveMobileAccountMenuEleRef = useRef<HTMLDivElement>(null);
  const mobileAccountMenuCloseButtonRef = useRef<HTMLButtonElement>(null);

  const [state, setState] = useState<State>({
    isMobileMenuOpen: false,
    mobileMenuType: null,
    isShowingSearchFieldOnDesktop: false,
  });

  useEffect(() => {
    /* Only attempt alerts fetch if logged in, otherwise we display a message on the page */
    /* When logging in/signing up FROM the alerts page, fetch alerts after authenticating */
    if (isLoggedIn && userId) {
      getUnreadAlertsCount();
    }
  }, [isLoggedIn, userId]);

  useEffect(() => {
    closeSearchOnDesktop();
    closeMobileMenu();
  }, []);

  const toggleAccountMenu = (): void => {
    toggleMobileMenu(
      state.mobileMenuType !== ACCOUNT_MENU ? ACCOUNT_MENU : null
    );
  };

  const toggleMobileMenu = (newMenuType: MenuType): void => {
    const { isMobileMenuOpen } = state;
    if (newMenuType === ACCOUNT_MENU && !isMobileMenuOpen) {
      /* Prevent scrolling on the body when the mobile menu is open since it's currently absolutely positioned
       * and we don't want to allow users to scroll past it
       * TODO change the mobile menu to be fixed positioned and remove this */
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
    setState({
      ...state,
      isMobileMenuOpen: !isMobileMenuOpen,
      mobileMenuType: newMenuType,
    });
  };

  const closeMobileMenu = (): void => {
    document.body.style.overflow = 'initial';
    setState({ ...state, isMobileMenuOpen: false, mobileMenuType: null });
  };

  const toggleSearchOnDesktop = (
    e: React.MouseEvent | React.KeyboardEvent
  ): void => {
    e.stopPropagation();
    setState({
      ...state,
      isShowingSearchFieldOnDesktop: !state.isShowingSearchFieldOnDesktop,
    });
  };

  const closeSearchOnDesktop = (): void => {
    setState({ ...state, isShowingSearchFieldOnDesktop: false });
  };

  const localShowAuthModal = (): void => {
    setState({ ...state, isMobileMenuOpen: false });
    handleShowAuthModal();
  };

  const onAddressSearchResultClick = (): void => {
    if (shouldCloseMenuOnAddressSearchResultClick) {
      closeMobileMenu();
    }
    closeSearchOnDesktop();
  };

  const handleLogoutAndRedirectTo = (redirectUrl: string | null) => {
    closeMobileMenu();
    if (!!handleLogoutAndRedirect && redirectUrl) {
      handleLogoutAndRedirect(redirectUrl);
    } else {
      handleLogout();
    }
  };

  const getBackToSiteUrl = (): string | null => {
    const { homeSiteUrl } = backToSiteLinkAndText;

    if (cobrandIdName === COBRAND_IDS.TOPAGENTSRANKED) {
      return getTARCTALink(
        topLocalAgentAdDetails,
        isLoggedIn,
        partnerUserId,
        chapiPartnerInviteId
      );
    } else if (isOutboundCtaEnabled && homeSiteUrl && accessToken) {
      return getOutboundUrl({ originalCtaUrl: homeSiteUrl, accessToken });
    } else {
      return homeSiteUrl;
    }
  };

  const handleBackToSite = () => {
    const backToSiteUrl = getBackToSiteUrl();

    if (!!handleReportClickLenderNavBackToSite) {
      handleReportClickLenderNavBackToSite();
    }

    if (backToSiteUrl) {
      if (!!shouldLogOutOnBackToSite) {
        // If we're performing a logout on this click, open the target URL in the current tab
        handleLogoutAndRedirectTo(backToSiteUrl);
      } else {
        if (shouldRedirectToBackToSiteURLInSameTab) {
          window.location.href = backToSiteUrl;
        } else {
          window.open(backToSiteUrl);
        }
      }
    }
  };

  const handleClickScreen = () => {
    closeMobileMenu();
  };

  const handleDesktopClickScreen = (e: React.MouseEvent): void => {
    e.stopPropagation();
    closeSearchOnDesktop();
  };

  const handleClearEmptyInput = () => {
    closeMobileMenu();
  };

  const handleClearPopulatedInput = () => {
    /* Clear the place boundary on the map and send subsequent property requests without a
     * place constraint */
    if (shouldClearConstrainedPlaceOnClearingSearch) {
      handleClearConstrainedToPlace();
    }
  };

  const handleReportingLenderNavItemClick = (navItemIdx) => {
    switch (navItemIdx) {
      case 0:
        if (handleReportClickLenderNavLink1) {
          handleReportClickLenderNavLink1();
        }
        break;
      case 1:
        if (handleReportClickLenderNavLink2) {
          handleReportClickLenderNavLink2();
        }
        break;
      case 2:
        if (handleReportClickLenderNavLink3) {
          handleReportClickLenderNavLink3();
        }
        break;
      default:
        break;
    }
  };

  const handleAccountMenuOnClick = (
    menuItem: AccountMenuItem,
    menuItemIdx: number | null
  ): void => {
    if (isModalItem(menuItem)) {
      closeMobileMenu();
      handleOpenModal(menuItem.modalKey);
    } else if (isNavItem(menuItem)) {
      if (menuItem.link) {
        window.open(menuItem.link, '_blank');
        handleReportingLenderNavItemClick(menuItemIdx);
      } else if (menuItem.key === View.LOGOUT) {
        handleLogoutAndRedirectTo(null);
      } else if (menuItem.route) {
        closeMobileMenu();

        if (menuItem.route === View.SIGN_UP || menuItem.route === View.LOGIN) {
          // for SIGN_UP/LOGIN routes, show the modal instead of the actual route in this context
          const startingPage =
            menuItem.route === View.SIGN_UP
              ? AUTH_MODAL_PAGES.SIGN_UP
              : AUTH_MODAL_PAGES.LOGIN;
          handleShowAuthModal(startingPage);
        } else {
          handleRouteChange(menuItem.route);
        }
      }
    } else if (isExternalUrlItem(menuItem)) {
      const url =
        menuItem.dataEventName === 'click_grant_header_cta'
          ? modifiedGrantProgramHeaderNavUrl
          : menuItem.url;

      url && window.open(url, '_blank');
    }
  };

  const getNavItemsWithPartialHeaderLinks = (): ReactElement[] => {
    const backToSiteMenuItem = createBackToSiteMenuItem();
    const partialHeaderLinksNavItems = partialHeaderLinks
      ? partialHeaderLinks.map((link, index) => createAccountMenu(link, index))
      : [];
    const effectiveGetNavItemFunc = isLoggedIn
      ? getMenuItemsLoggedIn
      : getMenuItemsLoggedOut;

    let finalNavItems = effectiveGetNavItemFunc(props)
      .map((navItem, index) => createAccountMenu(navItem, index))
      .concat(customNavCtasMenu())
      .concat(partialHeaderLinksNavItems);

    if (backToSiteMenuItem) {
      finalNavItems.unshift(backToSiteMenuItem);
    }

    return finalNavItems;
  };

  const createAccountMenu = (
    menuItem: AccountMenuItem,
    menuItemIdx: number | null
  ): ReactElement => {
    const isSelected = isNavItem(menuItem) && currentView === menuItem.route;
    return (
      <li className={theme.MobileMenuListItem}>
        <CobrandedStyles>
          {({ linkColor }) => (
            <button
              type="button"
              role="link"
              className={classNames(theme.MobileMenuListItemButton, {
                [theme.MobileMenuListItemSelected]: isSelected,
              })}
              style={isSelected ? { color: linkColor } : {}}
              key={menuItem.title}
              data-hc-name={menuItem.dataHcName}
              data-event-name={menuItem.dataEventName}
              data-parent-event-name={menuItem.dataParentEventName}
              onClick={() => handleAccountMenuOnClick(menuItem, menuItemIdx)}
            >
              {menuItem.title}
            </button>
          )}
        </CobrandedStyles>
      </li>
    );
  };

  const customNavCtasMenu = (): ReactElement[] => {
    return customNavCtas.map((cta: CustomHeaderCtaConfig, index) => {
      return (
        <li
          className={theme.MobileMenuListItem}
          key={`custom-header-cta-${index}`}
        >
          <button
            type="button"
            role="link"
            className={classNames(theme.MobileMenuListItemButton, {
              [theme.MobileMenuListItemSelected]: false,
            })}
            key={cta.label}
            data-hc-name={`custom-header-cta-${index}`}
            data-event-name={`click_header_cta_${index + 1}`}
            data-parent-event-name="lender_ctas"
            onClick={() => {
              cta.url && window.open(cta.url, '_blank', 'noopener');
            }}
          >
            {cta.label}
          </button>
        </li>
      );
    });
  };

  const createBackToSiteMenuItem = (): ReactElement | null => {
    const { backToSiteText, homeSiteUrl } = backToSiteLinkAndText;
    return backToSiteText && homeSiteUrl ? (
      <li className={theme.MobileMenuListItem}>
        <button
          type="button"
          role="link"
          className={classNames(
            theme.MobileMenuListItemButton,
            theme.MobileMenuListItemSelected
          )}
          key={backToSiteText}
          onClick={() => {
            handleBackToSite();
          }}
        >
          <span className={theme.BackToSiteWrapper}>
            <ChevronIconCobranded className={theme.ChevronIcon} />
            {backToSiteText}
          </span>
        </button>
      </li>
    ) : null;
  };

  const handleRouteChangeToRoot = () => {
    handleRouteChange(isHomePageEnabled ? View.HOMEPAGE : View.SEARCH);
  };

  const setMobileAccountMenuHeight = (ele: HTMLDivElement | null) => {
    if (ele) {
      const { top } = ele.getBoundingClientRect();
      ele.style.height = `calc(100vh - ${top}px)`;
    }
  };

  const { isMobileMenuOpen, isShowingSearchFieldOnDesktop, mobileMenuType } =
    state;
  const isAccountMenuOpen = mobileMenuType === ACCOUNT_MENU;
  const isSearchMenuOpen =
    isShowingPersistentSearchFieldBelowHeader ||
    (isMobileMenuOpen && mobileMenuType === SEARCH_MENU);
  const { backToSiteText, homeSiteUrl } = backToSiteLinkAndText;
  const clickableLogoAriaLabel = `${cobrandDisplayName} logo, navigate to the ${
    isHomePageEnabled ? 'home' : 'search'
  } page`;

  return (
    <CobrandedStyles>
      {({
        activeNavItemColor,
        activeAddressSearchLabelPosition,
        headerBackgroundColor,
        logoUrl,
        mobileLogoUrl,
        partialHeaderNavItemColor,
        partialHeaderNavItemHoverColor,
      }) => (
        <>
          {/* Only displayed on small screens via CSS */}
          <div className={theme.MobileContainer}>
            {isCanaryUIFeatureEnabled && isScaffoldingDataForHeaderMobile ? (
              <div style={{ height: 'auto', width: '100%' }}>
                <ScaffoldingHeaderMobile />
              </div>
            ) : (
              <div
                className={theme.AboveMobileAccountMenuContainer}
                ref={aboveMobileAccountMenuEleRef}
              >
                {!isHidingTopSection && (
                  <div
                    className={theme.MobileTopSection}
                    style={{ backgroundColor: headerBackgroundColor }}
                  >
                    <MenuIcon
                      ariaLabel={isAccountMenuOpen ? 'Close menu' : 'Open menu'}
                      handleClick={toggleAccountMenu}
                      active={isAccountMenuOpen}
                      theme={theme}
                      color={partialHeaderNavItemColor}
                      buttonRef={mobileAccountMenuCloseButtonRef}
                    />
                    {isShowingComehomeLogoForPartner ? (
                      <div className={theme.MobileComehomeLogoContainer}>
                        <ClickableLogo
                          dataHcName={'comehome-logo'}
                          ariaLabel="ComeHome logo"
                          Logo={ComeHomeLogoImg}
                          LogoByHouseCanary={
                            ComehomeHeaderLogoByHousecanaryWithHouse
                          }
                          theme={theme}
                        />
                      </div>
                    ) : mobileLogoUrl ? (
                      <div
                        className={classNames(theme.HeaderLogo, {
                          [theme.LargerMobileHeaderLogo]:
                            isLargerMobileHeaderLogoEnabled,
                        })}
                        aria-label={clickableLogoAriaLabel}
                        onClick={handleRouteChangeToRoot}
                      >
                        <img
                          src={mobileLogoUrl}
                          alt={`${cobrandDisplayName} logo`}
                        />
                      </div>
                    ) : (
                      <ClickableLogo
                        dataHcName={'company-logo'}
                        alwaysUseRouteChange={true}
                        ariaLabel={clickableLogoAriaLabel}
                        Logo={LendcoLogo}
                        theme={theme}
                      />
                    )}
                    <div className={theme.MobileSearchIconContainer} />
                  </div>
                )}
                <div className={theme.MobileMenuContainer}>
                  <AnimatePresence>
                    {isSearchMenuOpen && (
                      <motion.nav
                        initial={{ height: 0, opacity: 0 }}
                        exit={{
                          height: 0,
                          opacity: 0,
                          transition: {
                            opacity: {
                              duration: 0.1,
                            },
                            height: {
                              duration: 0.2,
                            },
                          },
                        }}
                        animate={{
                          opacity: 1,
                          height: 'initial',
                          transition: {
                            ease: 'easeInOut',
                            duration: isSearchMenuOpen ? 0.1 : 0.2,
                          },
                        }}
                        key="menu"
                        aria-label="Navigation menu"
                        className={classNames(theme.MobileSearchItemAnimation, {
                          [theme.MobileSearchItemAnimationHiddenOnTablet]:
                            isHidingPersistentSearchForPageOnTablet,
                        })}
                      >
                        <ul className={theme.MobileMenuList}>
                          <li
                            key={'SearchContainer'}
                            className={theme.SearchItem}
                          >
                            {/* Only focus autocomplete input on mount when not logged in,
                                      otherwise saved searches would automatically be shown, blocking other menu items */}
                            <AutoCompleteSearchContainer
                              /* Only focus the input on mount if it's not persistently shown and if the user is logged-out
                               * since otherwise the saved searches will be displayed which might be jarring */
                              focusInputOnMount={
                                !isShowingPersistentSearchFieldBelowHeader &&
                                !isLoggedIn
                              }
                              theme={theme}
                              onResultClick={onAddressSearchResultClick}
                              prefilledUserInput={placeGeoJSONDescription}
                              handleClearPopulatedInput={
                                handleClearPopulatedInput
                              }
                              handleClearEmptyInput={handleClearEmptyInput}
                              shouldDisplayCurrentLocationOption={
                                shouldDisplayCurrentLocationSearchOption
                              }
                              hasVisibleLabel
                              activeAddressSearchLabelPosition={
                                activeAddressSearchLabelPosition
                              }
                              disabled={isAccountMenuOpen}
                            />
                          </li>
                        </ul>
                      </motion.nav>
                    )}
                    {!!(isAccountMenuOpen && aboveMobileAccountMenuEleRef) && (
                      <motion.nav
                        initial={{ x: '-100%' }}
                        exit={{ x: '-100%' }}
                        animate={{
                          x: '0%',
                          transition: {
                            ease: 'easeInOut',
                            duration: isAccountMenuOpen ? 0.1 : 0.2,
                            damping: 0,
                          },
                        }}
                        key="account-menu"
                        style={{
                          top: aboveMobileAccountMenuEleRef?.current?.getBoundingClientRect()
                            .height,
                        }}
                        className={theme.MobileAccountMenuAnimation}
                      >
                        <FocusOn
                          className={theme.MobileMenuList}
                          onEscapeKey={toggleAccountMenu}
                          onClickOutside={closeMobileMenu}
                          shards={[mobileAccountMenuCloseButtonRef]}
                        >
                          <div
                            className={theme.MobileAccountMenuContainer}
                            ref={setMobileAccountMenuHeight}
                          >
                            <ul className={theme.MobileMenuList}>
                              {isEmailOptInModalAllowedToOpen && (
                                <li>
                                  <EmailOptInSignUpCta
                                    isInMobileView
                                    handleClickEmailOptInSignUpCta={
                                      closeMobileMenu
                                    }
                                  />
                                </li>
                              )}
                              {getNavItemsWithPartialHeaderLinks()}
                              {isShowingHeaderLenderCTA && (
                                <li>
                                  <div
                                    className={theme.LenderCTAButtonPDPWrapper}
                                  >
                                    <LenderCTAButtonPDP
                                      theme={theme}
                                      dataEventName="click_lender_cta_header"
                                      dataParentEventName="lender_ctas"
                                    />
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        </FocusOn>
                      </motion.nav>
                    )}
                  </AnimatePresence>
                  {isAccountMenuOpen && aboveMobileAccountMenuEleRef && (
                    <div
                      style={{
                        top: aboveMobileAccountMenuEleRef?.current?.getBoundingClientRect()
                          .height,
                      }}
                      className={theme.MobileAccountMenuBackdrop}
                      onClick={closeMobileMenu}
                    />
                  )}
                  {isSearchMenuOpen &&
                    !isShowingPersistentSearchFieldBelowHeader && (
                      <div
                        className={theme.MobileMenuScreen}
                        onClick={handleClickScreen}
                      />
                    )}
                </div>
                <HeaderMobileBottomSection
                  isShowingBackNavButton={isShowingBackNavButton}
                  currentView={currentView}
                  theme={theme}
                />
                <HeaderMobileSearchHomesNav theme={theme} />
              </div>
            )}
          </div>

          {/* Only displayed on large screens via CSS */}
          <div className={theme.DesktopContainer}>
            {isCanaryUIFeatureEnabled && isScaffoldingDataForHeaderDesktop ? (
              <div style={{ height: 'auto', width: '100%' }}>
                <ScaffoldingHeaderDesktop />
              </div>
            ) : (
              <div
                className={theme.DesktopHeaderTopRow}
                style={{ backgroundColor: headerBackgroundColor }}
              >
                <div
                  className={classNames(
                    theme.DesktopHeaderTopRowInnerContainer,
                    {
                      [theme.DesktopInnerContainerFullWidth]:
                        isDesktopHeaderFullWidth,
                      [theme.removeRightPadding]:
                        isEmailOptInModalAllowedToOpen,
                    }
                  )}
                >
                  <div className={theme.DesktopHeaderTopRowLeftContainer}>
                    {backToSiteText && homeSiteUrl && (
                      <div className={theme.BackToSiteContainer}>
                        <button
                          onClick={() => handleBackToSite()}
                          onKeyDown={onEnterKey(handleBackToSite)}
                          className={theme.BackToSiteLink}
                        >
                          <style
                            dangerouslySetInnerHTML={{
                              __html: `
                                .${theme.DesktopChevronIcon} .element-to-fill {
                                  fill: ${partialHeaderNavItemColor}
                                }
                                .${theme.BackToSiteText}:hover {
                                  color: ${partialHeaderNavItemHoverColor} !important
                                }
                                .${theme.BackToSiteText}:hover .${theme.DesktopChevronIcon} .element-to-fill {
                                  fill: ${partialHeaderNavItemHoverColor}
                                }
                                `,
                            }}
                          />
                          <span
                            className={theme.BackToSiteText}
                            style={{ color: partialHeaderNavItemColor }}
                          >
                            <ChevronIconCobranded
                              className={theme.DesktopChevronIcon}
                            />
                            {backToSiteText}
                          </span>
                        </button>
                        <VerticalSeparator theme={theme} />
                      </div>
                    )}
                    {isShowingComehomeLogoForPartner ? (
                      <div className={theme.DesktopComehomeLogoContainer}>
                        <ClickableLogo
                          dataHcName={'comehome-logo'}
                          Logo={ComeHomeLogoImg}
                          LogoByHouseCanary={
                            ComehomeHeaderLogoByHousecanaryWithHouse
                          }
                          theme={theme}
                        />
                      </div>
                    ) : logoUrl ? (
                      <button
                        className={theme.LendcoLogo}
                        role="link"
                        aria-label={`Navigate to the ${cobrandDisplayName} ComeHome search page`}
                        onKeyDown={onEnterOrSpaceKey(handleRouteChangeToRoot)}
                        onClick={handleRouteChangeToRoot}
                      >
                        <img src={logoUrl} alt={`${cobrandDisplayName}`} />
                      </button>
                    ) : (
                      <button
                        className={theme.LendcoLogo}
                        data-hc-name={'company-logo'}
                        role="link"
                        onKeyDown={onEnterOrSpaceKey(handleRouteChangeToRoot)}
                        onClick={handleRouteChangeToRoot}
                      >
                        <LendcoLogo shouldUseAltText />
                      </button>
                    )}
                  </div>
                  <div
                    className={classNames(
                      theme.DesktopHeaderTopRowRightContainer,
                      {
                        [theme.removeRightMargin]:
                          isEmailOptInModalAllowedToOpen,
                      }
                    )}
                  >
                    {partialHeaderLinks &&
                      partialHeaderLinks.map((navItem, navItemIndex) => {
                        return (
                          <ExternalLink
                            href={navItem.link}
                            target={'_blank'}
                            className={theme.PartialNavItemLink}
                            key={navItem.key}
                            onClick={() =>
                              handleReportingLenderNavItemClick(navItemIndex)
                            }
                          >
                            <style
                              dangerouslySetInnerHTML={{
                                __html: `
                               .${theme.PartialNavItemLink} .${theme.PartialNavItemText} {
                                 color: ${partialHeaderNavItemColor};
                               }
                               .${theme.PartialNavItemLink}:hover .${theme.PartialNavItemText} {
                                 color: ${partialHeaderNavItemHoverColor};
                                 transition: color 200ms;
                               }
                             `,
                              }}
                            />
                            <div className={theme.PartialNavItemText}>
                              {navItem.title}
                            </div>
                          </ExternalLink>
                        );
                      })}
                    {isEmailOptInModalAllowedToOpen && <EmailOptInSignUpCta />}
                  </div>
                </div>
              </div>
            )}
            <div className={theme.DesktopHeaderBottomRow}>
              <div
                className={classNames(
                  theme.DesktopHeaderBottomRowInnerContainer,
                  {
                    [theme.DesktopInnerContainerFullWidth]:
                      isDesktopHeaderFullWidth,
                  }
                )}
              >
                <div className={theme.DesktopHeaderBottomRowLeftContainer}>
                  {isShowingBackNavButton ? (
                    <HeaderBackArrowContainer />
                  ) : (
                    <>
                      <DesktopNavItem
                        dataHcName={'buy-home-button'}
                        theme={theme}
                        view={View.SEARCH}
                        currentView={currentView}
                        onClick={() => handleRouteChange(View.SEARCH)}
                        activeColor={activeNavItemColor}
                        hasActiveColorWhenHovered
                      >
                        {navLinkLabels.navLinkSearchLabel || 'Find a home'}
                      </DesktopNavItem>
                      {isShowingHomeownerNavLink && (
                        <DesktopNavItem
                          theme={theme}
                          dataHcName={'my-home-button'}
                          dataTooltipAnchorId={'my-home'}
                          view={[
                            View.HOMEOWNER,
                            View.HOMEOWNER_CLAIMED_HOMES,
                            View.HOMEOWNER_PROPERTY_DETAILS,
                          ]}
                          currentView={currentView}
                          onClick={() => handleRouteChange(View.HOMEOWNER)}
                          activeColor={activeNavItemColor}
                          hasActiveColorWhenHovered
                        >
                          {navLinkLabels.navLinkHomeownerLabel || 'My home'}
                        </DesktopNavItem>
                      )}
                      <DesktopNavItem
                        dataHcName={'saved-button'}
                        theme={theme}
                        view={[View.WATCHLIST, View.SAVED_SEARCHES]}
                        currentView={currentView}
                        onClick={() => handleRouteChange(View.WATCHLIST)}
                        activeColor={activeNavItemColor}
                        hasActiveColorWhenHovered
                      >
                        {navLinkLabels.navLinkWatchlistLabel || 'Saved'}
                      </DesktopNavItem>
                      <DesktopNavItem
                        theme={theme}
                        dataHcName={'alerts-button'}
                        view={View.ALERTS}
                        currentView={currentView}
                        onClick={() => handleRouteChange(View.ALERTS)}
                        activeColor={activeNavItemColor}
                        hasActiveColorWhenHovered
                      >
                        {navLinkLabels.navLinkAlertsLabel || 'Alerts'}
                        <MenuNotificationCount
                          theme={theme}
                          count={unreadAlertsCount}
                        />
                      </DesktopNavItem>
                      {isYourTeamEnabled && isReferralServicesEnabled && (
                        <DesktopNavItem
                          theme={theme}
                          dataHcName={'find-an-agent-button'}
                          dataEventName={EVENTS.CLICK_FIND_AGENT_HEADER}
                          dataParentEventName={PARENT_EVENTS.CLICK_NAV}
                          dataEventDataJson={userContactInfo}
                          view={View.CONCIERGE_TEAM}
                          currentView={currentView}
                          onClick={() => handleRouteChange(View.CONCIERGE_TEAM)}
                          activeColor={activeNavItemColor}
                          hasActiveColorWhenHovered
                        >
                          Find an agent
                        </DesktopNavItem>
                      )}
                      {isShowingHeaderLenderCTA && lenderCtaPdpConfig && (
                        <DesktopNavItem
                          onClick={() => {
                            const url = getCtaUrl(lenderCtaPdpConfig.ctaUrl);
                            url && window.open(url, '_blank', 'noopener');
                          }}
                          dataEventName="click_lender_cta_header"
                          dataParentEventName="lender_ctas"
                          dataHcName="lender-cta-header"
                          view={null}
                          theme={theme}
                          activeColor={activeNavItemColor}
                          hasActiveColorWhenHovered
                          currentView={currentView}
                        >
                          {lenderCtaPdpConfig.ctaText}
                        </DesktopNavItem>
                      )}
                      {grantProgramHeaderNavItem && (
                        <DesktopNavItem
                          view={null}
                          theme={theme}
                          dataHcName={grantProgramHeaderNavItem.dataHcName}
                          dataEventName={
                            grantProgramHeaderNavItem.dataEventName!
                          }
                          dataParentEventName={
                            grantProgramHeaderNavItem.dataParentEventName!
                          }
                          currentView={currentView}
                          onClick={() => {
                            if (
                              isInsideNativeApp &&
                              grantProgramHeaderNavItem.nativeAppNavKey
                            ) {
                              nativeAppLenderCTAClick(
                                grantProgramHeaderNavItem.nativeAppNavKey,
                                {}
                              );
                            } else {
                              modifiedGrantProgramHeaderNavUrl &&
                                window.open(
                                  modifiedGrantProgramHeaderNavUrl,
                                  '_blank'
                                );
                            }
                          }}
                          activeColor={activeNavItemColor}
                          hasActiveColorWhenHovered
                        >
                          {grantProgramHeaderNavItem.title}
                        </DesktopNavItem>
                      )}
                      {customNavCtas.map(
                        (cta: CustomHeaderCtaConfig, index) => (
                          <DesktopNavItem
                            theme={theme}
                            view={null}
                            key={`custom-header-cta-${index}`}
                            dataHcName={`custom-header-cta-${index}`}
                            dataEventName={`click_header_cta_${index + 1}`}
                            dataParentEventName="lender_ctas"
                            dataEventDataJson={JSON.stringify({
                              area: 'header',
                              cta_url: cta.url,
                              button_copy: cta.label,
                            })}
                            onClick={() => {
                              cta.url &&
                                window.open(cta.url, '_blank', 'noopener');
                            }}
                            activeColor={activeNavItemColor}
                            hasActiveColorWhenHovered
                            currentView={currentView}
                          >
                            {cta.label}
                          </DesktopNavItem>
                        )
                      )}
                    </>
                  )}
                </div>
                <div
                  className={classNames(
                    theme.DesktopHeaderBottomRowRightContainer
                  )}
                >
                  {isShowingSearchFieldOnDesktop && (
                    <AutoCompleteSearchContainer
                      theme={theme}
                      /* Focus on the search input once a user clicks on the search icon to open the search input box */
                      focusInputOnMount={true}
                      onResultClick={onAddressSearchResultClick}
                      prefilledUserInput={placeGeoJSONDescription}
                      handleClearPopulatedInput={handleClearPopulatedInput}
                      handleClickSearchButton={toggleSearchOnDesktop}
                      handleClearEmptyInput={handleClearEmptyInput}
                      shouldDisplayCurrentLocationOption={
                        shouldDisplayCurrentLocationSearchOption
                      }
                    />
                  )}
                  {isShowingSearchIconForRoute &&
                    !isShowingSearchFieldOnDesktop && (
                      <button
                        aria-expanded={isShowingSearchFieldOnDesktop}
                        type="button"
                        aria-label="Search for a city, ZIP code or address"
                        onKeyDown={onEnterOrSpaceKey(toggleSearchOnDesktop)}
                        onClick={toggleSearchOnDesktop}
                        className={theme.DesktopBottomRowIconElement}
                      >
                        <SearchIcon className={theme.SearchIcon} />
                        <style
                          dangerouslySetInnerHTML={{
                            __html: `
                                 .${theme.SearchIcon}:hover .element-to-stroke {
                                   stroke: ${activeNavItemColor}
                                 }
                                 .${theme.SearchIcon}:hover .element-to-fill {
                                   fill: ${activeNavItemColor}
                                 }
                               `,
                          }}
                        />
                      </button>
                    )}
                  <HeaderAuth
                    localShowAuthModal={localShowAuthModal}
                    {...props}
                  />
                </div>
              </div>
            </div>
            {isShowingSearchFieldOnDesktop && (
              <div
                className={theme.DesktopMenuScreen}
                onClick={handleDesktopClickScreen}
              />
            )}
          </div>
        </>
      )}
    </CobrandedStyles>
  );
};

const ThemedHeader = themr('Header', defaultTheme)(Header);
export default ThemedHeader;
