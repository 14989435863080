import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { useSelector } from 'react-redux';

import CobrandedDataComponent, {
  CobrandedDataComponentArgs,
} from '@client/components/CobrandedDataComponent';
import ConfirmModalCobranded from '@client/components/ConfirmModal/ConfirmModalCobranded';
import CircleButton from '@client/components/generic/CircleButton';
import HeartIconActiveCobranded from '@client/components/HeartIcon/HeartIconActiveCobranded';
import HeartIconInactiveCobranded from '@client/components/HeartIcon/HeartIconInactiveCobranded';
import { useAriaAnnouncer } from '@client/context/aria-announcer';
import defaultTheme from '@client/css-modules/WatchListActionButton.css';
import { View } from '@client/routes/constants';
import { AnalyticsEventAddress } from '@client/store/types/analytics';
import { WatchlistPageConfig } from '@client/store/types/cobranding';
import { key, stopEvent } from '@client/utils/component.utils';
import { Alert } from '@client/utils/reach-alert';
import { getCurrentView } from '@src/redux-saga-router-plus/selectors';

type Props = {
  dataHcName?: string;
  getIsPropertyInWatchList: (addressSlug: string) => void;
  handleAddToWatchListWithEnsureLogin: (
    addToWatchListData: AnalyticsEventAddress
  ) => void;
  handleRemoveFromWatchList: (addressSlug: string) => void;
  isAddedToWatchList: boolean;
  addressSlug: string;
  isLoggedIn: boolean;
  /**
   * shouldHandleCheckingForWatchListStatus is used to Prevent
   * multiple request from going out at once, new requests will
   * be cancelled if there is a pending one.
   */
  shouldHandleCheckingForWatchListStatus: boolean;
  isShowingButtonLabel?: boolean;
  theme: Theme;
  handleReportWatchClick?: (slug: string) => void;
  handleReportUnwatchClick?: (slug: string) => void;
  handleReportUnwatchConfirmClick?: (slug: string) => void;
  fullAddress?: string;
  address: AnalyticsEventAddress;
  isInModal?: boolean;
  ariaAnnouncer?: (message: JSX.Element | string) => void;
  isWatchListView?: boolean;
  isFocused?: boolean;
  watchlistPageConfig?: WatchlistPageConfig;
};

type State = {
  isShowingConfirmModal: boolean;
  ariaMessage: string;
};

class WatchListActionButtonComponent extends Component<Props, State> {
  state: State = {
    isShowingConfirmModal: false,
    ariaMessage: '',
  };

  returnFocusTimeout: number | null = null;

  componentDidUpdate(prevProps: Props) {
    const {
      isLoggedIn,
      addressSlug,
      shouldHandleCheckingForWatchListStatus,
      isAddedToWatchList,
      isFocused,
    } = this.props;

    /* When loading the property page on init, first this component mounts, then
     * the account details are set on state */
    if (
      !prevProps.isLoggedIn &&
      isLoggedIn &&
      shouldHandleCheckingForWatchListStatus
    ) {
      this.props.getIsPropertyInWatchList(addressSlug);
    }
    if (
      isAddedToWatchList !== prevProps.isAddedToWatchList &&
      isAddedToWatchList
    ) {
      this.setAriaMessage('Property saved to watchlist');
    }
    if (isFocused) {
      this.handleReturnButtonFocus(0);
    }
  }

  componentDidMount() {
    const {
      isLoggedIn,
      addressSlug,
      shouldHandleCheckingForWatchListStatus,
      isFocused,
    } = this.props;
    /* When loading the property page from another page in the app, the account
     * details are already set before this component mounts */
    if (isLoggedIn && shouldHandleCheckingForWatchListStatus) {
      this.props.getIsPropertyInWatchList(addressSlug);
    }
    if (isFocused) {
      this.handleReturnButtonFocus(0);
    }
  }

  componentWillUnmount() {
    if (this.returnFocusTimeout) {
      window.clearTimeout(this.returnFocusTimeout);
    }
  }

  setAriaMessage(message: string) {
    this.setState({
      ariaMessage: message,
    });

    window.setTimeout(() => {
      this.setState({
        ariaMessage: '',
      });
    }, 1000);
  }

  closeConfirmModal = (e: React.MouseEvent | React.KeyboardEvent) => {
    stopEvent(e);
    this.setState({ isShowingConfirmModal: false });
  };

  openConfirmModal = () => {
    this.setState({ isShowingConfirmModal: true });
  };

  handleOnKeyDown = (e) => {
    if (key.isReturn(e.key) || key.isSpace(e.key)) {
      stopEvent(e);
      this.props.handleReportUnwatchClick?.(this.props.addressSlug);
      this.openConfirmModal();
    }
  };

  handleReturnButtonFocus = (timeoutLength?: number) => {
    /* Need to wait for the modal animation to finish before returning focus.  We're not using FocusLock's
     * returnFocus prop here since it doesn't work to return focus on iOS devices with VoiceOver enabled */
    this.returnFocusTimeout = window.setTimeout(() => {
      /* We know that this component's rendered element can't be of type Text */
      const ele = findDOMNode(this) as Element | null;
      const buttonToFocus = ele && (ele.querySelector('button') as HTMLElement);
      if (buttonToFocus) {
        buttonToFocus.focus();
      }
      if (!this.props.isAddedToWatchList) {
        // Need to wait for focus to return to the button and `aria-label` of button to start
        // reading before setting the remove property message. Otherwise the message will not
        // be read by some screen readers.
        window.setTimeout(() => {
          if (!this.props.isAddedToWatchList) {
            this.setAriaMessage('Property removed from watchlist');
          }
        }, 300);
      }
    }, timeoutLength || 800);
  };

  handleAddToWatchListClick = (
    e: React.KeyboardEvent | React.MouseEvent
  ): void => {
    this.props.handleReportWatchClick?.(this.props.addressSlug);
    this.props.handleAddToWatchListWithEnsureLogin(this.props.address);
  };

  handleRemoveFromWatchList = (addressSlug) => {
    const { ariaAnnouncer, isWatchListView } = this.props;
    this.props.handleRemoveFromWatchList(addressSlug);
    if (ariaAnnouncer && isWatchListView) {
      ariaAnnouncer('Saved home removed.');
    }
  };

  render() {
    const {
      dataHcName,
      isAddedToWatchList,
      addressSlug,
      isShowingButtonLabel,
      theme,
      handleReportUnwatchClick,
      handleReportUnwatchConfirmClick,
      isInModal,
      watchlistPageConfig,
    } = this.props;

    const { ariaMessage } = this.state;
    return (
      <CobrandedDataComponent>
        {({
          customizationData: { remove_watch_list_confirm_modal_copy },
        }: CobrandedDataComponentArgs) => (
          <section
            className={theme.WatchListActionButton}
            data-hc-name={dataHcName || 'save-button'}
          >
            <CircleButton
              theme={theme}
              isActive={isAddedToWatchList}
              ariaLabel={`${isAddedToWatchList ? 'Remove' : 'Save'} this property ${isAddedToWatchList ? 'from' : 'to'} your Watchlist`}
              onKeyDown={isAddedToWatchList ? this.handleOnKeyDown : undefined}
              onClick={(e) => {
                // We want to add e.stopPropagation() to prevent default onClick behavior from interfering with parent elements that have onClick events.
                e.stopPropagation();
                if (isAddedToWatchList) {
                  handleReportUnwatchClick?.(addressSlug);
                  this.openConfirmModal();
                } else {
                  this.handleAddToWatchListClick(e);
                }
              }}
            >
              {isAddedToWatchList ? (
                <>
                  {watchlistPageConfig?.watchlistActionButtonSavedIcon ? (
                    <img
                      src={watchlistPageConfig.watchlistActionButtonSavedIcon}
                      alt=""
                      className={theme.CustomIcon}
                    />
                  ) : (
                    <HeartIconActiveCobranded className={theme.HeartIcon} />
                  )}
                </>
              ) : (
                <>
                  {watchlistPageConfig?.watchlistActionButtonUnsavedIcon ? (
                    <img
                      src={watchlistPageConfig.watchlistActionButtonUnsavedIcon}
                      alt=""
                      className={theme.CustomIcon}
                    />
                  ) : (
                    <HeartIconInactiveCobranded className={theme.HeartIcon} />
                  )}
                </>
              )}
            </CircleButton>
            {isShowingButtonLabel && (
              <div className={theme.ButtonLabel}>
                {isAddedToWatchList ? 'Saved' : 'Save'}
              </div>
            )}
            <ConfirmModalCobranded
              isActive={this.state.isShowingConfirmModal}
              title={remove_watch_list_confirm_modal_copy.title as string}
              body={remove_watch_list_confirm_modal_copy.body as string}
              handleConfirm={(e: React.MouseEvent | React.KeyboardEvent) => {
                this.closeConfirmModal(e);
                handleReportUnwatchConfirmClick?.(addressSlug);
                this.handleRemoveFromWatchList(addressSlug);
              }}
              confirmText={remove_watch_list_confirm_modal_copy.confirm_text}
              handleClose={this.closeConfirmModal}
              handleReturnFocus={this.handleReturnButtonFocus}
            />
            {isInModal ? (
              <div
                className={theme.ScreenReaderVisuallyHidden}
                aria-live="assertive"
              >
                {ariaMessage}
              </div>
            ) : (
              <Alert
                className={theme.ScreenReaderVisuallyHidden}
                type="assertive"
              >
                {ariaMessage}
              </Alert>
            )}
          </section>
        )}
      </CobrandedDataComponent>
    );
  }
}

/* Functional Component Wrapper so we can use the AriaAnnouncer hook */
const WatchListActionButton = ({
  ariaAnnouncer,
  isWatchListView,
  ...props
}: Props) => {
  const announcer = useAriaAnnouncer();
  const currentView = useSelector(getCurrentView);
  const isInWatchListView = currentView === View.WATCHLIST;
  return (
    <WatchListActionButtonComponent
      ariaAnnouncer={announcer}
      isWatchListView={isInWatchListView}
      {...props}
    />
  );
};

export default themr(
  'WatchListActionButton',
  defaultTheme
)(WatchListActionButton);
