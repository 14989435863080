import { Theme } from '@friendsofreactjs/react-css-themr';
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

import LazilyRenderedList from '@client/components/LazilyRenderedList';
import ListingStatusPillMultiUnit from '@client/components/ListingStatusPillMultiUnit';
import MLSAttribution from '@client/components/MLSAttribution';
import PropertyBrief from '@client/components/PropertyBrief';
import PropertyPhotoContainer from '@client/containers/property-photo.container';
import WatchListActionButtonContainer from '@client/containers/watchlist-action-button.container';
import theme from '@client/css-modules/MultiUnitSelectModal.css';
import CloseIcon from '@client/inline-svgs/close';
import {
  dollarsFormatter,
  titleFormatter,
} from '@client/utils/formatter.utils';
import { AnimatePresence, motion } from 'framer-motion';

import { MultiUnitDataObject } from '@client/store/types/maps';
import { Alert } from '@client/utils/reach-alert';

const { modalCollapsedHeight, singleUnitHeight } = theme;

type SingleUnitProps = {
  property: MultiUnitDataObject;
  isAddedToWatchList: boolean;
  onSelectProperty: (property: MultiUnitDataObject) => void;
  handleReportWatchClick?: (slug: string) => void;
  handleReportUnwatchClick: (slug: string) => void;
  handleReportUnwatchConfirmClick: (slug: string) => void;
  showWatchListButton: boolean;
  index: number;
  theme: Theme;
  ariaLabel?: string;
};

const SingleUnit: React.FC<SingleUnitProps> = (props) => {
  const {
    property,
    isAddedToWatchList,
    onSelectProperty,
    handleReportWatchClick,
    handleReportUnwatchClick,
    handleReportUnwatchConfirmClick,
    showWatchListButton,
    index,
    theme,
    ariaLabel,
  } = props;

  /* Animate-in the first few seen above the fold for effect but display the rest, seen after scrolling, immediately */
  const shouldAnimate = index < 3;
  const normalizedProperty = property.normalizedPropertyData;
  const watchlistAddress = {
    street: normalizedProperty.streetAddress,
    city: normalizedProperty.city,
    state: normalizedProperty.state,
    zip: normalizedProperty.zipcode,
    unit: normalizedProperty.unit,
    address_id: normalizedProperty.hcAddressId,
    slug: normalizedProperty.slug,
  };

  return (
    <motion.div
      className={theme.SingleUnit}
      onClick={() => {
        onSelectProperty(property);
      }}
      initial={shouldAnimate ? { opacity: 0, x: -40 } : {}}
      animate={
        shouldAnimate
          ? { opacity: 1, x: 0, transition: { delay: 0.06 + index * 0.06 } }
          : {}
      }
    >
      <div className={theme.LeftSection}>
        <PropertyPhotoContainer
          theme={theme}
          photoSize={'LARGE' as 'LARGE'}
          fullAddress={normalizedProperty.fullAddress}
          addressSlug={normalizedProperty.slug}
          mapTileSize={[160, 120]}
          streetViewLocation={
            normalizedProperty.latitude && normalizedProperty.longitude
              ? {
                  latitude: normalizedProperty.latitude,
                  longitude: normalizedProperty.longitude,
                }
              : null
          }
          mlsLogoUrl={normalizedProperty.mlsLogoOverlay}
        />
        <ListingStatusPillMultiUnit
          theme={theme}
          listingStatus={normalizedProperty.status}
          price={normalizedProperty.listPrice}
        />
      </div>
      <div className={theme.RightSection}>
        <div className={theme.UpperSection}>
          <button
            aria-label={ariaLabel}
            className={theme.UnitNumber}
            type="button"
            onClick={() => {
              onSelectProperty(property);
            }}
          >
            {normalizedProperty.unit}
          </button>
          <div className={theme.AvmPriceLabel}>Comehome Value</div>
          {normalizedProperty.avm ? (
            <div className={theme.AvmPrice}>
              {dollarsFormatter(normalizedProperty.avm)}
            </div>
          ) : (
            <div className={theme.AvmPricePlaceholder}>Unavailable</div>
          )}
          <div className={theme.MLSLabel}>
            <MLSAttribution propertyDetails={normalizedProperty} />
          </div>
        </div>
        <div className={theme.LowerSection}>
          <div className={theme.LowerSectionLeftSide}>
            <div className={theme.PropertyType}>
              {titleFormatter(
                normalizedProperty.propertyType || ''
              ).toLowerCase()}
            </div>
            <PropertyBrief
              beds={normalizedProperty.beds}
              baths={normalizedProperty.baths}
              sqFeet={normalizedProperty.sqFt}
            />
          </div>
          <div className={theme.LowerSectionRightSide}>
            {showWatchListButton && (
              <WatchListActionButtonContainer
                address={watchlistAddress}
                fullAddress={normalizedProperty.fullStreetAddress}
                isAddedToWatchList={isAddedToWatchList}
                shouldHandleCheckingForWatchListStatus={false}
                theme={theme}
                isInModal
                addressSlug={normalizedProperty.slug}
                handleReportWatchClick={handleReportWatchClick}
                handleReportUnwatchClick={handleReportUnwatchClick}
                handleReportUnwatchConfirmClick={
                  handleReportUnwatchConfirmClick
                }
              />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

type WatchListItem = {
  slug: string;
};

type MultiUnitProps = {
  isActive: boolean;
  properties: MultiUnitDataObject[] | null;
  onSelectProperty: (featureProps) => void;
  handleCloseModal: () => void;
  isAddedToWatchList?: boolean;
  handleReportWatchClick?: (slug: string) => void;
  handleReportUnwatchClick: (slug: string) => void;
  handleReportUnwatchConfirmClick: (slug: string) => void;
  showWatchListButton?: boolean;
  theme?: Theme;
  watchListItems?: WatchListItem[];
};

type MultiUnitState = {
  hasExpandAnimationStarted: boolean;
  isExpandAnimationComplete: boolean;
};

/**
 * A modal to allow viewing all multi-unit properties within a building and selecting a single property
 */
export default class MultiUnitSelectModal extends React.Component<
  MultiUnitProps,
  MultiUnitState
> {
  state: MultiUnitState = {
    /* Not currently used, but leaving in for now in case functionality is desired */
    hasExpandAnimationStarted: false,
    isExpandAnimationComplete: false,
  };

  handleExpand = () => {
    this.setState({ hasExpandAnimationStarted: true });
  };

  handleRetract = () => {
    this.setState({
      hasExpandAnimationStarted: false,
    });
  };

  onAnimationComplete = () => {
    const { isActive } = this.props;

    this.setState({ isExpandAnimationComplete: isActive });
  };

  componentDidUpdate(prevProps) {
    const closeButton = document.getElementById('close-button');

    // Have to use a setTimeout inside componentDidUpdate to give the button a chance to load.
    // Otherwise tab to focus & enter to open modal will not work because of how elements are loaded into DOM.
    setTimeout(() => {
      // Set focus to button if prevProps.isActive is false, to not interfere with focus events tied to WatchList button.
      if (closeButton && !prevProps.isActive) {
        closeButton.focus();
      }
    }, 1);
  }

  render() {
    const {
      isActive,
      properties,
      watchListItems,
      onSelectProperty,
      handleReportWatchClick,
      handleReportUnwatchClick,
      handleReportUnwatchConfirmClick,
      handleCloseModal,
      showWatchListButton,
    } = this.props;
    const { hasExpandAnimationStarted, isExpandAnimationComplete } = this.state;

    const firstPropertyData =
      properties && get(properties[0], 'normalizedPropertyData');

    return (
      <AnimatePresence>
        {isActive && (
          <motion.div
            key="pdp-modal-wrapper"
            initial={{
              y: hasExpandAnimationStarted
                ? window.innerHeight
                : modalCollapsedHeight,
            }}
            animate={{
              y: 0,
              top: 'auto',
              left: 0,
              right: 0,
              width: '100%',
              height: '325px',
              transition: {
                default: {
                  duration: 0.15,
                  easing: 'easeIn',
                },
              },
            }}
            exit={{
              y: hasExpandAnimationStarted
                ? window.innerHeight
                : modalCollapsedHeight,
              transition: {
                damping: 0,
                default: {
                  duration: 0.15,
                  easing: 'easeIn',
                },
              },
            }}
            onAnimationComplete={this.onAnimationComplete}
            className={theme.MultiUnitSelectModalWrapper}
          >
            <div
              className={classNames(theme.MultiUnitSelectModal, {
                [theme.MultiUnitSelectModalExpanded]: hasExpandAnimationStarted,
              })}
            >
              <div className={theme.TopBar}>
                {firstPropertyData && (
                  <Alert type="assertive" className={theme.GroupAddress}>
                    <div>{firstPropertyData.streetAddress}</div>
                    <div>
                      {`${firstPropertyData.city}, ${firstPropertyData.state} ${firstPropertyData.zipcode}`}
                    </div>
                  </Alert>
                )}
                <button
                  aria-label="Close multi-unit detail overlay and return to map"
                  className={theme.MobileMapCloseButton}
                  onClick={handleCloseModal}
                  id="close-button"
                  type="button"
                >
                  <CloseIcon />
                </button>
              </div>
              {isExpandAnimationComplete && (
                <LazilyRenderedList
                  dataHcName="property-section"
                  preloadBuffer={500}
                  useSelfAsScrollableAncestor
                  /* Set intended dimensions of list items so that dimensions of each item
                   * container remain constant before and after child render */
                  itemDimensionsStyle={{
                    width: '100%',
                    height: singleUnitHeight,
                  }}
                  llKeyField="property.addressSlug"
                  isMoreToLoad={false}
                  className={theme.ScrollableSection}
                >
                  {properties &&
                    properties.map((property, index) => (
                      <SingleUnit
                        ariaLabel={`${property.normalizedPropertyData.unit}, Unit ${index + 1} of ${properties.length}`}
                        key={property.addressSlug}
                        index={index}
                        onSelectProperty={onSelectProperty}
                        property={property}
                        isAddedToWatchList={
                          !!(watchListItems || []).find(
                            (item) => item.slug === property.addressSlug
                          )
                        }
                        showWatchListButton={!!showWatchListButton}
                        theme={theme}
                        handleReportWatchClick={handleReportWatchClick}
                        handleReportUnwatchClick={handleReportUnwatchClick}
                        handleReportUnwatchConfirmClick={
                          handleReportUnwatchConfirmClick
                        }
                      />
                    ))}
                </LazilyRenderedList>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}
