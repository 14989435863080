import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CopyableField from '@client/components/generic/CopyableField';
import FormError from '@client/components/generic/FormError';
import PillButton from '@client/components/generic/PillButton';
import SmallModal from '@client/components/generic/SmallModal';
import TextAreaWithFormStyle from '@client/components/generic/TextAreaWithFormStyle';
import TextInput from '@client/components/generic/TextInput';

import {
  clearShareProperty,
  sharePropertySubmit,
} from '@client/store/actions/share-property.actions';
import { toastShow } from '@client/store/actions/toast.actions';
import {
  getUserContactInfo,
  getUserFullName,
} from '@client/store/selectors/auth.selectors';
import { getLoanOfficerId } from '@client/store/selectors/loan-officer.selectors';
import { getPropertyDetailsNormalized } from '@client/store/selectors/property-details.selectors';

import { createModalPortal } from '@client/hocs/create-modal-portal';
import {
  multiEmailValidator,
  removeSpaces,
  splitAtCommas,
} from '@client/utils/formatter.utils';
import { getPDPUrlForSlug } from '@client/utils/property.utils';
import { isValidEmailAddress } from '@client/utils/validations.forms';

import theme from '@client/css-modules/ShareModal.css';
import {
  getCobrandId,
  getPropertyDetailPageConfig,
} from '@client/store/selectors/cobranding.selectors';

type Props = {
  isActive: boolean;
  onCloseButtonClick: () => void;
};

/* exported for testing, since the createModalPortal HOC can be problematic to test */
export const ShareModal = ({ isActive, onCloseButtonClick }: Props) => {
  const dispatch = useDispatch();
  const propertyData = useSelector(getPropertyDetailsNormalized);
  const propertyPageData = useSelector(getPropertyDetailPageConfig);
  const shareModalLegalLanguage = propertyPageData?.shareModalLegalLanguage;
  const loId = useSelector(getLoanOfficerId);
  const cobrandId = useSelector(getCobrandId);
  const slug = propertyData?.slug;
  const shareUrl = !slug
    ? null
    : `${getPDPUrlForSlug(slug, cobrandId)}${loId ? `?loid=${loId}` : ''}`;
  const user = useSelector(getUserContactInfo);
  const userFullName = useSelector(getUserFullName);

  const [usersEmail, setUsersEmail] = useState<string>(user?.email || '');
  const [usersEmailError, setUsersEmailError] = useState<string | null>(null);

  const [emailAddresses, setEmailAddresses] = useState<string>('');
  const [emailAddressesError, setEmailAddressesError] = useState<string | null>(
    null
  );
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<string | null>(null);
  const [ariaMessage, setAriaMessage] = useState<string>('');

  const handleEmailAddressesChange = (value: string) => {
    setEmailAddresses(value);
    setEmailAddressesError(null);
  };

  const handleEmailAddressesBlur = () => {
    const invalidEmails =
      emailAddresses.length === 0 || multiEmailValidator(emailAddresses);

    if (invalidEmails) {
      setEmailAddressesError(
        'Please enter valid email addresses, separated by commas'
      );
    }
  };

  const handleUsersEmailChange = (value: string) => {
    setUsersEmail(value);
    setUsersEmailError(null);
  };

  const handleUsersEmailBlur = () => {
    if (usersEmail.length === 0 || !isValidEmailAddress(usersEmail)) {
      setUsersEmailError('Please enter a valid email address');
    }
  };

  const handleMessageChange = (value: string) => {
    setMessage(value);
    if (message.length > 1000) {
      setMessageError('Message must be less than 1000 characters');
    }
  };

  const handleAfterCopy = () => {
    const newToastMessage = 'Share URL copied to clipboard';
    setAriaMessage(newToastMessage);
    dispatch(toastShow(newToastMessage));

    // Clear ariaMessage after a second. Gives screen reader
    // enough time to acknowledge and read it and leaves it blank for
    // any future messages.
    setTimeout(() => {
      setAriaMessage('');
    }, 1000);
  };

  const handleSubmit = (e: React.MouseEvent<Element, MouseEvent>) => {
    /* don't submit via standard HTML form practices */
    e.preventDefault();
    if (propertyData) {
      dispatch(
        sharePropertySubmit({
          emails: splitAtCommas(removeSpaces(emailAddresses)),
          message,
          usersEmail,
          usersName: userFullName || '',
          loId,
          street: propertyData?.streetAddress,
          city: propertyData?.city,
          state: propertyData?.state,
          zip: propertyData?.zipcode,
          unit: propertyData?.unit,
          address_id: propertyData?.hcAddressId,
          addressSlug: propertyData?.slug,
        })
      );
    }
    onCloseButtonClick();
  };

  useEffect(() => {
    if (isActive) {
      setEmailAddresses('');
      setMessage('');
      dispatch(clearShareProperty());
    }
  }, [isActive]);

  const isSubmitEnabled =
    usersEmail.length > 0 &&
    isValidEmailAddress(usersEmail) &&
    emailAddresses.length > 0 &&
    !multiEmailValidator(emailAddresses);

  return (
    <SmallModal
      className={theme.ShareModal}
      theme={theme}
      isActive={isActive}
      handleClose={onCloseButtonClick}
    >
      <div>
        <h3>Share this property</h3>
        <form>
          <fieldset className={theme.FieldRow}>
            <TextInput
              theme={theme}
              required
              value={emailAddresses}
              onChange={(e) => handleEmailAddressesChange(e.target.value)}
              label="Send to*"
              placeholder="Enter email addresses, separate with commas"
              onBlur={handleEmailAddressesBlur}
              role="textbox"
              name="recipient_emails"
              dataHcName="share-property-recipient-field"
              data-event-name="click-share-property-recipient-field"
              data-parent-event-name="click-share-property"
            />
            {emailAddressesError && (
              <FormError
                className={theme.ErrorMessage}
                theme={theme}
                value={emailAddressesError}
              />
            )}
          </fieldset>
          {!user.email && (
            <fieldset className={theme.FieldRow}>
              <TextInput
                theme={theme}
                required
                value={usersEmail}
                onChange={(e) => handleUsersEmailChange(e.target.value)}
                label="Your email*"
                placeholder="Enter your email"
                onBlur={handleUsersEmailBlur}
                role="textbox"
                name="user email"
                dataHcName="share-property-user-email-field"
                data-event-name="click-share-property-user-email-field"
                data-parent-event-name="click-share-property"
              />
              {usersEmailError && (
                <FormError
                  className={theme.ErrorMessage}
                  theme={theme}
                  value={usersEmailError}
                />
              )}
            </fieldset>
          )}

          <fieldset className={theme.FieldRow}>
            <TextAreaWithFormStyle
              theme={theme}
              value={message}
              onChange={(e) => handleMessageChange(e.target.value)}
              label="Add a personal message"
              placeholder="Check out this property I found on ComeHome!"
              rows={6}
              className={theme.TextArea}
              role="textbox"
              name="message"
              data-hc-name="share-property-message-field"
              data-event-name="click-share-property-message-field"
              data-parent-event-name="click-share-property"
            />
            {messageError && (
              <FormError
                className={theme.ErrorMessage}
                theme={theme}
                value={messageError}
              />
            )}
          </fieldset>
          <PillButton
            dataHcName="share-property-cta-button"
            data-event-name="click-share-property-cta-button"
            data-parent-event-name="click-share-property"
            ariaLabel="Send"
            className={theme.SendButton}
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
            tertiary={!isSubmitEnabled}
            theme={theme}
          >
            Send
          </PillButton>
        </form>
        <div className={theme.CopyableFieldSection}>
          {shareUrl && (
            <>
              <CopyableField
                data-hc-name="share-property-copy"
                data-event-name="click-share-property-copy"
                data-parent-event-name="click-share-property"
                value={shareUrl}
                isLoading={false}
                theme={theme}
                afterCopy={() => handleAfterCopy()}
              />
              <div
                className={theme.ScreenReaderVisuallyHidden}
                aria-live="assertive"
              >
                {ariaMessage}
              </div>
            </>
          )}
        </div>
        {shareModalLegalLanguage && (
          <div
            className={theme.ShareLanguageLegalText}
            // these values come from Parcon and can contain HTML in the string
            dangerouslySetInnerHTML={{
              __html: shareModalLegalLanguage,
            }}
          ></div>
        )}
      </div>
    </SmallModal>
  );
};

export default createModalPortal(ShareModal, 'share-modal');
