import { Theme, themr } from '@friendsofreactjs/react-css-themr';
import { Component } from 'react';

import defaultTheme from '@client/css-modules/MobileFilterSelect.css';
import { LockedComponent } from '@client/hocs/locked-component';
import {
  FILTER_KEYS,
  FilterKey,
  FILTERS_ORDER,
} from '@client/store/filter-constants';
import { SearchFiltersConfig } from '@client/store/types/cobranding';
import { SpecialUserType } from '@client/store/types/locked-components';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';
import {
  FILTER_CONTROLS,
  getFilterDescription,
  getFilterTitle,
} from '@client/utils/filters.utils';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { grantProgramFilterToggleDescription } from './GrantProgram/GrantProgramFilterToggle';

const FOCUSABLE_ELEMENT_ATTRIBUTE_KEY = 'data-programmatically-focusable';

const {
  headerAndControlsCombinedHeight,
  headerAndControlsAndTitleCombinedHeight,
} = defaultTheme;

type Props = {
  filterSortOrder?: SearchFiltersConfig | null;
  inactiveFilterKeys: FilterKey[];
  handleClose: () => void;
  handleSelect: (key: FilterKey | 'grant-program') => void;
  shouldShowUnitsTotalMinMax: boolean;
  needsMobileHeightAdjustment: boolean;
  theme: Theme;
  reportFilterClick: (key) => void;
  isSchoolDataEnabled: boolean;
  isHidingAdvancedFilters: boolean;
  routeToMlsIdSearch: () => void;
  isShowingGrantToggle: boolean;
  areTempEnhancedFilterOptionsEnabled: boolean;
};

const ADVANCED_FILTERS = FILTER_CONTROLS.advanced.map(
  (filter) => filter.key
) as Partial<FilterKey>[];

class MobileFilterSelect extends Component<Props> {
  getSortedInactiveFilterKeys = (): Partial<FilterKey>[] => {
    const {
      filterSortOrder,
      isHidingAdvancedFilters,
      inactiveFilterKeys,
      shouldShowUnitsTotalMinMax,
    } = this.props;
    const usableFilterKeys = isHidingAdvancedFilters
      ? inactiveFilterKeys.filter(
          (control) => !ADVANCED_FILTERS.includes(control)
        )
      : inactiveFilterKeys;
    return usableFilterKeys
      .filter((key) =>
        shouldShowUnitsTotalMinMax ? key : key !== 'unitsTotalMinMax'
      )
      .sort((aKey, bKey) =>
        filterSortOrder
          ? filterSortOrder.indexOf(aKey) - filterSortOrder.indexOf(bKey)
          : FILTERS_ORDER.indexOf(aKey) - FILTERS_ORDER.indexOf(bKey)
      );
  };

  /* Focus the first filter item after component mounts for a11y compliance*/
  focusFirstFilter = (ele: HTMLDivElement | null) => {
    if (ele !== null) {
      const filterItemNodeList = ele.querySelectorAll(
        `[${FOCUSABLE_ELEMENT_ATTRIBUTE_KEY}="true"]`
      );
      if (filterItemNodeList && filterItemNodeList[0]) {
        (filterItemNodeList[0] as HTMLElement).focus();
      }
    }
  };

  grantEligibleSection = (): JSX.Element => {
    const { theme, reportFilterClick, handleSelect } = this.props;
    return (
      <tr>
        <td>
          <motion.div
            tabIndex={0}
            aria-labelledby={`#grant-id #grant-desc`}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.4 * 0.05,
                duration: 0.2,
              },
            }}
            className={theme.FilterRow}
            onKeyDown={onEnterOrSpaceKey(() => {
              reportFilterClick('grant_program');
              handleSelect('grant-program');
            })}
            onClick={(e) => {
              reportFilterClick('grant_program');
              handleSelect('grant-program');
            }}
            data-filter-key={'grant-program'}
            /* Attribute to target for focusing on component mount for a11y compliance */
            {...{ [FOCUSABLE_ELEMENT_ATTRIBUTE_KEY]: 'true' }}
          >
            <div className={theme.FilterTitle} id={'grant-id'}>
              Grant Eligible
            </div>
            <div className={theme.FilterDesc} id={'grant-desc'}>
              {grantProgramFilterToggleDescription}
            </div>
          </motion.div>
        </td>
      </tr>
    );
  };

  searchByMlsMobileSection = (): JSX.Element => {
    const {
      theme,
      reportFilterClick,
      routeToMlsIdSearch,
      isShowingGrantToggle,
    } = this.props;
    return (
      <LockedComponent
        sectionId={FILTER_KEYS.SEARCH_BY_MLS}
        lockedFor={[SpecialUserType.Restricted]}
        className={theme.LockedComponentContainer}
        theme={theme}
      >
        {({ isLocked }) => (
          <tr key="mls-search">
            <td>
              <motion.div
                key={'search-by-mls-number'}
                tabIndex={0}
                aria-labelledby={`#mls-search #mls-search-description`}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    delay:
                      0.4 +
                      (isShowingGrantToggle
                        ? this.getSortedInactiveFilterKeys().length + 1
                        : this.getSortedInactiveFilterKeys().length) *
                        0.05,
                    duration: 0.2,
                  },
                }}
                className={classNames(theme.FilterRow, {
                  [theme.disabled]: isLocked,
                })}
                onKeyDown={onEnterOrSpaceKey(() => {
                  reportFilterClick('mls-search');
                  routeToMlsIdSearch();
                })}
                onClick={(e) => {
                  reportFilterClick('mls-search');
                  routeToMlsIdSearch();
                }}
                data-filter-key="mls-search"
                /* Attribute to target for focusing on component mount for a11y compliance */
                {...{ [FOCUSABLE_ELEMENT_ATTRIBUTE_KEY]: 'true' }}
              >
                <div className={theme.FilterTitle} id="mls-search">
                  MLS#
                </div>
                <div className={theme.FilterDesc} id="mls-search-description">
                  Search by MLS#
                </div>
              </motion.div>
            </td>
          </tr>
        )}
      </LockedComponent>
    );
  };

  render() {
    const {
      theme,
      handleClose,
      handleSelect,
      needsMobileHeightAdjustment,
      reportFilterClick,
      isShowingGrantToggle,
      filterSortOrder,
      areTempEnhancedFilterOptionsEnabled,
    } = this.props;
    const windowHeight = window ? window.innerHeight : 0;

    return (
      <div
        className={theme.MobileFilterSelect}
        role="dialog"
        ref={this.focusFirstFilter}
        style={{
          ...(needsMobileHeightAdjustment
            ? {
                maxHeight: `calc(${windowHeight}px - ${headerAndControlsCombinedHeight})`,
              }
            : {}),
        }}
      >
        <div className={theme.Header}>
          <button
            type="button"
            aria-label="Close Select Filter"
            className={theme.SelectFilters}
            onClick={handleClose}
            onKeyDown={onEnterOrSpaceKey(handleClose)}
          >
            Select Filter
          </button>
        </div>
        <span
          className={theme.SelectFilterHeadingAriaText}
          id="selectFilterHeading"
        >
          Select Filter list
        </span>
        <table
          aria-labelledby="#selectFilterHeading"
          style={{
            ...(needsMobileHeightAdjustment
              ? {
                  maxHeight: `calc(${windowHeight}px - ${headerAndControlsAndTitleCombinedHeight})`,
                }
              : {
                  maxHeight: `calc(100vh - ${headerAndControlsAndTitleCombinedHeight})`,
                }),
          }}
          className={theme.FilterSelectTable}
        >
          <caption className={theme.Screenreader}>
            Please select a filter
          </caption>
          <tbody>
            <tr className={theme.Screenreader}>
              <th scope="col">Filter name and description</th>
            </tr>
            {/* if there is no filterSortOrder config from Parcon, show the grant program filter toggle as the first item */}
            {isShowingGrantToggle &&
              !filterSortOrder &&
              this.grantEligibleSection()}

            {this.getSortedInactiveFilterKeys().map((key, i) => {
              const filterTitleId = `filterTitle_${i}`;
              const filterDescId = `filterDesc_${i}`;
              const effectiveAnimationIndex = isShowingGrantToggle ? i + 1 : i;

              if (key === FILTER_KEYS.SEARCH_BY_MLS) {
                return this.searchByMlsMobileSection();
              } else if (
                key === FILTER_KEYS.GRANT_ELIGIBLE &&
                filterSortOrder
              ) {
                if (isShowingGrantToggle) return this.grantEligibleSection();
                return null;
              } else if (
                [FILTER_KEYS.POOL, FILTER_KEYS.BASEMENT].includes(
                  key as 'pool' | 'basement'
                ) &&
                !areTempEnhancedFilterOptionsEnabled
              ) {
                return null;
              }

              return (
                <LockedComponent
                  sectionId={key}
                  lockedFor={[SpecialUserType.Restricted]}
                  className={theme.LockedComponentContainer}
                  theme={theme}
                >
                  {({ isLocked }) => (
                    <tr key={key}>
                      <td>
                        <motion.div
                          key={key}
                          tabIndex={0}
                          aria-labelledby={`#${filterTitleId} #${filterDescId}`}
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: 1,
                            transition: {
                              delay: 0.4 + effectiveAnimationIndex * 0.05,
                              duration: 0.2,
                            },
                          }}
                          className={classNames(theme.FilterRow, {
                            [theme.disabled]: isLocked,
                          })}
                          onKeyDown={onEnterOrSpaceKey(() => {
                            reportFilterClick(key);
                            handleSelect(key);
                          })}
                          onClick={(e) => {
                            reportFilterClick(key);
                            handleSelect(key);
                          }}
                          data-filter-key={key}
                          /* Attribute to target for focusing on component mount for a11y compliance */
                          {...{ [FOCUSABLE_ELEMENT_ATTRIBUTE_KEY]: 'true' }}
                        >
                          <div className={theme.FilterTitle} id={filterTitleId}>
                            {getFilterTitle(key)}
                          </div>
                          <div className={theme.FilterDesc} id={filterDescId}>
                            {getFilterDescription(key)}
                          </div>
                        </motion.div>
                      </td>
                    </tr>
                  )}
                </LockedComponent>
              );
            })}
            {/* Hard-coded MLS ID search link */}
            {!filterSortOrder && this.searchByMlsMobileSection()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default themr('MobileFilterSelect', defaultTheme)(MobileFilterSelect);
